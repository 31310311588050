import React, { Component, Fragment } from "react";
import _ from "lodash";
import "./index.scss";
import Slider from "rc-slider";
class FilterSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activeValues: [],
    };
  }

  async componentDidMount() {
    // Call any function in provider
    let selectedFilters = [...this.props.filters["selected"]];
    // if found remove for replace after
    let filterFound = _.find(
      selectedFilters,
      (currentFilter) => currentFilter["type"] === "years"
    );

    let defaultValue = [];

    if (filterFound) {
      defaultValue = [
        Number(filterFound.values[0]),
        Number(filterFound.values[filterFound.values.length - 1]),
      ];
    } else {
      defaultValue = [this.props.min, this.props.max];
    }

    this.setState({
      loading: false,
      activeValues: defaultValue,
    });
  }

  handleSelected = (event) => {
    const years = [];
    for (var i = event[0]; i <= event[1]; i++) {
      years.push(i);
    }

    this.props.storeSliderFilter({
      type: "years",
      values: years,
    });
  };

  render() {
    const { loading, activeValues } = this.state;
    var marks = {};
    for (var i = this.props.min; i <= this.props.max; i++) {
      marks[i] = i.toString();
    }
    return (
      <Fragment>
        {loading ? (
          "<p>Carregant</p>"
        ) : (
          <Slider
            range
            marks={marks}
            min={this.props.min}
            max={this.props.max}
            defaultValue={activeValues}
            step={1}
            onChange={this.handleSelected}
          />
        )}
      </Fragment>
    );
  }
}

export default FilterSlider;
