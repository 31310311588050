import React, { useEffect } from "react";
import * as d3 from "d3";
import _ from "lodash";

import { CONFIG, CLUSTERS_TO_MOVE } from "../../common/constants";

import { showTooltip, hideTooltip } from "./Tooltip";
// import { removePropForces } from "./Simulation";

import { zoomed } from "./Zoom";

import { getTranslation } from "../../common/translations";

// Testing collision
export const DotsCollision = function (props) {
  // console.log("---------- ENTERING DOTS COLLISION");
  // console.log("-> PROPS", props);

  // Create a double loader as it's so slow this part
  const canvasTopics = d3.select("#canvas-options-tabpane-topics");

  d3.selectAll("#canvas-options-tabpane-topics .loader").remove();

  const canvasTopicsLoader = canvasTopics
    .append("div")
    .attr("class", "loader")
    .style("position", "absolute")
    // .style("top", CONFIG.APP.LAYOUT.MAIN_TABS_HEIGHT + "px")
    .style("top", "0px")
    .style("left", "0px")
    .style("z-index", "1000")
    .style("width", "100vw")
    .style("height", props.height + "px")
    .style("line-height", props.height + "px")
    .style("text-align", "center")
    .style("background-color", "rgba(255,255,255,0.5)")
    .html(
      "<img src='./images/loading-small.gif' class='loading-spinner' />" +
        getTranslation({
          lang: props.language,
          key: "LOADING_MESSAGES.MAP",
        })
    );

  // As label are mounted after we store the props scale to a variable
  colorScale = props.colorScale;
  // 1. Data

  const svgRef = React.useRef(null);
  // 4. Functions
  const onOverPoint = (d, mousePosition) => {
    const currentPointData = d.target["__data__"];
    // Manage visibility of elements in canvas
    props.dotHandleOver({
      selectedDot: currentPointData,
      // selectedCluster: currentPointData.cluster,
      canvasApp: props.canvasApplication,
      filteredDots: props.getFilteredProjects(),
      filteredTopics: props.getFilteredTopics(),
      clickedDot: props.getClickedDot(),
      // clickedLabel: props.getClickedLabel(),
      totalParticipants: props.totalParticipants,
    });

    setTimeout(function () {
      showTooltip({
        datum: currentPointData,
        cluster: props.getTopicInformation(currentPointData["cluster"]),
        mousePosition: mousePosition,
      });
    }, 300);
  };

  const onOutPoint = () => {
    setTimeout(function () {
      hideTooltip();
    }, 300);
    props.dotHandleOut({
      filteredDots: props.getFilteredProjects(),
      filteredTopics: props.getFilteredTopics(),
      clickedDot: props.getClickedDot(),
      clickedLabel: props.getClickedLabel(),
    });
  };

  const drawNodes = (svg, node_data) => {
    const node = svg
      .selectAll(".projectNode")
      .data(node_data)
      .enter()
      .append("circle")
      .attr("data-cluster", (d) => d.cluster)
      .attr("id", (d) => d.id)
      .attr("class", (d) => "projectNode projectNode-" + d.cluster)
      .attr("cx", (d) => d.x)
      .attr("cy", (d) => d.y)
      .attr("r", (d) => d.r)
      .attr("fill", (d) => d.color)
      .attr("opacity", 0);

    return [node];
  };

  const drawClusters = (svg, clustersData, nodesData) => {
    //   const gMainCluster = svg.append('g');
    // var mainContour = d3.contourDensity()
    //     .x(d => props.xScale(d.x))
    //     .y(d => props.yScale(d.y))
    //     .size([props.canvasWidth, props.canvasHeight])
    //     .bandwidth(30)
    //     .thresholds(30)
    //     (
    //         props.data.topics.topics
    //     );
    // var mainOpacityScale = d3.scalePow()
    //     .exponent(9)
    //     .domain([0, mainContour.length])
    //     .range([0, 1]);
    // const mainG = gMainCluster.append('g')
    //     .attr('id', 'main')
    //     .attr("class", "maincluster-container")
    //     .attr("fill", "none")
    //     .attr("stroke", "#C00") //colorCluster)
    //     .attr("stroke-linejoin", "round")
    //     .style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.ACTIVE);

    // mainG.selectAll("path")
    //     .data(mainContour)
    //     .enter()
    //     .append("path")
    //     .attr('class', 'maincluster')
    //     .attr("stroke-width", 1)
    //     .attr("stroke-opacity", function (d, i) {
    //         return i * 0.05;
    //         //return _.clamp(i * 0.025, 0, MAX_OPACITY/2);
    //     })
    //     .attr("fill", function (d) {
    //         return CONFIG.TOPICS.LAYOUT.COLOR_CLUSTER;
    //     })
    //     .attr("fill-opacity", function (d, i) {
    //         return mainOpacityScale(i);
    //     })
    //     .attr("d", d3.geoPath());

    clustersData.forEach(function (cluster, i) {
      const clusterColor = cluster.color;

      // create contours from the data
      var contours = d3
        .contourDensity()
        .x((d) => d.x)
        .y((d) => d.y)
        .size([props.width * 2, props.height * 2])
        .bandwidth(18)
        .thresholds(9)(
        _.filter(nodesData, function (row) {
          return row.cluster === cluster.cluster;
        })
      );

      // opacity with scalePow will go to 1 very fast in the latest contours
      // (the inner ones). The higher the exponent, more at the end the fast
      // increase will happen
      // var opacityScale = d3
      //   .scalePow()
      //   .exponent(9)
      //   .domain([0, contours.length])
      //   .range([0, 0.2]);

      const g = svg
        .append("g")
        .attr("id", "id" + cluster.cluster)
        .attr("class", "cluster-container")
        .attr("fill", "none")
        .attr("stroke", "rgba(255, 255, 255, .1)") //colorCluster)
        .attr("stroke-linejoin", "round")
        .style("opacity", () => {
          if (props.filters["filteredTopics"].length > 0) {
            return props.filters["filteredTopics"].includes(cluster.cluster)
              ? 1
              : 0.2;
          } else {
            return 0.8;
          }
        });

      g.selectAll("path")
        .data(contours)
        .enter()
        .append("path")
        .attr("class", "cluster" + cluster)
        .attr("stroke-width", 1)
        .attr("stroke-opacity", function (d, i) {
          // return 0;
          return i * 0.05;
          //return _.clamp(i * 0.025, 0, MAX_OPACITY/2);
        })
        .attr("fill", function (d) {
          const colorValue =
            clusterColor.substring(0, clusterColor.length - 1) +
            ", " +
            d.value * 10 +
            ")";
          return colorValue;
          // return CONFIG.TOPICS.LAYOUT.COLOR_CLUSTER;
        })
        // .attr("fill-opacity", function (d, i) {
        //   // return 0;
        //   return opacityScale(i);
        // })
        .attr("d", d3.geoPath());
    });
  };

  const calculateRectSize = (label) => {
    console.log("LABEL", label);
    const labelLength = label.length;
    return labelLength * 10;
  };

  const drawLabels = (svg, label_data) => {
    // console.log("--------> DRAW_LABELS");
    // console.log("LABEL_DATA", label_data);

    // const labelsContainer = svg
    //   .append("g")
    //   .attr("class", "label-rects-container");

    const label = svg
      .selectAll(".labelGroup")
      .data(label_data)
      .enter()
      .append("g")
      .attr("id", (d) => "label" + d.cluster)
      .attr("data-cluster", (d) => d.cluster)
      .attr("class", (d) => "label label-" + d.cluster)
      .attr("transform-origin", "50% 50%")
      .attr("transform", (d) => {
        return "translate(" + (d.translateX - (calculateRectSize(d.label) / 3)) + "," + d.translateY + ")";
      })
      .style("opacity", (d) => {
        if (props.filters["filteredTopics"].length > 0) {
          return props.filters["filteredTopics"].includes(d.cluster) ? 1 : 0.2;
        } else {
          if (props.filters.zero) {
            return 0;
          } else {
            return 0.8;
          }
        }
      })
      .style("pointer-events", () => (props.filters.zero ? "none" : "all"));

    // Append label background
    label
      .append("rect")
      .attr("id", (d) => "rectLabel" + d.cluster)
      .attr("data-cluster", (d) => d.cluster)
      .attr("data-label", (d) => d.label)
      .attr("class", "label-back-rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", (d) => calculateRectSize(d.label))
      .attr("height", 15)
      // .attr("transform-origin", "50% 50%")
      // .attr("transform", (d) => {
      //   return "translate(" + -calculateRectSize(d.label) / 2 + "," + 0 + ")";
      // })
      .attr("fill", CONFIG.TOPICS.LAYOUT.COLOR_LABEL_BACKGROUND)
      .attr("fill-opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.ACTIVE)
      .attr("stroke", (d) => d.color)
      .attr("stroke-width", 0.5)
      // .attr("rx", 5)
      // .attr("ry", 5)
      .style("pointer-events", props.filters.zero ? "none" : "all");

    // Append label text
    label
      .append("text")
      .attr("data-cluster-color", (d) => {
        return d3.interpolateRgbBasis(colorValues)(colorScale(d.cluster));
      })
      .attr("class", (d) => "label-text label-text-" + d.cluster)
      .attr("x", 0)
      .attr("y", 0)
      .attr("font-size", CONFIG.TOPICS.LAYOUT.FONT_SIZE)
      .attr("font-family", CONFIG.TOPICS.LAYOUT.FONT_FAMILY)
      // .attr("text-anchor", "middle")
      .attr("fill", (d) => d.color)
      // .attr("dx", 2.5)
      // .attr("dy", 5)
      .attr("dominant-baseline", "hanging")
      .text((d) => {
        // find the topic in the props.topicsTranslations by cluster number
        const found = _.find(
          props.topicsTranslations,
          (t) => t.topic_num === d.cluster
        );
        // return the translation
        return found
          ? found["topic_label_" + props.language]
          : "NO TRANSLATION";
      });

    return [label];
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let mousePosition;

    const svgEl = d3
      .select(svgRef.current)
      .attr("id", "svgNodes")
      .attr("class", "svg-nodes");

    svgEl.selectAll("*").remove(); // Clear svg content before adding new elements

    // Create a loading message while the data and layout are not ready
    const zoom = d3.zoom();
    let transform = d3.zoomIdentity;

    // Create de layers for each element
    // to keep overlaping ordered

    const gClusters = svgEl.append("g").attr("class", "clusters-container"),
      gNodes = svgEl.append("g").attr("class", "nodes-container"),
      labelsRectsContainer = svgEl
        .append("g")
        .attr("class", "label-rects-container");
    // Only access the simulation if provider data.topicsPoints is empty
    // First time the simulation is created
    if (props.data.topicsPoints.length === 0) {
      // create simulation using x + y forces to avoid collision
      const simulation = d3.forceSimulation(props.dotsData);

      simulation
        .force(
          "x",
          d3.forceX().x((d) => d.x)
        )
        .force(
          "y",
          d3.forceY().y((d) => d.y)
        )
        .force(
          "collision",
          d3.forceCollide().radius(function (d) {
            return d.r + 1;
          })
        );

      simulation.on("end", function () {
        // What's in gNodes?
        // console.log("What's in gNodes?")
        // console.log(gNodes.selectAll(".projectNode").data());
        // console.log("ONLY NODES", gNodes.selectAll(".projectNode").nodes());

        // Let's move some labels to avoid overlaping
        // Iterate on data to move labels
        _.map(props.labelsData, (label) => {
          const found = _.find(
            CLUSTERS_TO_MOVE,
            (d) => d.id === label["cluster"]
          );
          if (found) {
            label.x = label.x + found["x"];
            label.y = label.y + found["y"];
          }
          label.translateX = label.x;
          label.translateY = label.y;
        });

        gNodes
          .selectAll(".projectNode")
          .attr("cx", function (d) {
            return d.x;
          })
          .attr("cy", function (d) {
            return d.y;
          })
          .attr("opacity", (d) => {
            // if props.filters.filteredProjects has lenght > 0 show only those projects
            if (props.filters.filteredProjects.length > 0) {
              return props.filters.filteredProjects.includes(d.id) ? 1 : 0.1;
            } else {
              if (props.filters.zero) {
                return 0.1;
              } else {
                return 0.5;
              }
            }
          })
          .style("pointer-events", () => (props.filters.zero ? "none" : "all"));

        // drawDelaunay(svgEl, labelsData);
        drawClusters(gClusters, props.labelsData, props.dotsData);
        drawLabels(labelsRectsContainer, props.labelsData);

        zoomed({
          transform: transform,
        });

        // Store the nodes objects in the Provider state
        props.setTopicPoints(
          gNodes.selectAll(".projectNode").data(),
          labelsRectsContainer.selectAll(".label").data()
        );
        // Remove loader
        canvasTopicsLoader.remove();
        // canvasTopics.remove();
      });
      // END SIMULATION
    } else {
      // console.log("TRANSFORM", transform);
      // console.log("------> ZOOMED", zoomed({transform: transform}));

      // transform = d3.zoomIdentity;
      // If the simulation is already created
      gNodes
        .selectAll("circle")
        .data(props.data.topicsPoints)
        .join("circle")
        .attr("class", "projectNode")
        .attr("id", (d) => d.id)
        .attr("r", (d) => d.r)
        .attr("cx", (d) => d.x)
        .attr("cy", (d) => d.y)
        .attr("fill", (d) => d.color)
        .attr("opacity", (d) => {
          if (props.filters["filteredProjects"].length > 0) {
            return props.filters["filteredProjects"].includes(d.id) ? 0.9 : 0.1;
          } else {
            if (props.filters.zero) {
              return 0.1;
            } else {
              return 0.7;
            }
          }
        })
        .style("pointer-events", () => (props.filters.zero ? "none" : "all"));

      drawClusters(gClusters, props.data.topicsLabels, props.dotsData);
      drawLabels(labelsRectsContainer, props.data.topicsLabels);

      // TODO: See how I can store the values of the zoom after resize window
      //  and use them to zoom again
      zoomed({
        transform: transform,
      });
      // Remove loader
      canvasTopicsLoader.remove();
      // canvasTopics.remove();
      // d3.selectAll("#topicsLoadingText").remove();
    }

    // Paint nodes
    drawNodes(gNodes, props.dotsData);

    // Interaction on dots
    gNodes
      .selectAll(".projectNode")
      .on("click", (d) => {
        const dotData = d["target"]["__data__"];
        props.openDotInfo(dotData.id, dotData.cluster);
      })
      .on("mouseover", (d) => onOverPoint(d, mousePosition))
      .on("mouseout", onOutPoint);

    // Interaction on labels
    labelsRectsContainer
      .on("mouseover", function (d, i) {
        // Bring the hovered label to the front
        d3.select(d["target"].parentNode).raise();

        props.labelHandleOver({
          selectedCluster: d["target"]["__data__"]["cluster"],
          filteredDots: props.getFilteredProjects(),
          filteredTopics: props.getFilteredTopics(),
        });
      })
      .on("mouseout", function (d, i) {
        // Send the hovered label to the back
        // d3.select(d["target"].parentNode).lower();
        props.dotHandleOut({
          filteredDots: props.getFilteredProjects(),
          clickedDot: props.getClickedDot(),
          clickedLabel: props.getClickedLabel(),
          filteredTopics: props.getFilteredTopics(),
        });
      })
      .on("click", (d) => {
        // props.loadingInteraction();
        props.selectTopicLabel(d["target"]["__data__"]["cluster"]);
      });

    zoomed({ transform: transform });

    svgEl
      .on("mousemove", function (event) {
        mousePosition = [];
        mousePosition.push(d3.pointer(event));
        document.body.style.cursor = "default";
      })
      .on("click", function (event) {
        if (event["target"]["id"] === "svgNodes") {
          d3.selectAll(".label")
            .style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.ACTIVE)
            .style("font-weight", "normal")
            .style("letter-spacing", "0px");
          props.selectTopicLabel(null);

          props.closeDotInfo();
        }
      })
      .call(
        zoom.scaleExtent([0.5, 8]).on("zoom", ({ sourceEvent, transform }) => {
          return zoomed({
            sourceEvent: sourceEvent,
            transform: transform,
          });
        })
      );
    // canvasTopics.remove();
    // Paint labels positions
  });

  return <svg ref={svgRef} width={props.width} height={props.height} />;
};

// UTILS

// const dataPoints = [];
const colorValues = CONFIG.TOPICS.LAYOUT.COLOR_VALUES;
let colorScale;
