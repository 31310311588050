import React, { Component, Fragment } from "react";
import { ParticipantsNetwork } from "./Canvas";
import _ from "lodash";
import "./index.scss";

import { CONFIG } from "../../common/constants";

class NetworkCanvas extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.setCanvasSize(this.props['canvasWidth'], this.props['canvasHeight']);
  }

  shouldComponentUpdate(nextProps, nextState) {

    if (
      // Update when:
      // - data change
      // - sorting criteria change
      // - zoom change
      // - zoomActive change
      // - canvasWidth change
      // - clickedNode change
      _.isEqual(this.props.data.networkData, nextProps.data.networkData) &&
      _.isEqual(this.props.sorting.network, nextProps.sorting.network) &&
      _.isEqual(this.props.zoom.network, nextProps.zoom.network) &&
      // this.props['zoomActive'] === nextProps['zoomActive'] &&
      this.props['canvasWidth'] === nextProps['canvasWidth'] &&
      this.props['clickedNode'] === nextProps['clickedNode'] &&
      this.props['tabs']['active'] === nextProps['tabs']['active']
    ) {
      // console.log("NETWORK_CANVAS -> DO NOTHING");
      return false;
    } else {
      // console.log("NETWORK_CANVAS -> UPDATE");
      // TODO: Manage blank state
      if (nextProps['tabs']['active'] === "network") {
        this.props.setCanvasSize(nextProps['canvasWidth'], nextProps['canvasHeight']);
        return true;
      } else {
        return false;
      }

    }
  }


  render() {

    const { canvasWidth, canvasHeight } = this.props;
    const calculatedHeight = canvasHeight - CONFIG.APP.LAYOUT.MAIN_TABS_HEIGHT;

    return (
      <Fragment>
        <ParticipantsNetwork
          width={canvasWidth}
          height={calculatedHeight}
          {...this.props}
        ></ParticipantsNetwork>
      </Fragment>
    );
  }
}

export default NetworkCanvas;
