import * as d3 from "d3";
import _ from "lodash";
import { CONFIG } from "../../../common/constants";

export const generateScales = function ({ data = null }) {
  let scales = {};

  const edgeColorScale = d3
    .scaleOrdinal()
    .range(CONFIG.APP.NETWORK_COLORS.LINKS_SCALE_RANGE)
    .domain([
      d3.extent(data.links, function (d) {
        return d.values.weight;
      }),
    ]);

  const sizeNodeLabelScale = d3
    .scaleLinear()
    .domain(
      d3.extent(
        _.map(data.nodes, function (o) {
          return o.values[CONFIG.APP.CONFIG.size.field];
        })
      )
    )
    .range([16, 22]);

  // define min and max size for the nodes
  // and for its labels as well
  const sizeScale = d3
    .scaleLinear()
    .domain(
      d3.extent(
        _.map(data.nodes, function (o) {
          return o.values[CONFIG.APP.CONFIG.size.field];
        })
      )
    )
    .range([5, 30]);

  const colorMap = d3.scaleOrdinal(d3.schemeCategory10);

  scales.edgeColorScale = edgeColorScale;
  scales.sizeNodeLabelScale = sizeNodeLabelScale;
  scales.sizeScale = sizeScale;
  scales.colorMap = colorMap;

  return scales;
};
