import _ from "lodash";
import * as jsnx from "jsnetworkx";
import * as Combinatorics from "js-combinatorics";
import * as dl from "datalib";

export const graph = function ({ myData = null, myConfig }) {
  /**
   * Main component. Defines a graph based on the 'config' component as defined in module.data().
   */

  // console.log("GRAPH");
  // console.log("MY_DATA", myData);
  // console.log("MY_CONFIG", myConfig);
  // console.log("existsFilters()", existsFilters())

  var myGraph = {};

  myGraph.create = function () {
    return create();
  };

  // inner methods
  function create() {
    var data;

    // if (existsFilters()) {
    //   filters.drop("organizationName");
    //   data = filterData(myData);
    // } else {
    //   data = myData;
    // }
    data = myData;

    // console.log("CREATE NETWORK DATA STRUCTURE");
    // console.log("MY_DATA", myData);
    // console.log("MY_CONFIG", myConfig);

    // data = _.filter(data, datum => datum['funding'] > 0)

    // var data = existsFilters() ? filterData(myData) : myData;

    // 1. group by projectId
    var groupedNodes = dl.groupby(myConfig.entity.field).execute(data);
    // 2. group by participant name
    var flattenGroupData = dl
      .groupby(
        [myConfig.node.id.field, myConfig.size.field]
          .concat(_.map(myConfig.node.aggs, "field"))
          .concat(myConfig.node.name.field)
          .concat(myConfig.node.geo.country.field)
          .concat(myConfig.node.geo.region.field)
      )
      .execute(data);

    // console.log("flattenGroupData", flattenGroupData);

    var aggGroupedNodes = dl
      .groupby([
        myConfig.node.id.field,
        myConfig.node.name.field,
        myConfig.node.geo.country.field,
        myConfig.node.geo.region.field,
      ])
      .summarize(
        [
          {
            name: myConfig.size.field,
            ops: [myConfig.size.opt],
            as: [myConfig.size.field],
          },
        ].concat(
          _.map(myConfig.node.aggs, function (nodeAggField) {
            return {
              name: nodeAggField.field,
              ops: ["values"],
              as: [nodeAggField.field],
            };
          })
        )
      )
      .execute(flattenGroupData);

    // console.log("aggGroupedNodes", aggGroupedNodes)

    var G = new jsnx.Graph(),
      edges = [];

    // return false;

    // get list of unique nodes for each group
    // if group has multiple nodes, generate edges
    // combinating all nodes and add edges to graph,
    // (adding edges adds also involved nodes as well)
    // otherwhise add the single node
    groupedNodes.forEach(function (group) {
      // console.log("GROUP", group)
      
      var uniqNodes = _(group.values)
      .map(myConfig.node.id.field)
      .uniq()
      .value();
      
      // console.log("uniqNodes", uniqNodes);
      
      if (uniqNodes.length > 1) {
        // console.log("UNIQ_NODES", uniqNodes);
        edges = _.concat(
          edges,
          new Combinatorics.Combination(uniqNodes, 2).toArray()
          );
        } else {
          G.addNode(uniqNodes[0]);
        }
      });
    
    // console.log(">>>>> groupedNodes", groupedNodes);

    // console.log("G", G);

    // console.log("edges", edges);

    var edgesWeights = dl.count.map(edges);

    // counting edges, we end up with:
    // {
    //  Ab Biotics Sa,Cuetara 1951 Slu: 1,
    //  Ab Biotics Sa,Fundació Eurecat: 10,
    //  Ab Biotics Sa,Ibermatica Sa: 2,
    // ...
    // }
    // so add each weight to the edge tuple
    // and add it to the graph
    edges.forEach(function (e) {
      e.push(edgesWeights[e.join()]);
    });

    // console.log("flattenGroupData", flattenGroupData);

    // console.log("edgesWeights", edgesWeights);

    // if (edgesWeight !== 0) {
    //   var filteredEdges = edges.filter(function (edge) {
    //     return edge[2] > edgesWeight;
    //   })
    //   edges = filteredEdges
    //   var filteredAggGroupedNodes = aggGroupedNodes.filter(obj => {
    //     for (let index = 0; index < filteredEdges.length; index++) {
    //       const element = filteredEdges[index];
    //       if(element.includes(obj.organizationName)) {
    //         return obj
    //       }

    //     }

    //   })
    //   aggGroupedNodes = filteredAggGroupedNodes
    // }

    // Filter edges by weight
    // console.log("*** FILTERED EDGES", filteredEdges);
    // console.log("*** WTF aggGroupedNodes", aggGroupedNodes)
    // console.log("*** filteredAggGroupedNodes", filteredAggGroupedNodes)

    G.addWeightedEdgesFrom(edges);

    // add label to G
    // console.log("aggGroupedNodes", aggGroupedNodes);
    // ADD NAMES TO THE NODES
    // here add the other properties
    // PARTICIPANT NAME
    const labelNodeObj = _.mapValues(
      _.keyBy(aggGroupedNodes, myConfig.node.id.field),
      function (obj) {
        return obj[myConfig.node.name.field];
      }
    );
    jsnx.setNodeAttributes(G, "label", labelNodeObj);

    // PARTICIPANT COUNTRY
    const countryNodeObj = _.mapValues(
      _.keyBy(aggGroupedNodes, myConfig.node.id.field),
      function (obj) {
        return obj[myConfig.node.geo.country.field];
      }
    );
    jsnx.setNodeAttributes(G, "countryName", countryNodeObj);

    // PARTICIPANT REGION
    const regionNodeObj = _.mapValues(
      _.keyBy(aggGroupedNodes, myConfig.node.id.field),
      function (obj) {
        return obj[myConfig.node.geo.region.field];
      }
    );
    jsnx.setNodeAttributes(G, "participantNuts3Name", regionNodeObj);

    // add size property to G
    var sizeNodeObj = _.mapValues(
        _.keyBy(aggGroupedNodes, myConfig.node.id.field),
        function (obj) {
          return obj[myConfig.size.field];
        }
      ),
      // For each agg for node, specify field and properties for each node.
      // Estrcture: [['field', [{'nodeName': propValue, ...}, ...]], ...]
      aggObjs = _.map(myConfig.node.aggs, function (nodeAggField) {
        return [
          nodeAggField.field,
          _.mapValues(
            _.keyBy(aggGroupedNodes, myConfig.node.id.field),
            function (obj) {
              return _.uniq(_.map(obj[nodeAggField.field], nodeAggField.field));
            }
          ),
        ];
      });

    jsnx.setNodeAttributes(G, myConfig.size.field, sizeNodeObj);

    aggObjs.forEach(function (aggObj) {
      // console.log("aggObj", aggObj[1])
      // field values for each node
      jsnx.setNodeAttributes(G, aggObj[0], aggObj[1]);
    });

    // console.log("****** NETWORKDATA G", JSON.stringify(G));
    // console.log("****** NETWORKDATA G STATS", jsnx.info(G));
    return G;
  }

  return myGraph;
};

export const arraymove = function (arr, fromIndex, toIndex) {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
};
