import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';

import { history } from './common';
import Home from './sections/Home';

export const RouteApp = props => {
    return (   
        <Router history={history}>
            <Routes>
                <Route path='/' element={<Home/>} />
            </Routes>
        </Router>
    )        
}