import _ from "lodash";
import * as d3 from "d3";
// import * as louvain from "jlouvain";

export const getForceSimulation = () => {
  // return a d3.forceSimulation with default settings for
  // auto-detected communities
  const simulation = d3.forceSimulation().alphaDecay(0.1).alphaTarget(0.05);
  return simulation;
};

// LAYOUTS

export const setClusteredForceLayout = ({
  graphJSON = [],
  selectedNodeProperty = null,
  simulation = null,
  width = null,
  height = null,
}) => {
  //   console.log(">>>>>>> setClusteredForceLayout");
  // get unique values for the selected propery. For each value
  // a force will be applied to those nodes having the property
  const propertyValues = _.uniq(
    _.map(graphJSON.nodes, function (node) {
      return node.values[selectedNodeProperty];
    })
  );
  // The largest node for each cluster.
  const clusters = new Array(propertyValues.length);
  graphJSON.nodes.map(function (node, i) {
    if (
      !clusters[node.values.group] ||
      node.radius > clusters[node.values.group].radius
    ) {
      return (clusters[node.values.group] = node);
    } else {
      return false;
    }
  });
  // try to place nodes around the center
  simulation.force("forceX", d3.forceX(width / 2).strength(0.2));
  simulation.force("forceY", d3.forceY(height / 2).strength(0.2));
  simulation.force(
    "collide",
    d3
      .forceCollide()
      .radius(function (d) {
        return d.radius * 4;
      })
      .iterations(1)
  );
  simulation.force("cluster", function (alpha) {
    for (
      var i = 0, n = graphJSON.nodes.length, node, cluster, k = alpha * 1;
      i < n;
      ++i
    ) {
      node = graphJSON.nodes[i];
      cluster = clusters[node.values.group];
      var allClusters = _.map(_.compact(_.map(clusters)));
      node.vx -= (node.x - (node.values.group !== -1 ? cluster.x : 0)) * k;
      node.vy -=
        (node.y -
          (node.values.group !== -1
            ? cluster.y
            : allClusters.length !== 0
            ? _.minBy(allClusters, "y").y
            : 0)) *
          k +
        (node.values.group !== -1 ? 0 : 5);
    }
  });
};
let propForces = [];
export const setIsolatedForceLayout = ({
  selectedNodeProperty = null,
  simulation = null,
  graphJSON = [],
  width = null,
}) => {

  // console.log("SET ISOLATED FORCE LAYOUT");

  const forceStrength = 1;
  var initialForces = [
    {
      key: "charge",
      force: d3.forceManyBody().strength(-300).distanceMax(200),
    },
    {
      key: "collide",
      force: d3
        .forceCollide()
        .radius(function (d) {
          return d.radius * 2;
        })
        .strength(0.7)
        .iterations(1),
    },
    { key: "link", force: d3.forceLink().distance(5) },
  ];

  initialForces.forEach(function (force) {
    simulation.force(force.key, force.force);
  });

  // get unique values for the selected property. For each value
  // a force will be applied to those nodes having the property
  let propertyValues = _.uniq(
    _.map(graphJSON.nodes, function (node) {
      return _.first(node.values[selectedNodeProperty]);
    })
  );

  // Remove undefined entries
  propertyValues = _.compact(propertyValues);
  // propertyValues = propertyValues.sort();
  // console.log("propertyValues", propertyValues);

  // get points that will be the centroid of the isolated
  // clusters, as many points as differents values we have
  var equiPoints = pointsOnCircle(propertyValues.length);

  // apply forces to each group of nodes sharing a
  // value property

  propertyValues.forEach(function (propertyValue, i) {
    simulation
      .force(
        _.kebabCase(propertyValue + " x"),
        isolate(
          d3.forceX(equiPoints[i].x * width * 0.5).strength(forceStrength),
          function (node) {
            return _.includes(node.values[selectedNodeProperty], propertyValue);
          },
          graphJSON
        )
      )
      .force(
        _.kebabCase(propertyValue + " y"),
        isolate(
          d3.forceY(equiPoints[i].y * width * 0.5).strength(forceStrength),
          function (node) {
            return _.includes(node.values[selectedNodeProperty], propertyValue);
          },
          graphJSON
        )
      );
    propForces.push(_.kebabCase(propertyValue + " x"));
    propForces.push(_.kebabCase(propertyValue + " y"));
  });

  simulation.force("link").links(graphJSON.links);
};

/**
 * Gives points positions around a circle
 * @param {*} alpha
 */
const pointsOnCircle = function (num) {
  var angle = (2 * Math.PI) / num;
  var points = [];
  var i = 0;
  for (var a = 0; a < 2 * Math.PI; a += angle) {
    i++;
    points.push({
      x: Math.cos(a),
      y: Math.sin(a),
      rotation: a,
      label: "point" + i,
    });
  }
  return points;
};

const isolate = function (force, filter, graphJSON) {
  var initialize = force.initialize;
  force.initialize = function () {
    initialize.call(force, graphJSON.nodes.filter(filter));
  };
  return force;
};

export const removePropForces = function ({ simulation = null }) {
  propForces.forEach(function (force) {
    simulation.force(force, null);
  });
};

export const removeClusteringForces = function ({ simulation = null }) {
  simulation.force("forceY", null);
  simulation.force("cluster", null);

  // mantain the collide force to prevent overlapping
  //simulation.force('collide', null);
};
