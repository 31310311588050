import React, { Component, Fragment } from "react";
import "./index.scss";

import _ from "lodash";
import FiltersSummary from "../FiltersSummary";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";

import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";

import StatisticsTabAmbits from "../StatisticsTabAmbits";
import StatisticsTabEntitats from "../StatisticsTabEntitats";
import StatisticsTabPartners from "../StatisticsTabPartners";
import StatisticsTabProjects from "../StatisticsTabProjects";

// import { CONFIG } from "../../common/constants";

import { getTranslation } from "../../common/translations";
// import { filter } from "d3";

class StatisticsTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activeTab: "",
    };
  }

  componentDidMount() {
    this.setState({
      loading: false,
      activeTab: this.props.statisticsTabs["active"],
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.statisticsTabs, this.props.statisticsTabs)) {
      this.setState({
        activeTab: this.props.statisticsTabs["active"],
      });
    }
  }

  closeStatisticsPanel = () => {
    if (this.state.activeTab !== "") {
      this.props.setSelectedStatisticTab("");
    } else {
      this.props.setSelectedStatisticTab(this.props.statisticsTabs["last"]);
    }
  };

  render() {
    const { filters, language } = this.props;
    const { activeTab } = this.state;

    return (
      <Fragment>
        <div className="statistics-module container-fluid">
          <div
            className="statistic-module-trigger"
            onClick={() => this.closeStatisticsPanel()}
          >
            {activeTab === "" ? (
              <FaChevronCircleDown></FaChevronCircleDown>
            ) : (
              <FaChevronCircleUp></FaChevronCircleUp>
            )}
          </div>
          <FiltersSummary {...this.props}></FiltersSummary>
          <div className="container-fluid statistic-module-tabs">
            <Tab.Container
              id="statistic-tabs"
              className="statistic-tabs"
              activeKey={activeTab}
              onSelect={(k) => {
                this.props.setSelectedStatisticTab(k);
              }}
              fill
            >
              <Row className="statistic-module-nav">
                <Col>
                  <Nav className="justify-content-center">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="statistic-ambits"
                        className={`${
                          activeTab === "statistic-ambits" ? "active" : ""
                        }`}
                      >
                        <FaChevronCircleDown className="close"></FaChevronCircleDown>
                        <FaChevronCircleUp className="open"></FaChevronCircleUp>
                        {getTranslation({
                          lang: language,
                          key: "STATISTICS.TABS.AMBITS",
                        })}
                      </Nav.Link>
                    </Nav.Item>
                    {/* If is ther an only one participant selected, then hide the tab */}
                    {filters["searchedParticipantIds"].length === 1 ? (
                      ""
                    ) : (
                      <Nav.Item>
                        <Nav.Link
                          eventKey="statistic-entitats"
                          className={`${
                            activeTab === "statistic-entitats" ? "active" : ""
                          }`}
                        >
                          <FaChevronCircleDown className="close"></FaChevronCircleDown>
                          <FaChevronCircleUp className="open"></FaChevronCircleUp>
                          {getTranslation({
                            lang: language,
                            key: "STATISTICS.TABS.ENTITIES",
                          })}
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {/* If is ther an only one participant selected, then hide the tab */}
                    {/* 
                      Show Socis the Catalunya when:
                      1. Any participant is selected
                      2. OR The type of institution (ACTIVITY_TYPE) filter is applied
                      3. OR The Province (NUTS3) filter is applied
                    */}
                    {filters["searchedParticipantIds"].length > 0 ||
                    (_.find(
                      filters["selected"],
                      (f) => f.type === "activityType"
                    ) &&
                      _.find(
                        filters["selected"],
                        (f) => f.type === "activityType"
                      ).values.length > 0) ||
                    (_.find(filters["selected"], (f) => f.type === "nuts3") &&
                      _.find(filters["selected"], (f) => f.type === "nuts3")
                        .values.length > 0) ? (
                      <Nav.Item>
                        <Nav.Link
                          eventKey="statistic-partners-catalans"
                          className={`${
                            activeTab === "statistic-partners-catalans"
                              ? "active"
                              : ""
                          }`}
                        >
                          <FaChevronCircleDown className="close"></FaChevronCircleDown>
                          <FaChevronCircleUp className="open"></FaChevronCircleUp>
                          {getTranslation({
                            lang: language,
                            key: "STATISTICS.TABS.CATALONIA_PARTNERS",
                          })}
                        </Nav.Link>
                      </Nav.Item>
                    ) : (
                      ""
                    )}
                    {/* If the frameworks filter only contains Instruments RIS3CAT the hide this tab */}
                    {filters["selected"] &&
                    _.find(
                      filters["selected"],
                      (f) => f.type === "frameworks"
                    ) &&
                    _.find(filters["selected"], (f) => f.type === "frameworks")
                      .values.length === 1 &&
                    _.find(
                      filters["selected"],
                      (f) => f.type === "frameworks"
                    ).values.includes("Instruments RIS3CAT") ? (
                      ""
                    ) : (
                      <Nav.Item>
                        <Nav.Link
                          eventKey="statistic-partners"
                          className={`${
                            activeTab === "statistic-partners" ? "active" : ""
                          }`}
                        >
                          <FaChevronCircleDown className="close"></FaChevronCircleDown>
                          <FaChevronCircleUp className="open"></FaChevronCircleUp>
                          {getTranslation({
                            lang: language,
                            key: "STATISTICS.TABS.EXTERNAL_PARTNERS",
                          })}
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    <Nav.Item>
                      <Nav.Link
                        eventKey="statistic-projects"
                        className={`${
                          activeTab === "statistic-projects" ? "active" : ""
                        }`}
                      >
                        <FaChevronCircleDown className="close"></FaChevronCircleDown>
                        <FaChevronCircleUp className="open"></FaChevronCircleUp>
                        {getTranslation({
                          lang: language,
                          key: "STATISTICS.TABS.PROJECTS",
                        })}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              </Row>
              {activeTab !== "" && (
                <Row className="statistic-module-panel">
                  <Tab.Content>
                    {activeTab === "statistic-ambits" && (
                      <Tab.Pane eventKey="statistic-ambits">
                        <StatisticsTabAmbits
                          {...this.props}
                        ></StatisticsTabAmbits>
                      </Tab.Pane>
                    )}
                    {activeTab === "statistic-entitats" && (
                      <Tab.Pane eventKey="statistic-entitats">
                        <StatisticsTabEntitats
                          section={"entitats"}
                          {...this.props}
                        ></StatisticsTabEntitats>
                      </Tab.Pane>
                    )}
                    {activeTab === "statistic-partners-catalans" && (
                      <Tab.Pane eventKey="statistic-partners-catalans">
                        <StatisticsTabEntitats
                          section={"partners-catalans"}
                          {...this.props}
                        ></StatisticsTabEntitats>
                      </Tab.Pane>
                    )}
                    {activeTab === "statistic-partners" && (
                      <Tab.Pane eventKey="statistic-partners">
                        <StatisticsTabPartners
                          {...this.props}
                        ></StatisticsTabPartners>
                      </Tab.Pane>
                    )}
                    {activeTab === "statistic-projects" && (
                      <Tab.Pane eventKey="statistic-projects">
                        <StatisticsTabProjects
                          {...this.props}
                        ></StatisticsTabProjects>
                      </Tab.Pane>
                    )}
                  </Tab.Content>
                </Row>
              )}
            </Tab.Container>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default StatisticsTabs;
