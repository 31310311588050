import React, { useEffect } from "react";
import * as d3 from "d3";
import _ from "lodash";
import "./index.scss";
import { CONFIG } from "../../common/constants";

const wrapEllipsesMiddle = function (allText, width) {
  allText.each(function () {
    var self = d3.select(this),
      textWidth = self.node().getComputedTextLength(), // Width of text in pixel.
      initialText = self.text(), // Initial text.
      text = initialText,
      precision = 2, // Adjustable precision.
      maxIterations = 100; // Set iterations limit.

    while (
      maxIterations > 0 &&
      text.length > 0 &&
      textWidth - width > precision
    ) {
      var midpoint = Math.ceil(text.length / 2);
      text =
        text.substring(0, midpoint - 1).trim() +
        text.substring(midpoint + 1).trim();
      self.text(
        text.substring(0, midpoint - 1).trim() +
          "..." +
          text.substring(midpoint + 1).trim()
      );
      textWidth = self.node().getComputedTextLength();
      maxIterations--;
    }
  });
};

export const StatisticsBars = function (props) {
  // console.log("ENTERS STATISTICS_BARS", props);

  const svgRef = React.useRef(null);

  // Process data and so
  // if (props.interaction === "receptor") {
  //   console.log("RECEPTOR - CHART_DATA", props.chartData);
  // }

  const labelValue = "value";
  const labelCategory = "label";
  const barHeigth = 18;
  let labelsWidth = props["labelsWidth"]
    ? props.width * props["labelsWidth"]
    : props.width * 0.5;
  const barsWidth = props["labelsWidth"]
    ? props.width * (1 - props["labelsWidth"])
    : props.width * 0.5;
  const colorScale = d3
    .scaleLinear()
    .interpolate(d3.interpolateHcl)
    .range([d3.rgb("#c00000"), d3.rgb("#430000")]);
  const x = d3.scaleLinear(),
    y = d3.scaleBand(),
    yAxis = d3.axisLeft().scale(y);
  x.range([0, barsWidth]);
  props.chartData.length > 0
    ? x
        .domain([0, _.maxBy(props.chartData, labelValue)[labelValue] * 1.1])
        .nice()
    : x.domain([0, 0]);
  colorScale.domain(x.domain());

  const categoryItems = _.uniq(_.map(props.chartData, labelCategory));

  y.range([0, props.chartData.length * barHeigth])
    .padding(categoryItems.length === 1 ? 0 : 0.1)
    .domain(categoryItems);

  // Process data for hovering on bars
  // if (props.interaction === "receptor") {
  //   console.log("RECEPTOR - CHART_DATA", props.chartData);
  // }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    const svgEl = d3.select(svgRef.current);
    svgEl.selectAll("*").remove();

    const g = svgEl.append("g").attr("class", "bars-container");

    g.attr(
      "transform",
      "translate(" + (labelsWidth + CONFIG.APP.LAYOUT.SPACER) + "," + 0 + ")"
    );

    g.append("g").attr("class", "x axis");
    g.append("g").attr("class", "y axis");

    // APPEND AXIS LABELS
    g.append("text").attr("class", "axis-label").attr("text-anchor", "middle");

    // TODO: check why some results overlap the bars
    g.select(".y.axis")
      .call(yAxis)
      .selectAll(".tick text")
      .call(wrapEllipsesMiddle, labelsWidth);

    // var barsCursorAttr = function(){
    //   return ($scope.interactionBarClass? "pointer" : "default" );
    // };

    g.selectAll(".bar").remove();
    var groups = g.selectAll(".bar").data(props.chartData, function (d) {
      return d[labelCategory];
    });
    var groupsEnter = groups.enter().append("g").attr("class", "bar");

    groupsEnter.append("rect");
    groupsEnter.append("text");

    // as the elements we're binding the data to are not
    // the ones that are actually drawn, the new data needs
    // to be propagated to them. This is what .select() does.
    // That is, by doing myGroups.select("line"), we're
    // propagating the new data bound to the g elements to
    // their children line elements
    groupsEnter
      .select("rect")
      // .attr("class", "bar " + $scope.barsClass)
      .attr("class", "bar")
      // .style("cursor", barsCursorAttr)
      .attr("x", 0)
      .attr("width", function (d) {
        if (x(d[labelValue]) <= 0) {
          return 0;
        } else {
          return x(d[labelValue]);
        }
      })
      .attr("y", function (d) {
        return y(d[labelCategory]);
      })
      .attr("height", y.bandwidth())
      .attr("fill", function (d) {
        return colorScale(d[labelValue]);
      })
      .on("click", function (d, event) {
        props.interaction === "sender" &&
          props.interactionLevel !== "level3" &&
          props.handleClickInteraction(event);
      })
      .on("mouseover", function (d, event) {
        props.interaction === "sender" && props.handleHoverInteraction(event);
      })
      .on("mouseout", function (d) {
        props.interaction === "sender" && props.handleOutInteraction();
      });

    // bar labels
    groupsEnter
      .select("text")
      .attr("y", function (d) {
        return y(d[labelCategory]) + y.bandwidth() - 5;
      })
      .text(function (d) {
        if (props.type === "funding") {
          // special case to format funding numbers
          if (d[labelValue] >= 1000000000) {
            var n = Math.round(d[labelValue] / 1000000);
            return n.toLocaleString("es") + " M€";
          } else {
            // redefine the d3.format to the new locale
            d3.formatDefaultLocale({
              currency: ["", "€"],
              decimal: ".",
              thousands: ".",
              grouping: [3],
            });
            return d3.format("$.2s")(d[labelValue]).replace("M", " M");
          }
        } else {
          return d[labelValue];
        }
      });

    groupsEnter
      .select("text")
      .attr("class", function (d) {
        // place beside the bar if
        // bars' width is not enough
        return x(d[labelValue]) > d3.select(this).node().getBBox().width + 10
          ? "bar-label"
          : "bar-label-outer";
      })
      .attr("dy", "1px")
      .attr("x", function (d) {
        return x(d[labelValue]) > d3.select(this).node().getBBox().width + 10
          ? x(d[labelValue]) - 5
          : x(d[labelValue]) + 5;
      })
      .style("pointer-events", "none");
  }, [props.chartData, colorScale, labelsWidth, props, x, y, yAxis]);

  return (
    <svg
      ref={svgRef}
      width={props.width}
      height={props.chartData.length * barHeigth}
    />
  );
};
