import React, { Component } from "react";
import "./index.scss";

import { FaInfoCircle } from "react-icons/fa";

// import translations from the translations file
import { getTranslation } from "../../common/translations";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectorIsOpen: false,
    };
  }

  handleLangSelector = () => {
    // Trigger the value of the selector
    this.setState({
      selectorIsOpen: !this.state.selectorIsOpen,
    });
  };

  render() {
    const { lang, setLang } = this.props;

    return (
      <header className="main-header container-fluid">
        <div className="row row-logos">
          <div className="col gencat-logo">
            <img
              src="./images/gencat-nuevo-completo-w.svg"
              height={24}
              alt="Generalitat de Catalunya"
            />
          </div>
          <div className="col col-auto europa-logo">
            <img src="./images/europa-w.svg" height={28} alt="EU FEDER" />
          </div>
        </div>
        <div className="row row-title">
          <div className="col">
            <h1 className="title">
              {getTranslation({ lang: lang, key: "HEADER.TITLE" })}
            </h1>
          </div>
          <div className="col col-auto links-nav">
            <ul>
              <li>
                <a
                  href={`./endpoint/ris3cat-endpoint-${lang}.html`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {getTranslation({
                    lang: lang,
                    key: "HEADER.MENU.QUERIES",
                  })}
                </a>
              </li>
              <li>
                <a
                  href="https://fonseuropeus.gencat.cat/web/.content/ris3cat/plataforma-ris3mcat/ajuda-ris3mcat.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  {getTranslation({
                    lang: lang,
                    key: "HEADER.MENU.HELP",
                  })}
                </a>
              </li>
              {/* Language selector */}
              <li>
                <div className="lang-trigger" onClick={this.handleLangSelector}>
                  <span className="text-white text-uppercase">{lang}</span>
                  <img src="./images/chevron-down-small-w.svg" alt="" />
                  <div
                    className={`lang-container ${
                      this.state.selectorIsOpen ? "d-block" : "d-none"
                    }`}
                  >
                    <div className="lang-content">
                      <ul>
                        <li>
                          <button
                            className="lang-btn"
                            onClick={() => setLang("ca")}
                          >
                            Català
                          </button>
                        </li>
                        <li>
                          <button
                            className="lang-btn"
                            onClick={() => setLang("en")}
                          >
                            English
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="source-trigger">
                  <FaInfoCircle></FaInfoCircle>
                  <div className="source-container">
                    <div className="source-content">
                      <p className="intro-text">
                        {getTranslation({
                          lang: lang,
                          key: "HEADER.INFORMATION_WINDOW.INTRO_1",
                        })}{" "}
                        <a
                          href="https://fonseuropeus.gencat.cat/ca/ris3cat/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          RIS3CAT
                        </a>
                        {getTranslation({
                          lang: lang,
                          key: "HEADER.INFORMATION_WINDOW.INTRO_2",
                        })}
                      </p>
                      <p className="intro-text">{getTranslation({
                          lang: lang,
                          key: "HEADER.INFORMATION_WINDOW.UPDATE",
                        })}</p>
                      <h4>
                        {getTranslation({
                          lang: lang,
                          key: "HEADER.INFORMATION_WINDOW.DATA_SOURCES.TITLE",
                        })}
                      </h4>{" "}
                      <ul>
                        {" "}
                        <li>
                          <span>
                            <a
                              href="https://cordis.europa.eu/home_en.html"
                              target="_blank"
                              rel="noreferrer"
                            >
                              CORDIS
                            </a>{" "}
                            - Community Research and Development Information
                            Service (© European Union, 1994-2022)
                          </span>
                        </li>{" "}
                        <li>
                          <span>
                            <a
                              href="https://aplicacions.economia.gencat.cat/sifecat1420/AppJava/views/inici.html?set-locale=ca_ES&amp;bust=1666764092970"
                              target="_blank"
                              rel="noreferrer"
                            >
                              SIFECAT
                            </a>{" "}
                            -{" "}
                            {getTranslation({
                              lang: lang,
                              key: "HEADER.INFORMATION_WINDOW.DATA_SOURCES.SIFECAT_DESCRIPTION",
                            })}
                          </span>
                        </li>{" "}
                      </ul>
                      <h4 className="pt-3">
                        {getTranslation({
                          lang: lang,
                          key: "HEADER.INFORMATION_WINDOW.LIBRARIES.TITLE",
                        })}
                      </h4>{" "}
                      <ul>
                        {" "}
                        <li>
                          <span>
                            <a
                              href="https://reactjs.org/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              React
                            </a>{" "}
                            (Facebook Open Source © 2023 Meta Platforms, Inc.)
                          </span>
                        </li>{" "}
                        <li>
                          <span>
                            <a
                              href="https://d3js.org/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              D3.js
                            </a>{" "}
                            (© Mike Bostock, 2011-2023)
                          </span>
                        </li>{" "}
                      </ul>
                      <h4>
                        {getTranslation({
                          lang: lang,
                          key: "HEADER.INFORMATION_WINDOW.LISENCE.TITLE",
                        })}
                      </h4>
                      <div className="license">
                        <p>
                          <a
                            rel="noreferrer"
                            href="https://creativecommons.org/publicdomain/zero/1.0/deed.ca"
                            target="_blank"
                          >
                            <img
                              alt="Llicència de Creative Commons"
                              style={{ borderWidth: 0, height: "40px" }}
                              // src="https://mirrors.creativecommons.org/presskit/buttons/88x31/png/cc-zero.png"
                              src="images/cc-public-domain.png"
                            />
                          </a>
                        </p>
                        <p className="mb-0">
                          {" "}
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href={
                              "https://creativecommons.org/publicdomain/zero/1.0/deed." +
                              lang
                            }
                          >
                            {getTranslation({
                              lang: lang,
                              key: "HEADER.INFORMATION_WINDOW.LISENCE.LINK_SUMMARY",
                            })}
                          </a>
                        </p>
                        <p className="mb-0">
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://creativecommons.org/publicdomain/zero/1.0/legalcode"
                          >
                            {getTranslation({
                              lang: lang,
                              key: "HEADER.INFORMATION_WINDOW.LISENCE.LINK_COMPLETE",
                            })}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
