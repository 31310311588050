import React, { Component, Fragment } from "react";
import * as d3 from "d3";
import _ from "lodash";

import Loader from "../Loader";

import "./index.scss";

import { FORMAT_THOUSANDS } from "../../common/constants";
import { getTranslation } from "../../common/translations";

class AmbitsChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: this.props.loading,
      chartData: [],
      filteredAmbits: [],
    };
  }

  // No need of this method because we manage the update in componentDidUpdate
  // as we are waiting for main loading to render the chart
  // componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (
      _.isEqual(
        this.props["filters"]["selected"],
        prevProps["filters"]["selected"]
      ) &&
      _.isEqual(
        this.props["data"]["networkData"],
        prevProps["data"]["networkData"]
      ) &&
      _.isEqual(
        this.props["data"]["ambitsData"],
        prevProps["data"]["ambitsData"]
      ) &&
      _.isEqual(this.state.chartData, prevState.chartData) &&
      this.props.language === prevProps.language
    ) {
      // console.log("AMBITS CHART -> DO NOTHING");
    } else {
      // console.log("AMBITS CHART -> UPDATE AMBITS");
      const filteredAmbits = this.getFilteredAmbits();
      const ambitsData = this.getAmbitsData({
        options: this.props.filters.populate["categoryName"].options,
        data: this.props.data["ambitsData"],
      });

      this.setState({
        loading: this.props.loading,
        chartData: ambitsData,
        filteredAmbits: filteredAmbits,
      });
    }
  }

  getFilteredAmbits = () => {
    const filterFound = _.find(
      this.props["filters"]["selected"],
      (filter) => filter["type"] === "categoryName"
    );
    return filterFound ? filterFound["values"] : [];
  };

  getAmbitsData = ({ options = [], data = {} }) => {
    // console.log("GET AMBITS DATA");
    // console.log("-> OPTIONS", options);
    // console.log("-> DATA", data);


    let ambitsData = data;

    // This is commented because it's not needed to remove categoryName shared projects
    // Check if is any ambit checked in filters
    // const filterFound = _.find(
    //   this.props["filters"]["selected"],
    //   (filter) => filter["type"] === "categoryName"
    // );

    // if (filterFound) {
    //   ambitsData = _.filter(ambitsData, (project) => {
    //     // iterate over the filters
    //     return filterFound["values"].includes(project["categoryName"]);
    //     // return project["categoryName"].includes(filter);
    //   });
    //   // ambitsData = _.map(options, (ambit) => {
    //   //   const checkAmbit = data[ambit["value"]];

    //   //   return {
    //   //     label: ambit["label"],
    //   //     filterValue: ambit["value"],
    //   //     value: filterFound["values"].includes(ambit["value"])
    //   //       ? checkAmbit
    //   //         ? checkAmbit.length
    //   //         : ambit["value"].length
    //   //       : ambit["value"].length,
    //   //   };
    //   // });
    // } else {
    // }
    ambitsData = _.map(options, (ambit) => {
      // const checkAmbit = data[ambit["value"]];
      return {
        label: ambit["label"],
        filterValue: ambit["value"],
        value: data[ambit["value"]] ? data[ambit["value"]].length : 0,
      };
    });

    // Prepare data for chart
    const maxValue = d3.max(ambitsData, (d) => d["value"]);
    const result = {
      maxValue: maxValue,
      bars: ambitsData,
    };
    return result;
  };

  handleBarClick = (bar) => {
    this.props.setClickedAmbit({
      ambit: bar["filterValue"],
    });
  };
  render() {
    const { loading, chartData, filteredAmbits } = this.state;
    const { language } = this.props;
    return (
      <Fragment>
        <div className="ambits-chart-container">
          <div className="informative-panel-body container">
            <p>
              {getTranslation({
                lang: language,
                key: "FLOATING_PANEL.AMBITS_CHART.TITLE",
              })}
            </p>
            {loading ? (
              <Loader
                text={getTranslation({
                  lang: language,
                  key: "LOADING_MESSAGES.CHART_AMBITS",
                })}
              />
            ) : (
              <div className="bars-container">
                {_.map(chartData.bars, (bar, i) => {
                  return (
                    <div
                      key={"bar-" + i}
                      className="bar"
                      onClick={() => this.handleBarClick(bar)}
                    >
                      <p className="ambit-label">
                        <span
                          style={{
                            fontWeight: filteredAmbits.includes(bar["label"])
                              ? "bold"
                              : "normal",
                          }}
                        >
                          {bar["label"]}
                        </span>
                        <small>{FORMAT_THOUSANDS(bar["value"])}</small>
                      </p>
                      <div className="rail">
                        {bar["value"] > 0 && (
                          <div
                            className="track"
                            style={{
                              width:
                                (Number(bar["value"]) / chartData.maxValue) *
                                  100 +
                                "%",
                            }}
                          ></div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AmbitsChart;
