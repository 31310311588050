// Manage the translations of the app
// By page / component / so on...

export const ca = {
  HEADER: {
    TITLE: "Plataforma RIS3-MCAT",
    MENU: {
      QUERIES: "Consultes en SPARQL",
      HELP: "Ajuda en PDF",
    },
    INFORMATION_WINDOW: {
      INTRO_1: "Projecte de la",
      INTRO_2:
        ", l'estratègia per a l'especialització intel·ligent de Catalunya",
      UPDATE:
        "Data d’actualització: Horitzó Europa, gener del 2024; instruments de la RIS3CAT, octubre del 2021.",
      DATA_SOURCES: {
        TITLE: "Fonts de dades",
        SIFECAT_DESCRIPTION:
          "Sistema d'Informació de Fons Europeus de Catalunya",
      },
      LIBRARIES: {
        TITLE: "Biblioteques",
      },
      LISENCE: {
        TITLE: "Llicència",
        LINK_SUMMARY: "Resum de la llicència",
        LINK_COMPLETE: "Llicència completa",
      },
    },
    TABS: {
      NETWORK_TITLE: "Xarxa d'entitats",
      MAP_TITLE: "Mapa de projectes",
    },
  },
  FLOATING_PANEL: {
    AMBITS_CHART: {
      TITLE: "Àmbits d'actuació",
    },
  },
  TOOLS: {
    SUMMARY_MENU: {
      SHOW_FILTERS: "Visualitza els filtres aplicats",
      REMOVE_FILTERS: "Suprimeix els filtres",
    },
    TOOLS_BAR: {
      MENU: {
        FILTER: "Filtra",
        SEARCH: "Cerca",
        SORT: "Criteri d'agrupació d'entitats a la xarxa",
        SHOW: "Visualitza",
        ZOOM_IN: "Amplia el zoom",
        ZOOM_OUT: "Redueix el zoom",
        ZOOM_RESET: "Restableix la visualització",
        DOWNLOAD: "Descarrega en XLSX",
        LEGEND: "Llegenda",
      },
      LEGEND: {
        OPTION_1:
          "Projecte (la mida és proporcional al volum d'inversió; el color reflecteix la classificació en una temàtica determinada)",
        OPTION_2: "Col·laboració entre entitats",
        OPTION_3:
          "Entitat (el color classifica l'entitat d'acord amb el criteri d'agrupació)",
      },
    },
  },
  SEARCH_PANEL: {
    TITLE: "Cerques",
    BUTTON_APPLY: "Aplica",
    BUTTON_CANCEL: "Cancel·la",
    TITLE_KEYWORDS: "Cerca per paraules clau",
    PLACEHOLDER_KEYWORDS: "Selecciona una paraula clau",
    TITLE_PARTICIPANTS: "Nom d'entitat",
    PLACEHOLDER_PARTICIPANTS: "Selecciona una entitat",
    KEYWORDS_SEARCH: {
      RADIO_OPTION_1: "Projectes amb totes les paraules",
      RADIO_OPTION_2: "Projectes amb algunes de les paraules",
    },
  },
  FILTER_PANEL: {
    TITLE: "Filtres",
    BUTTON_APPLY: "Aplica",
    BUTTON_CANCEL: "Cancel·la",
    FILTERS: {
      CATEGORY_NAME: {
        TITLE: "Àmbits d'actuació",
        ALL: "Tots",
        OPTIONS: {
          sistemaAlimentari: "Sistema alimentari",
          sistemaCultural: "Sistema cultural",
          sistemaDeMobilitatILogistica: "Sistema de mobilitat i logística",
          sistemaDenergiaIRecursos: "Sistema d’energia i recursos",
          sistemaEducatiuIDeGeneracioDeConeixement:
            "Sistema educatiu i de generació de coneixement",
          sistemaIndustrial: "Sistema industrial",
          sistemaSociosanitari: "Sistema sociosanitari",
          senseClassificar: "Sense classificar",
        },
      },
      TOPICS: {
        TITLE: "Temàtica",
        ALL: "Totes",
      },
      SDGS: {
        TITLE: "Objectiu de desenvolupament sostenible (ODS)",
        ALL: "Tots",
        OPTIONS: {
          ODS_1: "ODS 1. Fi de la pobresa",
          ODS_2: "ODS 2. Fam zero",
          ODS_3: "ODS 3. Salut i benestar",
          ODS_4: "ODS 4. Educació de qualitat",
          ODS_5: "ODS 5. Igualtat de gènere",
          ODS_6: "ODS 6. Aigua neta i sanejament",
          ODS_7: "ODS 7. Energia neta i assequible",
          ODS_8: "ODS 8. Treball decent i creixement econòmic",
          ODS_9: "ODS 9. Indústria, innovació i infraestructures",
          ODS_10: "ODS 10. Reducció de les desigualtats",
          ODS_11: "ODS 11. Ciutats i comunitats sostenibles",
          ODS_12: "ODS 12. Producció i consum responsables",
          ODS_13: "ODS 13. Acció pel clima",
          ODS_14: "ODS 14. Vida submarina",
          ODS_15: "ODS 15. Vida terrestre",
          ODS_16: "ODS 16. Pau, justícia i institucions sòlides",
          ODS_17: "ODS 17. Aliances per a aconseguir els objectius",
          NA: "N/A",
        },
      },
      FRAMEWORKS: {
        TITLE: "Programa",
        OPTIONS: {
          horitzo2020: "Horitzó 2020",
          horitzoEuropa: "Horitzó Europa",
          instrumentsDeLaRis3Cat: "Instruments de la RIS3CAT",
        },
      },
      ACTIVITY_TYPE: {
        TITLE: "Tipus d'entitat",
        ALL: "Tots",
        OPTIONS: {
          HES: "Universitat",
          PRC: "Empresa",
          PUB: "Administració pública",
          REC: "Centre de recerca",
          CERCA: "Centre CERCA",
          OTH: "Altres",
        },
      },
      NUTS3: {
        TITLE: "Província",
        ALL: "Totes",
      },
      YEARS: {
        TITLE: "Període",
      },
    },
  },
  SORTING_PANEL: {
    TITLE: "Criteri d'agrupació d'entitats a la xarxa",
    BUTTON_CLOSE: "Tanca",
    OPTIONS: {
      OPTION_1: "Alta col·laboració",
      OPTION_2: "Tipus d'entitat",
      OPTION_3: "Programa",
      OPTION_4: "Província",
    },
    MULTIPLE_PROGRAMS: "Més d'un programa",
  },
  VISIBILITY_PANEL: {
    OPTIONS: {
      LABELS: "Etiquetes",
      PROJECTS: "Projectes",
      DENSITIES: "Densitats",
    },
  },
  SUMMARY: {
    PROJECT: "projecte",
    PROJECTS: "projectes",
    FUNDING: "d'inversió",
    ENTITY: "entitat",
    ENTITIES: "entitats",
    EXTERNAL_PARTNERS: "socis de l'exterior",
  },
  STATISTICS: {
    TABS: {
      AMBITS: "Àmbits d'actuació",
      ENTITIES: "Entitats",
      CATALONIA_PARTNERS: "Socis de Catalunya",
      EXTERNAL_PARTNERS: "Socis de l'exterior",
      PROJECTS: "Projectes",
    },
    AMBITS: {
      TITLE_1_COUNT:
        "Rànquing d'àmbits d'actuació segons el nombre de projectes",
      TITLE_1_FUNDING:
        "Rànquing d'àmbits d'actuació segons l'import de la inversió",
      TITLE_2: "Núvol de paraules clau dels projectes",
      TITLE_2_SUBTITLE:
        "Filtra el núvol de paraules desplaçant el cursor pel gràfic de barres",
      RADIO_OPTIONS: {
        COUNT: "Nombre de projectes",
        FUNDING: "Import de la inversió",
      },
    },
    ENTITIES: {
      TITLE_1: "Rànquing d'entitats segons nombre de projectes",
      TITLE_2: "Rànquing d'entitats segons import de la inversió",
      TITLE_SOCIS_1:
        "Rànquing de socis de Catalunya segons nombre de projectes",
      TITLE_SOCIS_2:
        "Rànquing de socis de Catalunya segons import de la inversió",
      DOWNLOAD_EXCEL: "Descarrega les dades en XLSX",
    },
    EXTERNAL_PARTNERS: {
      TITLE_1_COUNT:
        "Rànquing de països de procedència dels socis de l'exterior segons nombre de projectes",
      TITLE_2_COUNT:
        "Rànquing de socis de l'exterior segons nombre de projectes",
      TITLE_1_FUNDING:
        "Rànquing de països de procedència dels socis de l'exterior segons l'import de la inversió",
      TITLE_2_FUNDING:
        "Rànquing de socis de l'exterior segons l'import de la inversió",
      RADIO_OPTIONS: {
        COUNT: "Nombre de projectes",
        FUNDING: "Import de la inversió",
      },
      DOWNLOAD_EXCEL: "Descarrega les dades en XLSX",
      COORDINATORS_CHECKBOX: "Considera només els socis coordinadors",
      NUTS2_TITLE: "Rànquing NUTS2",
      NUTS3_TITLE: "Rànquing NUTS3",
    },
    PROJECTS: {
      TABLE: {
        HEADER: {
          PROJECT_TITLE: "Títol del projecte",
          PROGRAM: "Programa",
          AMBIT: "Àmbit d'actuació",
          ENTITY: "Entitat",
          YEAR: "Data d'inici",
          FUNDING: "Inversió",
          GRANT: "Subvenció",
        },
        ACTIONS: {
          labelDisplayedRows: "de",
          labelRowsPerPage: "Files per pàgina:",
          columnMenuLabel: "Menú",
          columnMenuShowColumns: "Mostra columnes",
          columnMenuManageColumns: "Gestionar columnes",
          columnMenuFilter: "Filtre",
          columnMenuHideColumn: "Oculta la columna",
          columnMenuUnsort: "Desordenar",
          columnMenuSortAsc: "Ordena per ASC",
          columnMenuSortDesc: "Ordena per DESC",
          filterPanelAddFilter: "Afegeix un filtre",
          filterPanelRemoveAll: "Esborra-ho tot",
          filterPanelDeleteIconLabel: "Suprimeix",
          filterPanelLogicOperator: "Operador lògic",
          filterPanelOperator: "Operador",
          filterPanelOperatorAnd: "And",
          filterPanelOperatorOr: "Or",
          filterPanelColumns: "Columnes",
          filterPanelInputLabel: "Valor",
          filterPanelInputPlaceholder: "Valor del filtre",
          footerTotalRows: "Total de files:",
          rowPerPageTranslation: "Files per pàgina",
        },
      },
    },
  },
  INFO_WINDOWS: {
    PROJECT: {
      TITLE: "Titol",
      PROGRAM: "Programa",
      YEAR: "Any",
      TOTAL_FUNDING: "Inversió",
      PARTICIPANTS: "Entitats de Catalunya",
      BUTTON_PROJECT: "Projecte",
    },
    ENTITY: {
      NUMBER_OF_PROJECTS: "Nombre de projectes",
      TOTAL_FUNDING: "Inversió",
      BUTTON_PROJECT: "Projecte",
      BUTTON_PROJECTS: "Projectes",
    },
  },
  MODAL_WINDOWS: {
    PROJECT: {
      DESCRIPTION: "Descripció",
      FUNDING: "Inversió",
      GRANT: "Subvenció",
      PROGRAM: "Programa de finançament",
      AMBITS: "Àmbits d'actuació",
      KEYWORDS: "Paraules clau",
      ENTITIES: "Entitats",
    },
  },
  LOADING_MESSAGES: {
    LOADING_DATA: "S'estan carregant les dades...",
    INITIAL_LOAD: {
      STEP_1: "1/6 - S'està carregant informació dels projectes",
      STEP_2: "2/6 - S'està carregant informació de les temàtiques",
      STEP_3: "3/6 - S'està carregant informació d'àmbits d'actuació",
      STEP_4: "4/6 - S'està carregant informació dels socis de l'exterior",
      STEP_5: "5/6 - S'està carregant informació de les entitats",
      STEP_6: "6/6 - S'està carregant informació de les paraules clau",
    },
    PROCESSANT_INFORMACIO: "S'està processant la informació",
    DOWNLOAD: "S'estan processant les dades per a la descàrrega",
    CHART_AMBITS: "S'està carregant informació dels àmbits d'actuació",
    CHART_PROGRAMS: "S'està carregant informació dels programes de finançament",
    NETWORK: "S'està actualitzant la xarxa",
    MAP: "S'està actualitzant el mapa de projectes",
  },
  DOWNLOAD_HEADERS: {
    PROJECT_ID: "Identificador del projecte",
    PROJECT_TITLE: "Títol del projecte",
    PROJECT_ABSTRACT: "Resum del projecte",
    PROJECT_YEAR: "Any d'inici",
    PROJECT_AMBIT: "Àmbit d'actuació",
    ENTITY_NAME: "Nom d'entitat",
    ENTITY_TYPE: "Tipus d'entitat",
    ENTITY_ROLE: "Rol en el projecte",
    ENTITY_COUNTRY: "País",
    ENTITY_NUTS2: "Regió",
    ENTITY_NUTS3: "Província",
    FUNDING: "Inversió",
    GRANT: "Subvenció",
    TOTAL_FUNDING: "Inversió total",
    TOTAL_GRANT: "Subvenció total",
  },
};
