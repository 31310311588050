import React, { Component, Fragment } from "react";
import "./index.scss";

import _ from "lodash";
import * as d3 from "d3";
import { AmbitsTable } from "../AmbitsTable";
// import { LineChart } from "./LineChart";
// import { BarChart } from "./BarChart";
import { WordCloud } from "./WordCloud";
import {
  FORMAT_THOUSANDS,
  FORMAT_MILLIONS_CUSTOM,
} from "../../common/constants";

import { getTranslation } from "../../common/translations";

const colorScale = d3
  .scaleLinear()
  .interpolate(d3.interpolateHcl)
  .range([d3.rgb("#c00000"), d3.rgb("#430000")]);

class StatisticsTabAmbits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      wordCloudData: [],
      optionsNumbers: [
        {
          label: getTranslation({
            lang: props.language,
            key: "STATISTICS.AMBITS.RADIO_OPTIONS.COUNT",
          }),
          value: "count",
        },
        {
          label: getTranslation({
            lang: props.language,
            key: "STATISTICS.AMBITS.RADIO_OPTIONS.FUNDING",
          }),
          value: "funding",
        },
      ],
      selectedOption: "count",
    };
    this.wordcloudContainer = React.createRef();
  }

  componentDidMount() {
    this.setState({
      loading: false,
      tableData: this.getAmbitsData({
        data: this.props.data["ambitsData"],
        option: this.state.selectedOption,
      }),
      wordCloudData: this.getWordCloudData(this.props.data["ambitsData"]),
      worcloudContainerMeasures: {
        width: this.wordcloudContainer.current.offsetWidth,
        height: this.wordcloudContainer.current.offsetHeight,
      },
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      _.isEqual(prevProps.data["ambitsData"], this.props.data["ambitsData"]) &&
      _.isEqual(prevState.selectedOption, this.state.selectedOption)
    ) {
      // console.log(">>>>>>>>>>>> AMBITS TABLE -> DO NOTHING");
      return false;
    } else {
      // console.log(">>>>>>>>>>>> AMBITS TABLE -> UPDATE");
      this.setState({
        tableData: this.getAmbitsData({
          data: this.props.data["ambitsData"],
          option: this.state.selectedOption,
        }),
        wordCloudData: this.getWordCloudData(this.props.data["ambitsData"]),
        worcloudContainerMeasures: {
          width: this.wordcloudContainer.current.offsetWidth,
          height: this.wordcloudContainer.current.offsetHeight,
        },
      });
    }
  }

  getAmbitsData = ({ data = null, option = null }) => {
    // Find if years range is filtered
    // const rangeFound = _.find(
    //   this.props.filters.selected,
    //   (filter) => filter.type === "years"
    // );
    // const yearsTotalRange = rangeFound
    //   ? rangeFound["values"]
    //   : this.props.filters.years;

    // Convert list of objects to array of objects
    let currentData = Object.entries(data);

    // Sort by number of projects
    if (option === "count") {
      currentData = _.sortBy(currentData, (ambit) => ambit[1].length).reverse();
    } else if (option === "funding") {
      currentData = _.sortBy(currentData, (ambit) =>
        _.sumBy(ambit[1], "funding")
      ).reverse();
    }

    // Create an extent for the bars
    const extent = d3.extent(currentData, (ambit) => ambit[1].length);

    // Extent for funding
    const extentFunding = d3.extent(currentData, (ambit) =>
      _.sumBy(ambit[1], "funding")
    );

    // if (filteredProjects.length > 0) {
    //   currentData = _.filter(currentData, (project) =>
    //     filteredProjects.includes(project["id"])
    //   );
    // }

    // Create linechart data array
    let fullRangeYearsData = [];
    // Get the total projects
    const totalProjects = _.map(currentData, (ambit) => {
      return ambit[1];
    }).flat();

    // Group projects by year
    const projectsByYear = _.groupBy(totalProjects, "year");
    const yearsTotalRange = Object.keys(projectsByYear);
    // remove undefined values from yearsTotalRange
    _.remove(yearsTotalRange, (year) => year === "undefined");

    const totalFullRangeYearsData = _.concat(
      _.map(yearsTotalRange, (year) => {
        return {
          year: year,
          value: projectsByYear[year] ? projectsByYear[year].length : 0,
          serie: "total",
        };
      })
    );

    let rowsData = _.map(currentData, (ambit) => {
      const projectsByYear = _.groupBy(ambit[1], "year");
      // Count projects by year
      const yearsData = _.map(
        Object.entries(projectsByYear),
        ([key, values]) => {
          return {
            year: key,
            value: values.length,
          };
        }
      );

      fullRangeYearsData = _.concat(
        _.map(yearsTotalRange, (year) => {
          const found = _.find(
            yearsData,
            (d) => Number(d["year"]) === Number(year)
          );
          return {
            year: year,
            value: found ? found["value"] : 0,
            serie: "ambit",
          };
        })
      );

      fullRangeYearsData = _.concat(
        totalFullRangeYearsData,
        fullRangeYearsData
      );

      return {
        id: ambit[0],
        col1: (
          <p>
            {getTranslation({
              lang: this.props.language,
              key:
                "FILTER_PANEL.FILTERS.CATEGORY_NAME.OPTIONS." +
                _.camelCase(ambit[0]),
            })}
          </p>
        ), // KEY
        col2: this.createBarSparkline({
          ambitName: ambit[0],
          projectsData: {
            percentage: ambit[1].length / extent[1],
            value: ambit[1].length,
          },
          fundingData: {
            percentage: _.sumBy(ambit[1], "funding") / extentFunding[1],
            value: _.sumBy(ambit[1], "funding"),
          },
          option: option,
        }),
        // col3: (
        //   <BarChart
        //     data={fullRangeYearsData}
        //     fullYearsRange={yearsTotalRange}
        //     x={(d, i) => i}
        //     y={(d) => d["normalisedValue"]}
        //     z={(d) => d["serie"]}
        //     width={180}
        //     height={30}
        //     color={colorScale(ambit[1].length / extent[1])}
        //   />
        //   // <LineChart
        //   //   data={fullRangeYearsData}
        //   //   x={(d) => d.year}
        //   //   y={(d) => d["value"]}
        //   //   z={(d) => d["serie"]}
        //   //   width={180}
        //   //   height={30}
        //   //   color={colorScale(ambit[1].length / extent[1])}
        //   // />
        // ),
      };
    });

    let headersData = [
      {
        Header: "Àmbit d'actuació",
        accessor: "col1",
        disableFilters: true,
      },
      {
        Header: "Projectes",
        accessor: "col2",
        disableFilters: true,
      },
      {
        Header: "Evolució",
        accessor: "col3",
        disableFilters: true,
      },
    ];

    const result = {
      rowsData: rowsData,
      headersData: headersData,
    };

    return result;
  };

  // Create a bar sparkline to show the distribution of the data
  createBarSparkline = ({
    projectsData = null,
    fundingData = null,
    option = "",
    ambitName = "",
  }) => {
    // console.log("CREATE BAR SPARKLINE");
    // console.log("-> PROJECTS DATA", projectsData);
    // console.log("-> FUNDING DATA", fundingData);
    // console.log("-> AMBIT NAME", ambitName);

    const showingData = option === "count" ? projectsData : fundingData;

    return (
      <div className="sparkline container">
        <div
          className="row"
          // onMouseOver={() => this.handleOverOnRow(ambitName)}
          // onMouseOut={() => this.handleOverOnRow(null)}
        >
          <div className={`col px-0 ${option === 'count' ? 'col-4' : 'col-5'}`}>
            <span className="bar-label">
              {option === "count"
                ? FORMAT_THOUSANDS(showingData["value"])
                : FORMAT_MILLIONS_CUSTOM(showingData["value"])}
            </span>
          </div>
          <div className="col">
            <div className="rail">
              <div
                className="track"
                style={{
                  width: `${showingData["percentage"] * 100}%`,
                  backgroundColor: colorScale(showingData["percentage"]),
                }}
              ></div>
              {/* <div
                className="subtrack"
                style={{
                  width: `${fundingData["percentage"] * 100}%`,
                }}
              >
                <span
                  className="label"
                  style={{
                    color:
                      `${fundingData["percentage"] * 100}` > 50
                        ? "white"
                        : "black",
                    left:
                      `${fundingData["percentage"] * 100}` > 50
                        ? "initial"
                        : "100%",
                    right:
                      `${fundingData["percentage"] * 100}` > 50
                        ? "0px"
                        : "initial",
                  }}
                >
                  {fundingData["value"]}
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Get the keywords from the projects
  getWordCloudData = (data, filter) => {
    data = _.flatMap(data);

    if (filter) {
      data = _.filter(data, (project) => {
        return project["categoryName"].includes(filter);
      });
    }

    let keywords = [];
    _.forEach(data, (project) => {
      if (project["keywords"]) {
        keywords = keywords.concat(project["keywords"]);
      } else {
        console.log("NO KEYWORDS", project);
      }
    });
    return keywords;
  };

  handleOverOnRow = (row) => {
    // console.log("HANDLE OVER ON ROW");
    // console.log("-> ROW", row);
    this.setState({
      wordCloudData:
        row !== null
          ? this.getWordCloudData(this.props.data["ambitsData"], row)
          : this.getWordCloudData(this.props.data["ambitsData"]),
      filtered: row !== null ? true : false,
    });
  };

  handleRadioOption = (event) => {
    this.setState({
      selectedOption: event["target"]["value"],
    });
  };

  render() {
    const {
      loading,
      tableData,
      wordCloudData,
      worcloudContainerMeasures,
      filtered,
      optionsNumbers,
      selectedOption,
    } = this.state;

    const { language } = this.props;

    return (
      <Fragment>
        <div className="container-fluid tab-container tab-container-ambits">
          <div className="row subheader">
            <div className="col col-auto ms-auto me-auto">
              <div
                className="btn-group btn-group-sm statistics-group"
                role="group"
                aria-label=""
              >
                {_.map(optionsNumbers, (option, index) => {
                  return (
                    <span key={"option" + index}>
                      <input
                        className="btn-check"
                        type="radio"
                        name="inlineRadioOptions"
                        id={"inlineRadio" + option["value"]}
                        autoComplete="off"
                        value={option["value"]}
                        checked={
                          selectedOption === option["value"] ? true : false
                        }
                        onChange={this.handleRadioOption}
                      />
                      <label
                        className="btn btn-secondary"
                        htmlFor={"inlineRadio" + option["value"]}
                      >
                        {option["label"]}
                      </label>
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
          <header className="row header">
            <div className="col col-5">
              <h4>
                {selectedOption === "count"
                  ? getTranslation({
                      lang: language,
                      key: "STATISTICS.AMBITS.TITLE_1_COUNT",
                    })
                  : getTranslation({
                      lang: language,
                      key: "STATISTICS.AMBITS.TITLE_1_FUNDING",
                    })}
              </h4>
            </div>
            <div className="col col-7">
              <h4>
                {getTranslation({
                  lang: language,
                  key: "STATISTICS.AMBITS.TITLE_2",
                })}{" "}
                <span className="d-block text-center">
                  {getTranslation({
                    lang: language,
                    key: "STATISTICS.AMBITS.TITLE_2_SUBTITLE",
                  })}
                </span>
              </h4>
            </div>
          </header>
          <div className="row body w-subheader">
            <div className="col col-5 d-flex justify-content-end">
              {loading ? (
                <p>Carregant</p>
              ) : (
                <AmbitsTable
                  tableData={tableData}
                  handleOverOnRow={this.handleOverOnRow}
                ></AmbitsTable>
              )}
            </div>
            <div className="col col-7" ref={this.wordcloudContainer}>
              {loading ? (
                <p>Carregant</p>
              ) : (
                <WordCloud
                  text={wordCloudData}
                  worcloudContainerMeasures={worcloudContainerMeasures}
                  filtered={filtered}
                ></WordCloud>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default StatisticsTabAmbits;
