import React, { Component, Fragment } from "react";
import "./index.scss";

import _ from "lodash";
import { FORMAT_EURO } from "../../common/constants";

import { DataGrid } from "@mui/x-data-grid";

import { getTranslation } from "../../common/translations";

class StatisticsTabProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({
      loading: false,
      tableData: this.getProjectsData({
        data: this.props.data.topics.data,
        filteredProjects: this.props.filters["filteredProjects"],
      }),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // BETTER CHECK FILTERED_PROJECTS (we filter inside the component)
    if (
      _.isEqual(
        prevProps.filters["filteredProjects"],
        this.props.filters["filteredProjects"]
      ) &&
      _.isEqual(prevProps.data.topics.data, this.props.data.topics.data) &&
      this.props.language === prevProps.language
    ) {
      // console.log("PROJECTS TABLE -> DO NOTHING");
      return false;
    } else {
      // console.log("PROJECTS TABLE -> UPDATE");
      this.setState({
        tableData: this.getProjectsData({
          data: this.props.data.topics.data,
          filteredProjects: this.props.filters["filteredProjects"],
        }),
      });
    }
  }

  getProjectsData = ({ data = null, filteredProjects = [] }) => {
    let currentData = data;
    let rowsData = [];

    if (filteredProjects.length > 0) {
      currentData = _.filter(currentData, (project) =>
        filteredProjects.includes(project["id"])
      );
    }

    // If is there not participants data we are with 0 results
    // then we return an empty array
    if (this.props.filters.populate.participants.options.length === 0) {
      return {
        rowsData: [],
        headersData: [],
      };
    } else {
      rowsData = _.map(currentData, (project) => {
        return {
          id: project["id"],
          col1: project["title"],
          col2: project["programaName"],
          col3: project["categoryName"],
          col4: project["participants"],
          col5: project["year"],
          col6: project["funding"],
          col7: project["grant"],
        };
      });
    }

    // Columns
    let headersData = [
      {
        headerName: getTranslation({
          lang: this.props.language,
          key: "STATISTICS.PROJECTS.TABLE.HEADER.PROJECT_TITLE",
        }),
        field: "col1",
        flex: 1,
        headerClassName: "projects-list--header",
      },
      {
        headerName: getTranslation({
          lang: this.props.language,
          key: "STATISTICS.PROJECTS.TABLE.HEADER.PROGRAM",
        }),
        field: "col2",
        flex: 0.75,
        renderCell: (params) => {
          return (
            <p className="m-0">
              {
                // Split the formattedValue key by character -
                // and get the fisrt element of the array
                // to get the value of the program
                getTranslation({
                  lang: this.props.language,
                  key:
                    "FILTER_PANEL.FILTERS.FRAMEWORKS.OPTIONS." +
                    _.camelCase(params.formattedValue.split("-")[0].trim()),
                }) +
                  " - " +
                  params.formattedValue.split(" - ")[1].trim()
              }
            </p>
          );
        },
      },
      {
        headerName: getTranslation({
          lang: this.props.language,
          key: "STATISTICS.PROJECTS.TABLE.HEADER.AMBIT",
        }),
        field: "col3",
        flex: 0.75,
        renderCell: (params) => {
          return (
            <p className="m-0">
              {params.value.map((category, index) => (
                <span className="d-block" key={"category" + index}>
                  {getTranslation({
                    lang: this.props.language,
                    key:
                      "FILTER_PANEL.FILTERS.CATEGORY_NAME.OPTIONS." +
                      _.camelCase(category),
                  })}
                </span>
              ))}
            </p>
          );
        },
      },
      {
        headerName: getTranslation({
          lang: this.props.language,
          key: "STATISTICS.PROJECTS.TABLE.HEADER.ENTITY",
        }),
        field: "col4",
        flex: 1,
        renderCell: (params) => {
          return (
            <p className="m-0">
              {params.value.map((participant, index) => (
                <span className="d-block" key={"participant" + index}>
                  {_.find(
                    this.props.filters.populate.participants.options,
                    (option) => option["organizationId"] === participant
                  ) !== undefined
                    ? _.find(
                        this.props.filters.populate.participants.options,
                        (option) => option["organizationId"] === participant
                      )["label"]
                    : ""}
                </span>
              ))}
            </p>
          );
        },
      },
      {
        headerName: getTranslation({
          lang: this.props.language,
          key: "STATISTICS.PROJECTS.TABLE.HEADER.YEAR",
        }),
        field: "col5",
        headerAlign: "right",
        align: "right",
      },
      {
        headerName: getTranslation({
          lang: this.props.language,
          key: "STATISTICS.PROJECTS.TABLE.HEADER.FUNDING",
        }),
        field: "col6",
        headerAlign: "right",
        align: "right",
        valueFormatter: (params) => {
          return FORMAT_EURO(params.value);
        },
      },
      {
        headerName: getTranslation({
          lang: this.props.language,
          key: "STATISTICS.PROJECTS.TABLE.HEADER.GRANT",
        }),
        field: "col7",
        headerAlign: "right",
        align: "right",
        valueFormatter: (params) => {
          return FORMAT_EURO(params.value);
        },
      },
    ];

    const result = {
      rowsData: rowsData,
      headersData: headersData,
    };

    return result;
  };

  // getGridData = ({ data = null, filteredProjects = [] }) => {};

  openProjectModal = (event) => {
    this.props.setClickedProject({ id: event.id });
  };

  render() {
    const { loading, tableData } = this.state;

    return (
      <Fragment>
        {loading ? (
          <p>Loading</p>
        ) : (
          <div className="container-fluid tab-container tab-container-projects bg-white">
            <DataGrid
              rows={tableData.rowsData}
              columns={tableData.headersData}
              getRowHeight={() => "auto"}
              density="standard"
              sx={{ fontFamily: "Open Sans" }}
              // hideFooter={true}
              onRowClick={(e) => this.openProjectModal(e)}
              componentsProps={{
                pagination: {
                  labelRowsPerPage: getTranslation({
                    lang: this.props.language,
                    key: "STATISTICS.PROJECTS.TABLE.ACTIONS.labelRowsPerPage",
                  }),
                },
              }}
              localeText={{
                MuiTablePagination: {
                  labelDisplayedRows: ({ from, to, count }) =>
                    `${from} - ${to} ${getTranslation({
                      lang: this.props.language,
                      key: "STATISTICS.PROJECTS.TABLE.ACTIONS.labelDisplayedRows",
                    })} ${count}`,
                },
                // Column menu text
                columnMenuLabel: getTranslation({
                  lang: this.props.language,
                  key: "STATISTICS.PROJECTS.TABLE.ACTIONS.columnMenuLabel",
                }),
                columnMenuShowColumns: getTranslation({
                  lang: this.props.language,
                  key: "STATISTICS.PROJECTS.TABLE.ACTIONS.columnMenuShowColumns",
                }),
                columnMenuManageColumns: getTranslation({
                  lang: this.props.language,
                  key: "STATISTICS.PROJECTS.TABLE.ACTIONS.columnMenuManageColumns",
                }),
                columnMenuFilter: getTranslation({
                  lang: this.props.language,
                  key: "STATISTICS.PROJECTS.TABLE.ACTIONS.columnMenuFilter",
                }),
                columnMenuHideColumn: getTranslation({
                  lang: this.props.language,
                  key: "STATISTICS.PROJECTS.TABLE.ACTIONS.columnMenuHideColumn",
                }),
                columnMenuUnsort: getTranslation({
                  lang: this.props.language,
                  key: "STATISTICS.PROJECTS.TABLE.ACTIONS.columnMenuUnsort",
                }),
                columnMenuSortAsc: getTranslation({
                  lang: this.props.language,
                  key: "STATISTICS.PROJECTS.TABLE.ACTIONS.columnMenuSortAsc",
                }),
                columnMenuSortDesc: getTranslation({
                  lang: this.props.language,
                  key: "STATISTICS.PROJECTS.TABLE.ACTIONS.columnMenuSortDesc",
                }),
                // Filter panel text
                filterPanelAddFilter: getTranslation({
                  lang: this.props.language,
                  key: "STATISTICS.PROJECTS.TABLE.ACTIONS.filterPanelAddFilter",
                }),
                filterPanelRemoveAll: getTranslation({
                  lang: this.props.language,
                  key: "STATISTICS.PROJECTS.TABLE.ACTIONS.filterPanelRemoveAll",
                }),
                filterPanelDeleteIconLabel: getTranslation({
                  lang: this.props.language,
                  key: "STATISTICS.PROJECTS.TABLE.ACTIONS.filterPanelDeleteIconLabel",
                }),
                filterPanelLogicOperator: getTranslation({
                  lang: this.props.language,
                  key: "STATISTICS.PROJECTS.TABLE.ACTIONS.filterPanelLogicOperator",
                }),
                filterPanelOperator: getTranslation({
                  lang: this.props.language,
                  key: "STATISTICS.PROJECTS.TABLE.ACTIONS.filterPanelOperator",
                }),
                filterPanelOperatorAnd: getTranslation({
                  lang: this.props.language,
                  key: "STATISTICS.PROJECTS.TABLE.ACTIONS.filterPanelOperatorAnd",
                }),
                filterPanelOperatorOr: getTranslation({
                  lang: this.props.language,
                  key: "STATISTICS.PROJECTS.TABLE.ACTIONS.filterPanelOperatorOr",
                }),
                filterPanelColumns: getTranslation({
                  lang: this.props.language,
                  key: "STATISTICS.PROJECTS.TABLE.ACTIONS.filterPanelColumns",
                }),
                filterPanelInputLabel: getTranslation({
                  lang: this.props.language,
                  key: "STATISTICS.PROJECTS.TABLE.ACTIONS.filterPanelInputLabel",
                }),
                filterPanelInputPlaceholder: getTranslation({
                  lang: this.props.language,
                  key: "STATISTICS.PROJECTS.TABLE.ACTIONS.filterPanelInputPlaceholder",
                }),
                // Total row amount footer text
                footerTotalRows: getTranslation({
                  lang: this.props.language,
                  key: "STATISTICS.PROJECTS.TABLE.ACTIONS.footerTotalRows",
                }),
                rowPerPageTranslation: getTranslation({
                  lang: this.props.language,
                  key: "STATISTICS.PROJECTS.TABLE.ACTIONS.rowPerPageTranslation",
                }),
              }}
            />
            {/* <ProjectsTable
              tableData={tableData}
              openProjectModal={this.openProjectModal}
            ></ProjectsTable> */}
          </div>
        )}
      </Fragment>
    );
  }
}

export default StatisticsTabProjects;
