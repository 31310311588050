import React, { Component, Fragment } from "react";
import _ from "lodash";
import "./index.scss";
import CanvasToolsBar from "../CanvasToolsBar";
import CanvasToolsPanel from "../CanvasToolsPanel";
import {
  PROPERTIES_TO_KEYS,
} from "../../common/constants";
import { getTranslation } from "../../common/translations";

class CanvasTools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      filtersRecapIsVisible: false,
    };
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  resetFilters = () => {
    this.props.setActiveTool("");
    this.props.resetAllFilters();
  };

  filtersRecapIsVisible = (bool) => {
    this.setState({
      filtersRecapIsVisible: bool,
    });
  };

  render() {
    const {
      tabs,
      data,
      filters,
      setFilter,
      setSearch,
      setFilters,
      resetAllFilters,
      setActiveTool,
      tools,
      resetAllSearches,
      sorting,
      setNetworkSortingCriteria,
      setZoom,
      setTopicsZoom,
      dwnldExcel,
      visibilityLayers,
      setVisibilityLayers,
      language,
    } = this.props;

    return (
      <Fragment>
        {filters.selected && filters.selected.length > 0 && (
          <div className="active-filters-bar">
            {/* Button to show the applied filters */}
            <button
              className="btn btn-link"
              onMouseOver={() => this.filtersRecapIsVisible(true)}
              onMouseOut={() => this.filtersRecapIsVisible(false)}
            >
              {getTranslation({
                lang: language,
                key: "TOOLS.SUMMARY_MENU.SHOW_FILTERS",
              })}
            </button>
            &nbsp;|&nbsp;
            {/* Button to reset all filters */}
            <button className="btn btn-link" onClick={this.resetFilters}>
              {getTranslation({
                lang: language,
                key: "TOOLS.SUMMARY_MENU.REMOVE_FILTERS",
              })}
            </button>
            {/* Container to show the applied filters */}
            {this.state.filtersRecapIsVisible && (
              <div className="active-filters-bar-list-container">
                {filters.selected.map((f) => (
                  <div className="active-filter" key={f.type}>
                    {f.values.length > 0 && (
                      <p className="filter-label">
                        {getTranslation({
                          lang: language,
                          key:
                            "FILTER_PANEL.FILTERS." +
                            PROPERTIES_TO_KEYS[f.type] +
                            ".TITLE",
                        })}
                      </p>
                    )}
                    <ul>
                      {_.map(f.values, (v, i) => (
                        <li key={"filter" + i} className="filter-value">
                          {f.type === "activityType"
                            ? _.find(
                                filters.populate["activityType"]["options"],
                                (option) => option["value"] === v
                              )["label"]
                            : f.type === "nuts3"
                            ? _.find(
                                filters.populate["nuts3"]["options"],
                                (option) => option["value"] === v
                              )["label"]
                            : f.type === "participants"
                            ? _.find(
                                filters.populate["participantsReference"][
                                  "options"
                                ],
                                (option) => option["value"] === v
                              )["label"]
                            : f.type === "topics"
                            ? _.find(
                                filters.populate["topics"]["options"],
                                (option) => option["value"] === Number(v)
                              )["label"]
                            : f.type === "categoryName"
                            ? _.find(
                                filters.populate["categoryName"]["options"],
                                (option) => option["value"] === v
                              )["label"]
                            : v}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <div className="canvas-tools-container">
          <CanvasToolsBar
            activeTab={tabs.active}
            activeTool={tools.active}
            setActiveTool={setActiveTool}
            resetAllSearches={resetAllSearches}
            resetAllFilters={resetAllFilters}
            filters={filters}
            setZoom={setZoom}
            setTopicsZoom={setTopicsZoom}
            dwnldExcel={dwnldExcel}
            networkData={data["networkData"]}
            visibilityLayers={visibilityLayers}
            setVisibilityLayers={setVisibilityLayers}
            lang={language}
          ></CanvasToolsBar>
          <CanvasToolsPanel
            data={data}
            filters={filters}
            setFilter={setFilter}
            setSearch={setSearch}
            setFilters={setFilters}
            resetAllFilters={resetAllFilters}
            activeTool={tools.active}
            sorting={sorting}
            setNetworkSortingCriteria={setNetworkSortingCriteria}
            setActiveTool={setActiveTool}
            lang={language}
          ></CanvasToolsPanel>
        </div>
      </Fragment>
    );
  }
}

export default CanvasTools;
