import React, { Component, Fragment } from "react";
import _ from "lodash";
import "./index.scss";
import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";

class HierarchyFilterCheckboxes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      options: {},
      expandedItems: [],
    };
  }

  componentDidMount() {

    this.setState({
      loading: false,
      options: this.props.options,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // PrevProps
    // console.log("DID UPDATE ------ prevState", prevState);
    // console.log("DID UPDATE ------ THIS.STATE", this.state);
    // console.log("DID UPDATE ------ prevProps", prevProps);
    // console.log("DID UPDATE ------ THIS.PROPS", this.props);
    if (
      _.isEqual(this.props["selectedFilters"], prevProps["selectedFilters"]) &&
      _.isEqual(this.state["expandedItems"], prevState["expandedItems"]) 
    ) {
      // console.log("HIERARCHY FILTER -> NOTHING TO DO");
    } else {
      // console.log("HIERARCHY FILTER -> UPDATE");
      // return false;

      // 1. Find currentfilter by entity
      let activeFilterType = _.find(
        this.props.selectedFilters,
        (filter) => filter["type"] === this.props["entity"]
      );

      // if (activeFilterType) {
      //   console.log("activeFilterType", activeFilterType);
      // }


      const activeFilter = activeFilterType;

      let options = this.props.options;
      // console.log("ACTIVE FILTERS", activeFilter);
      // console.log("OPTIONS", options);

      if (activeFilter) {
        _.map(options, (option) => {
          if (activeFilter["values"].includes(option["value"])) {
            option["checked"] = true;
            _.map(option["children"], (child) => {
              child["checked"] = true;
              return child;
            });
          } else {
            option["checked"] = false;
            _.map(option["children"], (child) => {
              if (activeFilter["values"].includes(child["value"])) {
                // option["expanded"] = true;
                child["checked"] = true;
              } else {
                child["checked"] = false;
              }
              return child;
            });
          }
          option["expanded"] = this.state.expandedItems.includes(
            option["value"]
          )
            ? true
            : false;

          // _.map(option["children"], (child) => {
          //   // child["checked"] = false;
          //   if (activeFilter["values"].includes(child["label"])) {
          //     child["checked"] = true;
          //   }
          //   return child;
          // });

          return option;
        });
      }
      // When remove filters
      else {
        _.map(options, (option) => {
          option["checked"] = false;
          _.map(option["children"], (child) => {
            child["checked"] = false;
            return child;
          });
          option["expanded"] = this.state.expandedItems.includes(
            option["value"]
          )
            ? true
            : false;
          return option;
        });
      }
      // console.log("AFTER OPTIONS", options);

      this.setState({
        options: options,
      });
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (_.isEqual(this.props["selectedFilters"], nextProps["selectedFilters"])) {

  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  onChange = (currentNode, selectedNodes) => {
    // console.log("onChange::");
    // console.log("currentNode", currentNode);
    // console.log("selectedNodes", selectedNodes);
    let filter = {
      level1: {
        values: [],
      },
      level2: {
        values: [],
      },
      values: [],
    };

    _.map(selectedNodes, (node) => {
      if (node["level"] === 1) {
        filter["level1"].values.push(node["value"]);
      } else {
        filter["level2"].values.push(node["value"]);
      }
      filter["values"].push(node["value"]);
    });

    filter["type"] = "frameworks";

    this.props.storeHierarchyFilter(filter);
  };
  onAction = (node, action) => {
    // console.log("onAction::", action, node);
  };
  onNodeToggle = (currentNode) => {
    // console.log("onNodeToggle::", currentNode);
    let currentExpanded = [...this.state.expandedItems];

    if (currentNode["expanded"] === true) {
      currentExpanded.push(currentNode["value"]);
    } else {
      currentExpanded = _.filter(
        currentExpanded,
        (entry) => entry !== currentNode["value"]
      );
    }

    // Need to store the position of expanded
    this.setState({
      expandedItems: currentExpanded,
    });
  };

  render() {
    // console.log("HIERARCHY_FILTER RENDER");
    // console.log("this.props", this.props);
    const { loading, options } = this.state;

    return (
      <Fragment>
        <div className="filter-container-hierarchy">
          {loading ? (
            "<p>S'està carregant</p>"
          ) : (
            <DropdownTreeSelect
              data={options}
              onChange={this.onChange}
              onAction={this.onAction}
              onNodeToggle={this.onNodeToggle}
              showDropdown={"always"}
            />
          )}
        </div>
      </Fragment>
    );
  }
}

export default HierarchyFilterCheckboxes;
