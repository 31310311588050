import React, { Component, Fragment } from "react";
import _ from "lodash";

import { getProjectParticipantsInformation } from "../../data/DataService";
import { FORMAT_EURO, CONFIG } from "../../common/constants";

import { FaTimesCircle } from "react-icons/fa";

import "./index.scss";

import { getTranslation } from "../../common/translations";

class TopicPanelInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    if (this.props.clickedDot) {
      let participantsInProject = await getProjectParticipantsInformation(
        this.props.clickedDot
      );
      this.setState({
        projectParticipantsInfo: participantsInProject,
      });
    }
  }

  async componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.clickedDot !== prevProps.clickedDot) {
      let participantsInProject = await getProjectParticipantsInformation(
        this.props.clickedDot
      );
      this.setState({
        projectParticipantsInfo: participantsInProject,
      });
    }
  }

  setFilter = ({ organizationId = null }) => {
    this.props.openNodeInfo(organizationId);
    this.props.setSelectedTab("network");
  };

  render() {
    const { projectParticipantsInfo } = this.state;
    const { tools, language } = this.props;

    const isToolsActive = tools["active"] !== "" ? true : false;

    const currentProject = this.props.getProjectInformation(
      this.props.clickedDot
    );

    return (
      <Fragment>
        <div
          className="informative-panel"
          style={{
            right: isToolsActive
              ? CONFIG.APP.LAYOUT.PANEL_WIDTH +
                CONFIG.APP.LAYOUT.BUTTON_SIZE +
                CONFIG.APP.LAYOUT.SPACER * 2 +
                "px"
              : CONFIG.APP.LAYOUT.BUTTON_SIZE +
                CONFIG.APP.LAYOUT.SPACER * 2 +
                "px",
          }}
        >
          <div className="informative-panel-body container">
            <button
              className="btn btn-close-window"
              onClick={this.props.closeDotInfo}
            >
              <FaTimesCircle></FaTimesCircle>
            </button>
            <div className="row">
              <div className="col">
                <h6 className="indicator-label pt-0">
                  {getTranslation({
                    lang: language,
                    key: "INFO_WINDOWS.PROJECT.TITLE",
                  })}
                </h6>
                <h3>{currentProject[0].title}</h3>
                <h6 className="indicator-label">
                  {getTranslation({
                    lang: language,
                    key: "INFO_WINDOWS.PROJECT.PROGRAM",
                  })}
                </h6>
                <h4>{currentProject[0]["programaName"]}</h4>
              </div>
            </div>
            <div className="row">
              <div className="col col-6">
                <div className="indicator">
                  <h6 className="indicator-label">
                    {getTranslation({
                      lang: language,
                      key: "INFO_WINDOWS.PROJECT.YEAR",
                    })}
                  </h6>
                  <h5>{currentProject[0]["year"]}</h5>
                </div>
              </div>
              <div className="col col-6">
                <div className="indicator text-end">
                  <h6 className="indicator-label">
                    {getTranslation({
                      lang: language,
                      key: "INFO_WINDOWS.PROJECT.TOTAL_FUNDING",
                    })}
                  </h6>
                  <h5>{FORMAT_EURO(currentProject[0].funding)}</h5>
                </div>
              </div>
            </div>
            <div className="row panel-internal-scrolling-container">
              <div className="col">
                <h6 className="indicator-label">
                  {getTranslation({
                    lang: language,
                    key: "INFO_WINDOWS.PROJECT.PARTICIPANTS",
                  })}
                </h6>
                <div className="panel-internal-scrolling">
                  <ul>
                    {_.map(projectParticipantsInfo, (el, i) => {
                      return (
                        <li
                          key={`participant-${i}`}
                          className="link"
                          onClick={() =>
                            this.setFilter({
                              organizationId: el["organizationId"],
                            })
                          }
                        >
                          {el["organizationName"]}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <footer className="informative-panel-footer">
            {/* <button className="btn btn-link" onClick={this.props.closeDotInfo}>
              Tanca
            </button> */}
            <button
              className="btn btn-primary ms-auto"
              onClick={() =>
                this.props.setClickedProject({ id: currentProject[0]["id"] })
              }
            >
              {getTranslation({
                lang: language,
                key: "INFO_WINDOWS.PROJECT.BUTTON_PROJECT",
              })}
            </button>
          </footer>
        </div>
      </Fragment>
    );
  }
}

export default TopicPanelInformation;
