import * as d3 from "d3";
// TOOLTIP
export const showTooltip = function ({
  datum = null,
  cluster = null,
  mousePosition = null
}) {
  createTooltip({
    content: datum,
    cluster: cluster,
    clusterName: cluster[0]["label"],
    clusterColor: cluster[0]["label"],
    mousePosition: mousePosition
  });
};

export const hideTooltip = function () {
  d3.selectAll(".siris-tooltip").remove();
  // event.target.removeChild(event.target.message);
  // delete event.target.message;
};

export const createTooltip = function ({
  content = null,
  // cluster = null,
  clusterName = null,
  clusterColor = null,
  mousePosition = null
}) {
  // console.log("content", content);
  // console.log("cluster", cluster);
  // console.log("clusterName", clusterName);
  // console.log("mousePosition", mousePosition);

  d3.selectAll(".siris-tooltip").remove();

  const contentType = typeof content;

  // console.log("Content", content)

  let htmlContent;

  if (contentType === "string") {
    // const keywords = content.split(" ");
    htmlContent = `
            <strong class='d-block'>Temàtica</strong>
            <p class="m-0" style="color: ${clusterColor}">${clusterName}</p>
            `;
  } else {
    // const activities = content.activityType.split(";")
    // const keywords = content.keywords.split(";");
    htmlContent = `
            <p class="m-0">${content.title}</p>
        `;
  }

  d3.select("#canvasContainer")
    .append("div")
    .attr("class", "siris-tooltip")
    .style("left", (mousePosition[0][0] + 8) + "px")
    .style("top", (mousePosition[0][1] + 8) + "px")
    .html(htmlContent);
};
