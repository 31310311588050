import * as d3 from "d3";
import React, { useEffect } from "react";
import d3Cloud from "d3-cloud";

// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/word-cloud
// text // Array of words to be displayed - they are repeated
//     size = group => group.length, // Given a grouping of words, returns the size factor for that word
//     word = d => d, // Given an item of the data array, returns the word
//     marginTop = 0, // top margin, in pixels
//     marginRight = 0, // right margin, in pixels
//     marginBottom = 0, // bottom margin, in pixels
//     marginLeft = 0, // left margin, in pixels
//     width = 640, // outer width, in pixels
//     height = 400, // outer height, in pixels
//     maxWords = 250, // maximum number of words to extract from the text
//     fontFamily = "sans-serif", // font family
//     fontScale = 15, // base font size
//     padding = 0, // amount of padding between the words (in pixels)
//     rotate = 0, // a constant or function to rotate the words
//     invalidation // when this promise resolves, stop the simulation

export function WordCloud(props) {
  const size = (group) => group.length;
  const maxWords = 45;
  const width = props["worcloudContainerMeasures"]["width"],
    height = props["worcloudContainerMeasures"]["height"],
    marginTop = 0,
    marginRight = 0,
    marginBottom = 0,
    marginLeft = 0,
    padding = 5,
    rotate = 0,
    fontFamily = "Open Sans";
    // fontScale = 9;

  let invalidation;
  const words = props.text;
  const word = (d) => d;
  const data = d3
    .rollups(words, size, (w) => w)
    .sort(([, a], [, b]) => d3.descending(a, b))
    .slice(0, maxWords)
    .map(([key, size]) => ({ text: word(key), size }));

  // Extent of sizes of words
  const extent = d3.extent(data, (d) => d.size);
  // Scale for font size
  const fontSizeScale = d3.scaleSqrt().domain(extent).range([11, 55]);
  // Scale for font color
  let colorScale = props.filtered
    ? d3.scaleOrdinal().range(["#C33", "#C99"])
    : d3.scaleOrdinal().range(["#333", "#999"]);

  const svgRef = React.useRef(null);
  useEffect(() => {

    const svgEl = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height]);

    svgEl.selectAll("*").remove(); // Clear svg content before adding new elements

    const g = svgEl
      .append("g")
      .attr("transform", `translate(${marginLeft},${marginTop})`);

    const cloud = d3Cloud()
      .size([
        width - marginLeft - marginRight,
        height - marginTop - marginBottom,
      ])
      .words(data)
      .padding(padding)
      .rotate(rotate)
      .font(fontFamily)
      .fontSize((d) => fontSizeScale(d.size))
      .on("word", ({ size, x, y, rotate, text }) => {
        g.append("text")
          .attr("font-size", size)
          .attr("transform", `translate(${x},${y}) rotate(${rotate})`)
          .attr("text-anchor", "middle")
          .style("fill", colorScale(text))
          .style("font-weight", 200)
          .text(text);
      });

    cloud.start();
    invalidation && invalidation.then(() => cloud.stop());
  });

  return (
      <svg ref={svgRef} width={width} height={height} />
  );
}
