import React from "react";
import { useTable } from "react-table";

import "./index.scss";

export function AmbitsTable(props) {
  const data = props["tableData"]["rowsData"];
  const columns = props["tableData"]["headersData"];

  const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const handleHover = (row) => {
    if (row === undefined) {
      return false;
    } else {
      props.handleOverOnRow(row);
    }
  };

  return (
    <table {...getTableProps()} className="table-ambits" onMouseLeave={() => handleHover(null)}>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          // console.log("ROW", row);
          const rowId = row["original"]["id"];
          prepareRow(row);
          return (
            <tr
              onMouseEnter={() => handleHover(rowId)}
              {...row.getRowProps()}
            >
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
