import _ from "lodash";
import * as d3 from "d3";
import { arraymove } from "./data";
import { CONFIG } from "../../../common/constants";
const MAX_EDGE_WEIGHT = 10;
// PRIVATE FUNCTIONS

export const ticked = function ({
  transform = null,
  min = 1,
  textWidth = 0,
  context = null,
  width = 0,
  height = 0,
  scales = null,
  sortCriteria = null,
  graphJSON = null,
}) {
  context.save();
  context.clearRect(0, 0, width, height);
  context.translate(transform.x, transform.y);
  context.scale(transform.k, transform.k);

  // --- links
  graphJSON.links.forEach(function (d) {
    context.beginPath();
    var color = d3.color(scales.edgeColorScale(d.values.weight));
    color.opacity = d.values.weight < min ? 0 : d.values.weight / 10;
    context.strokeStyle = d.__highlighted
      ? CONFIG.APP.NETWORK_COLORS.LINK_SELECTED
      : color.toString();
    context.lineWidth = _.clamp(d.values.weight, 1, MAX_EDGE_WEIGHT);
    context.moveTo(d.source.x, d.source.y);
    context.lineTo(d.target.x, d.target.y);
    context.stroke();
  });

  const lastPosition = graphJSON.nodes.length;
  let rollovedPosition = 0;

  // --- nodes
  graphJSON.nodes.forEach(function (d, i) {
    if (!d) {
      return false;
    }

    if (d.__rolloved) {
      rollovedPosition = i;
    }

    context.beginPath();
    context.moveTo(d.x, d.y);
    context.lineWidth = Math.floor(
      (d.__highlighted || d.__brushed ? 4 : 2) * (1 / transform.k)
    );
    context.arc(
      d.x,
      d.y,
      scales.sizeScale(d.values[CONFIG.APP.CONFIG.size.field]),
      0,
      2 * Math.PI
    );

    // Fill the node with the appropriate colour
    if (sortCriteria.field !== "group") {
      let nodeColour = _.find(sortCriteria.options, function (o) {
        // console.log("----- NODE_COLOUR");
        // console.log("D VALUES", d.values);
        // console.log("O", o);
        // console.log("sortCriteria.field", sortCriteria.field);

        switch (sortCriteria["field"]) {
          
          case "participantNuts3Name":
            return o.label === d.values[sortCriteria.field][0];
          // break;

          // case "nodeFramework":
          //   return o.label === d.values[sortCriteria.field][0];
          // break;

          default:
            return o.value === d.values[sortCriteria.field][0];
          // break;
        }
      }).color;

      // Need create a fake option when there is more than one framework
      if (sortCriteria["field"] === "nodeFramework") {
        if (d.values[sortCriteria.field].length > 1) {
          nodeColour = "#6F418F";
        }
      }

      // Assing color by finding the value in the options array
      // TODO: This is a hack, we should be able to use the colorMap function
      // Also, this is not working for participantNuts3Name so need to add a condition
      context.fillStyle = nodeColour;

      // context.fillStyle = sortCriteria.colorMap(
      //   d.values[sortCriteria.field][0]
      // );
    } else {
      // when colouring by auto-detected communities,
      // fill to white those nodes with no community
      context.fillStyle =
        d.values.group === CONFIG.APP.ID_NO_GROUP
          ? "#fff"
          : sortCriteria.colorMap(d.values[sortCriteria.field]);
    }

    // stroke appears with aliasing in zoom in / zoom out:
    // although we cap aply a correction factor over the zoom (we already
    // do this when defining context.lineWidth), we only draw the stroke
    // in specific situation, to try to avoid an ugly general aesthetic on the net:
    if (
      sortCriteria.field === "group" &&
      d.values.group === CONFIG.APP.ID_NO_GROUP
    ) {
      context.strokeStyle = "#aaa";
      context.stroke();
    }
    if ((d.__highlighted || d.__brushed) && textWidth !== -1) {
      context.strokeStyle = "#888";
      context.stroke();
    }
    context.fill();
  });

  // Move the rolloved object to the last position of data array
  // this way we paint it at the top of all elements
  arraymove(graphJSON.nodes, rollovedPosition, lastPosition);
  // --- node labels, if node is highlighted, show node label

  graphJSON.nodes.forEach(function (d, i) {
    if (!d) {
      return false;
    }

    const fontSize = scales.sizeNodeLabelScale(
      d.values[CONFIG.APP.CONFIG.size.field]
    );
    const scaledFontSize = fontSize / transform.k;

    // d.__rolloved ? document.body.style.cursor = "pointer" : document.body.style.cursor = "default"
    if (d.__clicked) {
      // The box behind
      context.fillStyle = d.__searched ? "#C00" : "rgba(0,0,0,.8)";
      context.fillRect(
        d.x + d.radius,
        d.y - scaledFontSize * 1.8,
        textWidth * (scaledFontSize * 0.11) + 10,
        scaledFontSize * 2
      );
    }
    if (d.__searched) {
      // context.fillStyle = d.__searched && "#C00";
      // context.fillStyle = "rgba(0,0,0,.8)";
      // context.fillRect(
      //   d.x + margin,
      //   d.y - scaledFontSize * 1.8,
      //   200 * (scaledFontSize * 0.11) + margin * 1.8,
      //   scaledFontSize * 2
      // );
      context.font = "bold " + scaledFontSize + "px Open Sans";
      context.fillStyle = d.__clicked ? "transparent" : "#C00";
      // context.strokeStyle = "transparent";

      context.fillText(d.label, d.x + d.radius, d.y - scaledFontSize * 0.5);
    }
    if (d.__highlighted && textWidth !== -1) {
      context.font =
        (d.__rolloved || d.__clicked ? "bold " : "") +
        scaledFontSize +
        "px Open Sans";
      context.shadowColor = d.__searched
        ? "transparent"
        : "rgba(255,255,255,.9)";
      context.shadowOffsetX = 0;
      context.shadowOffsetY = 0;
      context.lineWidth = 3 / transform.k;
      if (!d.__clicked) {
        context.shadowBlur = 2;
        context.strokeStyle = d.__searched
          ? "transparent"
          : "rgba(250,250,250,.9)";
        context.strokeText(
          d.label,
          d.__clicked ? d.x + d.radius + 5 : d.x + d.radius,
          d.y - scaledFontSize * 0.5
        );
      }
      context.fillStyle = d.__clicked
        ? d.__searched
          ? "#FFF"
          : "#FFF"
        : d.__searched
        ? "transparent"
        : "rgba(0,0,0,.9)";
      context.fillText(
        d.label,
        d.__clicked ? d.x + d.radius + 5 : d.x + d.radius,
        d.y - scaledFontSize * 0.5
      );
    }
  });

  context.restore();
};
