import React, { Component, Fragment } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import * as d3 from "d3";
import "./index.scss";

import { getTranslation } from "../../common/translations";

class LayerVisibility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      labels: props.visibilityLayers.labels,
      projects: props.visibilityLayers.nodes,
      clusters: props.visibilityLayers.densities,
    };
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  handleLayerVisibility = (event) => {
    const layer = event.target.id;

    switch (layer) {
      case "visibilityLabels":
        if (this.state.labels === true) {
          this.setState({
            labels: false,
          });
          d3.select(".label-rects-container").style("visibility", "hidden");
          d3.selectAll(".label-back-rect").style("pointer-events", "none");
          d3.selectAll(".label").style("pointer-events", "none");
        } else {
          this.setState({
            labels: true,
          });
          d3.select(".label-rects-container").style("visibility", "visible");
          d3.selectAll(".label-back-rect").style("pointer-events", "all");
          d3.selectAll(".label").style("pointer-events", "all");
        }
        this.props.setVisibilityLayers(
          "labels",
          this.state.labels ? false : true
        );
        break;
      case "visibilityProjects":
        // console.log("THIS.PROPS", this.props);

        if (this.state.projects === true) {
          this.setState({
            projects: false,
          });
          d3.select(".nodes-container").style("visibility", "hidden");
          d3.selectAll(".projectNode").style("pointer-events", "none");
        } else {
          this.setState({
            projects: true,
          });
          d3.select(".nodes-container").style("visibility", "visible");
          d3.selectAll(".projectNode").style("pointer-events", "all");
        }
        this.props.setVisibilityLayers(
          "nodes",
          this.state.projects ? false : true
        );
        break;
      case "visibilityClusters":
        if (this.state.clusters === true) {
          this.setState({
            clusters: false,
          });
          d3.select(".clusters-container").style("visibility", "hidden");
        } else {
          this.setState({
            clusters: true,
          });
          d3.select(".clusters-container").style("visibility", "visible");
        }
        this.props.setVisibilityLayers(
          "densities",
          this.state.clusters ? false : true
        );
        break;
      default:
        break;
    }
  };

  render() {
    const { loading, labels, projects, clusters } = this.state;
    const { lang } = this.props;
    return (
      <Fragment>
        {loading ? (
          <p>Loading</p>
        ) : (
          <ButtonGroup
            className="visibility-container bg-white"
            aria-label="Layers visibility"
          >
            <Button
              id="visibilityLabels"
              variant="link"
              active={labels}
              onClick={this.handleLayerVisibility}
            >
              {labels ? (
                <small className="fake-radio active"></small>
              ) : (
                <small className="fake-radio"></small>
              )}{" "}
              {getTranslation({
                lang: lang,
                key: "VISIBILITY_PANEL.OPTIONS.LABELS",
              })}
            </Button>
            <Button
              id="visibilityProjects"
              variant="link"
              active={projects}
              onClick={this.handleLayerVisibility}
            >
              {projects ? (
                <small className="fake-radio active"></small>
              ) : (
                <small className="fake-radio"></small>
              )}{" "}
              {getTranslation({
                lang: lang,
                key: "VISIBILITY_PANEL.OPTIONS.PROJECTS",
              })}
            </Button>
            <Button
              id="visibilityClusters"
              variant="link"
              active={clusters}
              onClick={this.handleLayerVisibility}
            >
              {clusters ? (
                <small className="fake-radio active"></small>
              ) : (
                <small className="fake-radio"></small>
              )}{" "}
              {getTranslation({
                lang: lang,
                key: "VISIBILITY_PANEL.OPTIONS.DENSITIES",
              })}
            </Button>
          </ButtonGroup>
        )}
      </Fragment>
    );
  }
}

export default LayerVisibility;
