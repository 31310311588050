import React, { Component, Fragment } from "react";
// import Select from "react-select";
import WindowedSelect from "react-windowed-select";
import "./index.scss";
import _ from "lodash";

class SearchDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      internalOptions: [],
    };
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.filters["searchedParticipantIds"] !==
      prevProps.filters["searchedParticipantIds"]
    ) {
      if (this.props.filters["searchedParticipantIds"].length === 0) {
        // console.log(">>>> SEARCH_PARTICIPANTS -> UPDATING");
        this.handleSelected([],{})
        if (this.props.selectedFromComponent.length > 0) {
          this.setState({
            internalOptions: this.props.selectedFromComponent,
          });
        } else {
          this.setState({
            internalOptions: [],
          });
        }
      }
    } else {
      // console.log(">>>> SEARCH_PARTICIPANTS -> DO NOTHING");
      return false;
    }
  }

  handleSelected = (options, { action, removedValue }) => {
    // console.log("handleSelected");
    // console.log("options", options);
    // console.log("action", action);
    // console.log("removedValue", removedValue);

    // if removedValue then
    // 1. go directly to Provider
    // 2. Clean options []
    // 3. disable search button
    if (removedValue !== undefined || options.length === 0) {

      const data = {};
      data.filter = options;
      data.type = "participants";

      this.props.setSearch({
        participants : data,
      });
    }

    this.props.storeValues({
      internalOptions: options,
      type: "participants",
    });

    this.setState({
      internalOptions: options,
    });
  };

  render() {
    const { options, placeholder, filters } = this.props;
    const { loading } = this.state;

    const selectedOptions = _.filter(options, (option) =>
      filters.searchedParticipantIds.includes(option["organizationId"])
    );

    return (
      <Fragment>
        {loading ? (
          <p>loading...</p>
        ) : (
          <div className="search-component-container">
            <WindowedSelect
              options={options}
              isSearchable={true}
              onChange={this.handleSelected}
              defaultValue={selectedOptions}
              openMenuOnClick={false}
              isMulti
              isClearable={true}
              placeholder={<div className="text-truncate">{placeholder}</div>}
              className={
                this.props.dynamicClassName ? this.props.dynamicClassName : ""
              }
              classNamePrefix={
                this.props.dynamicPrefix ? this.props.dynamicPrefix : ""
              }
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#F2F2F2",
                  primary: "#DDD",
                },
              })}
            />
            {/* <button
              className="btn btn-primary"
              onClick={this.sendValueToProvider}
              disabled={internalOptions.length === 0 ? true : false}
            >
              <FaSearch />
            </button> */}
          </div>
        )}
      </Fragment>
    );
  }
}

export default SearchDropdown;
