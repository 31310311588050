import React, { Component, Fragment } from "react";
import "./index.scss";

import { FORMAT_EURO, FORMAT_THOUSANDS } from "../../common/constants";
import { getTranslation } from "../../common/translations";

class FiltersSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    // Call any function in provider
    this.setState({
      loading: false,
    });
  }

  render() {
    const { summary, language } = this.props;
    const { loading } = this.state;

    return (
      <Fragment>
        {loading ? (
          <div className="filters-summary-loading"></div>
        ) : (
          <div className="container-fluid p-0">
            <div className="filters-summary row m-0">
              {/* <div className="col text-center indicator-periode">
                <p className="text-nowrap me-2">
                  <small>periode</small>
                </p>
                <h2>
                  {summary.years[0] !== undefined && summary.years.length > 0
                    ? summary.years[0] + (summary.years[0] === summary.years[summary.years.length - 1]
                      ? ""
                      : "-" + summary.years[summary.years.length - 1].toString().slice(-2))
                    : 0}
                </h2>
              </div> */}

              <div className="col text-center">
                <h2>{FORMAT_THOUSANDS(summary.projects)}</h2>
                <p className="text-nowrap ms-2">
                  <small>
                    {summary.projects === 1
                      ? getTranslation({
                          lang: language,
                          key: "SUMMARY.PROJECT",
                        })
                      : getTranslation({
                          lang: language,
                          key: "SUMMARY.PROJECTS",
                        })}
                  </small>
                </p>
              </div>
              <div className="col col-auto text-center">
                <h2>{FORMAT_EURO(summary.funding)}</h2>
                <p className="text-nowrap ms-2">
                  <small>
                    {getTranslation({
                      lang: language,
                      key: "SUMMARY.FUNDING",
                    })}
                  </small>
                </p>
              </div>
              <div className="col text-center">
                <h2>{FORMAT_THOUSANDS(summary.participants)}</h2>
                <p className="text-nowrap ms-2">
                  <small>
                    {summary.participants === 1
                      ? getTranslation({
                          lang: language,
                          key: "SUMMARY.ENTITY",
                        })
                      : getTranslation({
                          lang: language,
                          key: "SUMMARY.ENTITIES",
                        })}
                  </small>
                </p>
              </div>
              <div className="col text-center">
                <h2>{FORMAT_THOUSANDS(summary.partners)}</h2>
                <p className="text-nowrap ms-2">
                  <small>
                    {getTranslation({
                      lang: language,
                      key: "SUMMARY.EXTERNAL_PARTNERS",
                    })}
                  </small>
                </p>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default FiltersSummary;
