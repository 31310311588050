import React, { Component, Fragment } from "react";
import _ from "lodash";
import * as d3 from "d3";
import { Accordion } from "react-bootstrap";
import FilterDropdown from "../../components/FilterDropdown";
import FilterSlider from "../../components/FilterSlider";
import FilterCheckboxes from "../../components/FilterCheckboxes";
import HierarchyFilterCheckboxes from "../../components/HierarchyFilterCheckboxes";
import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";
import "./index.scss";

import { CONFIG } from "../../common/constants";
import { getTranslation } from "../../common/translations";

class FiltersPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      selectedFilters: [],
    };
  }

  componentDidMount() {
    this.setState({
      // Load active filters from Provider
      selectedFilters: this.props.filters.selected,
      loading: false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.filters.selected !== this.props.filters.selected) {
      this.setState({
        selectedFilters: this.props.filters.selected,
      });
    }
  }

  getFilterComponent = (filter) => {
    switch (filter.TYPE) {
      case "dropdown":
        return (
          <FilterDropdown
            entity={filter.ENTITY}
            placeholder={filter.PLACEHOLDER}
            options={this.props.filters.populate[filter.ENTITY].options}
            {...this.props}
          ></FilterDropdown>
        );
      case "filterSlide":
        return (
          <div className="filter-container filter-container-slider">
            <FilterSlider
              entity={filter.ENTITY}
              min={d3.min(this.props.data.topics.data, (d) => d.year)}
              max={d3.max(this.props.data.topics.data, (d) => d.year)}
              storeSliderFilter={this.storeSliderFilter}
              {...this.props}
            ></FilterSlider>
          </div>
        );
      case "checkboxes":
        return (
          <div className="filter-container">
            <FilterCheckboxes
              entity={filter.ENTITY}
              textAll={getTranslation({
                lang: this.props.lang,
                key:
                  "FILTER_PANEL.FILTERS." + filter["TRANSLATION_KEY"] + ".ALL",
              })}
              columns={filter.COLUMNS}
              options={this.props.filters.populate[filter.ENTITY].options}
              storeCheckboxesFilter={this.storeCheckboxesFilter}
              selectedFilters={this.state["selectedFilters"]}
              {...this.props}
            ></FilterCheckboxes>
          </div>
        );
      case "hierarchy":
        return (
          <div className="filter-container">
            <HierarchyFilterCheckboxes
              entity={filter.ENTITY}
              placeholder={filter.PLACEHOLDER}
              options={this.props.filters.populate[filter.ENTITY].options}
              storeHierarchyFilter={this.storeHierarchyFilter}
              selectedFilters={this.state["selectedFilters"]}
              {...this.props}
            ></HierarchyFilterCheckboxes>
          </div>
        );
      default:
        break;
    }
  };

  // Store filters for sending to provider
  // we set filters and mark selected ones
  storeCheckboxesFilter = (
    filter // Array
  ) => {
    let currentFilters = [...this.state["selectedFilters"]];
    // To know if is a sigle value or all
    // let check the filter array length
    // 1. Single Values
    if (filter["values"].length === 1) {
      // 1. Check if value is selected
      // 1.1 Entity is present

      // Manage expection to select Centres CERCA if activityType = "REC"
      if (
        filter["type"] === "activityType" &&
        filter["values"].includes("REC")
      ) {
        filter["values"].push("CERCA");
      }

      const foundType = _.find(currentFilters, (stateFilter) => {
        return stateFilter["type"] === filter["type"];
      });
      // 1.2 value is present
      if (foundType) {
        if (foundType["values"].includes(filter.values[0])) {
          foundType["values"] = _.filter(foundType["values"], (foundValue) => {
            return foundValue !== filter.values[0];
          });
        } else {
          foundType["values"].push(filter.values[0]);
        }
        // Manage expection to select Centres CERCA if activityType = "REC"
        if (
          foundType["type"] === "activityType" &&
          foundType["values"].includes("REC")
        ) {
          if (!foundType["values"].includes("CERCA")) {
            foundType["values"].push("CERCA");
          }
        }

        // 2. modify source filters
        currentFilters = _.filter(currentFilters, (stateFilter) => {
          return stateFilter["type"] !== foundType["type"];
        });

        currentFilters.push(foundType);
      } else {
        currentFilters.push({
          type: filter["type"],
          values: filter.values,
        });
      }
    }
    // 2. Multiple values so coming from 'All'
    else {
      // Check if filter type is present in selected filters
      let filterFound = _.find(
        currentFilters,
        (currentFilter) => currentFilter["type"] === filter["type"]
      );
      if (filterFound) {
        // remove current type from filters
        currentFilters = _.filter(
          currentFilters,
          (currentFilter) => currentFilter["type"] !== filter["type"]
        );

        // Check if they are already all selected (by length)
        filterFound["values"].length === filter["values"].length &&
          (filter["values"] = []);

        // add all values selected in currentFilters
        currentFilters.push(filter);
      } else {
        currentFilters.push(filter);
      }
    }

    this.setState({
      selectedFilters: currentFilters,
    });
  };

  storeSliderFilter = (
    filter // Array
  ) => {
    let currentFilters = [...this.state["selectedFilters"]];
    // if found remove for replace after
    let filterFound = _.find(
      currentFilters,
      (currentFilter) => currentFilter["type"] === filter["type"]
    );
    // remove current type from filters
    if (filterFound) {
      currentFilters = _.filter(
        currentFilters,
        (currentFilter) => currentFilter["type"] !== filter["type"]
      );
      currentFilters.push(filter);
    } else {
      currentFilters.push(filter);
    }
    this.setState({
      selectedFilters: currentFilters,
    });
  };

  storeHierarchyFilter = (
    filter // Object
  ) => {
    this.storeSliderFilter(filter);
    return true;
  };

  sendFilterToProvider = (filters) => {
    this.props.setFilters(filters);
    this.props.setActiveTool("");
  };

  resetCheckboxes = () => {
    // Empty state to set all checkboxes to empty
    this.setState({
      selectedFilters: [],
    });
    // TODO reset also the "all" option
    // Reset filters in provider
    this.props.resetAllFilters();
  };

  render() {
    const { selectedFilters } = this.state;
    const { lang } = this.props;

    // Check if there are filters selected
    // If not disable button
    const currentActiveFilters = _.map(selectedFilters, (filter) => {
      return filter["values"];
    }).flat();

    return (
      <Fragment>
        <div className="panel filters-panel">
          <header>
            <h6>Filtres</h6>
            <button
              className="btn btn-secondary ms-auto"
              onClick={() => this.props.setActiveTool("")}
            >
              {getTranslation({
                lang: lang,
                key: "FILTER_PANEL.BUTTON_CANCEL",
              })}
            </button>
            <button
              className="btn btn-primary ms-3"
              onClick={() => this.sendFilterToProvider(selectedFilters)}
              disabled={currentActiveFilters.length === 0 ? true : false}
            >
              {getTranslation({
                lang: lang,
                key: "FILTER_PANEL.BUTTON_APPLY",
              })}
            </button>
            {/* <button
              className="btn btn-link ms-auto"
              onClick={() => this.resetCheckboxes()}
              disabled={selectedFilters.length === 0 ? true : false}
            >
              Suprimeix els filtres
            </button> */}
          </header>
          {/* Iterate on dropdown filters */}
          <div className="filters-panel-scrolling">
            <Accordion defaultActiveKey="0">
              {_.map(CONFIG.FILTERS, (filter, index) => {
                const filterFound = _.find(
                  selectedFilters,
                  (selectedFilter) =>
                    selectedFilter["type"] === filter["ENTITY"]
                );
                return (
                  <Accordion.Item eventKey={index} key={filter.ENTITY}>
                    <Accordion.Header>
                      <FaChevronCircleDown className="close"></FaChevronCircleDown>
                      <FaChevronCircleUp className="open"></FaChevronCircleUp>
                      {/* <span className="btn-label">{filter.TITLE}</span> */}
                      <span className="btn-label">
                        {
                          getTranslation({
                            lang: lang,
                            key: "FILTER_PANEL.FILTERS." + filter["TRANSLATION_KEY"] + ".TITLE",
                          })
                        }
                      </span>
                      {filterFound && filterFound["values"].length > 0 ? (
                        <span className="badge rounded-pill bg-primary text-white ms-auto">
                          {filterFound["values"].length}
                        </span>
                      ) : (
                        ""
                      )}
                    </Accordion.Header>
                    <Accordion.Body>
                      {this.getFilterComponent(filter)}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default FiltersPanel;
