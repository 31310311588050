import { 
  SPARQL_ENDPOINT, 
  // STATIC_DATA_FOLDER 
} from "../common/constants";

// import d3
import * as d3 from "d3";

import { queries } from "../common/queries/index";

import query from "../common/sparql/index";

// import { projectCoords } from "./static/projectCoords";
// import { projectTopics } from "./static/projectTopics";
// import { projectsInformation } from "./static/projectsInformation";
// import { projectsAmbits } from "./static/projectsAmbits";
// import { projectsKeywords } from "./static/projectsKeywords";
// import { projectsPartners } from "./static/projectsPartners";
// import { projectsSDGS } from "./static/projectsSDGS";

// From SPARQL endpoint

export async function getProjectsInformation() {
  // Loading data from local file public/satic-data folder
  // const result = d3.json(
  //   STATIC_DATA_FOLDER + "/projectsInformation.json",
  //   function (error, data) {
  //     if (error) {
  //       return console.warn(error);
  //     }
  //     return data;
  //   }
  // );

  // Loading from SPARQL endpoint
  const result = await query(SPARQL_ENDPOINT, queries.projectsInformation());

  // console.log("getProjectsInformation");
  // console.log(JSON.stringify(result));

  // Loading from static data only for development
  // const result = projectsInformation;

  return result;
}

export async function getProjectsAmbits() {
  // Loading data from local file public/satic-data folder
  // const result = d3.json(
  //   STATIC_DATA_FOLDER + "/projectsAmbits.json",
  //   function (error, data) {
  //     if (error) {
  //       return console.warn(error);
  //     }
  //     return data;
  //   }
  // );
  // Loading from SPARQL endpoint
  const result = await query(SPARQL_ENDPOINT, queries.projectsAmbits());

  // console.log("getProjectsAmbits");
  // console.log(JSON.stringify(result));
  // Loading from static data only for development
  // const result = projectsAmbits;

  return result;
}

export async function getProjectsKeywords() {
  // Loading data from local file public/satic-data folder
  // const result = d3.json(
  //   STATIC_DATA_FOLDER + "/projectsKeywords.json",
  //   function (error, data) {
  //     if (error) {
  //       return console.warn(error);
  //     }
  //     return data;
  //   }
  // );
  // Loading from SPARQL endpoint
  const result = await query(SPARQL_ENDPOINT, queries.projectsKeywords());

  // console.log("getProjectsKeywords");
  // console.log(JSON.stringify(result));
  // Loading from static data only for development
  //   const result = projectsKeywords;

  return result;
}

// Get partners & participants

export async function getProjectsPartners() {
  // Loading data from local file public/satic-data folder
  // const result = d3.json(
  //   STATIC_DATA_FOLDER + "/projectsPartners.json",
  //   function (error, data) {
  //     if (error) {
  //       return console.warn(error);
  //     }
  //     return data;
  //   }
  // );
  // Loading from SPARQL endpoint
  const result = await query(SPARQL_ENDPOINT, queries.projectsPartners());

  // console.log("getProjectsPartners");
  // console.log(JSON.stringify(result));
  // Loading from static data only for development
  // const result = projectsPartners;

  return result;
}

export async function getProjectsSDGs() {
  // Loading data from local file public/satic-data folder
  // const result = d3.json(
  //   STATIC_DATA_FOLDER + "/projectsSDGS.json",
  //   function (error, data) {
  //     if (error) {
  //       return console.warn(error);
  //     }
  //     return data;
  //   }
  // );
  // Loading from SPARQL endpoint
  const result = await query(SPARQL_ENDPOINT, queries.projectsSDGs());

  // console.log("getProjectsSDGs");
  // console.log(JSON.stringify(result));
  // Loading from static data only for development
  // const result = projectsSDGS;

  return result;
}

// DYNAMIC QUERIES

export async function getProjectParticipantsInformation(projectId) {
  const result = await query(
    SPARQL_ENDPOINT,
    queries.projectParticipantsInformation(projectId)
  );

  return result;
}

// For the main download
export async function getMainDataDownload(array) {
  const result = await query(SPARQL_ENDPOINT, queries.mainDataDownload(array));

  return result;
}

// From local sources

export async function getProjectCoords() {
  // Loading data from local file public/satic-data folder
  // const result = d3.json(
  //   STATIC_DATA_FOLDER + "/projectCoords.json",
  //   function (error, data) {
  //     if (error) {
  //       return console.warn(error);
  //     }
  //     return data;
  //   }
  // );
  // Loading from SPARQL endpoint
  const result = await query(SPARQL_ENDPOINT, queries.projectCoords());

  // console.log("getProjectCoords");
  // console.log(JSON.stringify(result));
  // Loading from static data only for development
  // const result = projectCoords;
  return result;
}

export async function getTopics() {
  // Loading data from local file public/satic-data folder
  // const result = d3.json(
  //   STATIC_DATA_FOLDER + "/projectTopics.json",
  //   function (error, data) {
  //     if (error) {
  //       return console.warn(error);
  //     }
  //     return data;
  //   }
  // );
  // Loading from SPARQL endpoint
  const result = await query(SPARQL_ENDPOINT, queries.topicsCoords());

  // console.log("getTopics");
  // console.log(JSON.stringify(result));
  // Loading from static data only for development
  // const result = projectTopics;

  return result;
}

export async function getTopicsTranslations() {
  // Loading data from local file public/satic-data folder
  const result = d3.json(
    "data-ris3cat/topics_01_2024.json",
    function (error, data) {
      if (error) {
        return console.warn(error);
      }

      return data;
    }
  );


  return result;
}
