import _ from "lodash";

export const searchParticipantsKeywords = ({
  networkData = [],
  keywordsIDS = [],
  operator = "AND",
}) => {
  if (keywordsIDS.length > 0) {
    let byKeywords = [];
    let byText = [];
    if (operator === "AND") {
      // Look in participant keywords
      byKeywords = _.filter(networkData, (obj) => {
        return keywordsIDS.every((kwd) => obj["nodeKeywords"].includes(kwd));
      });
      // Look in the title and abstract
      byText = _.filter(networkData, (obj) => {
        // console.log("SEARCHING", obj["projectTitle"], obj["projectAbstract"]);
        return (
          obj["projectTitle"] !== undefined &&
          obj["projectAbstract"] !== undefined &&
          keywordsIDS.every(
            (kwd) =>
              obj["projectTitle"].search(kwd) !== -1 ||
              obj["projectAbstract"].search(kwd) !== -1
          )
        );
      });
    } else {
      // Look in participant keywords
      byKeywords = _.filter(networkData, (obj) => {
        return keywordsIDS.some((kwd) => obj["nodeKeywords"].includes(kwd));
      });
      // Look in the title and abstract
      byText = _.filter(networkData, (obj) => {
        return (
          obj["projectTitle"] !== undefined &&
          obj["projectAbstract"] !== undefined &&
          keywordsIDS.some(
            (kwd) =>
              obj["projectTitle"].search(kwd) !== -1 ||
              obj["projectAbstract"].search(kwd) !== -1
          )
        );
      });
    }
    networkData = _.uniq(byKeywords.concat(byText));
  }

  // console.log("NETWORK DATA", networkData);

  return networkData;
};

export const searchProjectsKeywords = ({
  projects = [],
  keywordsIDS = [],
  operator = "AND",
}) => {

  // console.log("SEARCH_PROJECTS_KEYWORDS");
  // console.log("PROJECTS", projects);
  // console.log("KEYWORDSIDS", keywordsIDS);
  // console.log("OPERATOR", operator);

  let byKeywords = [];
  let byText = [];

  if (operator === "AND") {
    // Look in participant keywords
    byKeywords = _.filter(projects, (obj) => {
      if (obj.keywords) {
        return keywordsIDS.every((kwd) => obj["keywords"].includes(kwd));
      } else {
        return false;
      }
    });
    // Look in the title and abstract
    byText = _.filter(projects, (obj) => {
      return (
        obj["title"] !== undefined &&
        obj["abstract"] !== undefined &&
        keywordsIDS.every(
          (kwd) =>
            obj["title"].search(kwd) !== -1 ||
            obj["abstract"].search(kwd) !== -1
        )
      );
    });
  } else {
    // Look in participant keywords
    byKeywords = _.filter(projects, (obj) => {
      if (obj.keywords) {
        return keywordsIDS.some((kwd) => obj["keywords"].includes(kwd));
      } else {
        return false;
      }
    });
    // Look in the title and abstract
    byText = _.filter(projects, (obj) => {
      return (
        obj["title"] !== undefined &&
        obj["abstract"] !== undefined &&
        keywordsIDS.some(
          (kwd) =>
            obj["title"].search(kwd) !== -1 ||
            obj["abstract"].search(kwd) !== -1
        )
      );
    });
  }
  projects = _.uniq(byKeywords.concat(byText));

  return projects;
};
