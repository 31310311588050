import React, { Component } from "react";

import "./index.scss";

class Loader extends Component {
  render() {
    const { width, height, text, image, size } = this.props;

    return (
      <div
        className={`loader mx-auto w-${width ? width : 100} h-${
          height ? height : 100
        } size-${size ? size : "none"}`}
      >
        {image && <img className="mb-2 d-block" alt="logotype" src={image} />}
        {/* <div className="spinner-border" role="status">
            </div> */}
        <img
          className="loading-spinner"
          src="./images/loading-small.gif"
          alt="Carregant"
        />{" "}
        {text && <p className="mb-0 ml-2">{text}</p>}
      </div>
    );
  }
}

export default Loader;
