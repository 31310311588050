import React, { useEffect } from "react";
import * as d3 from "d3";
import _ from "lodash";

import { graphToJSON } from "./functions/utils";
import { graph } from "./functions/data";
import { generateScales } from "./functions/scales";
import {
  getForceSimulation,
  setClusteredForceLayout,
  setIsolatedForceLayout,
  removePropForces,
  removeClusteringForces,
} from "./functions/simulation";
import { ticked } from "./functions/interaction";
import { CONFIG } from "../../common/constants";

export const ParticipantsNetwork = function (props) {
  // Let's check the zoom values
  // console.log("ZOOM", props.zoom);

  // DATA
  // 1. nodes (dots)
  // Data comes already filtered
  const data = props.data.networkData;
  // Process data for network
  const G = graph({
    myData: data,
    myConfig: CONFIG.APP.CONFIG,
  }).create();

  let JSON = graphToJSON(G);
  // Force a filtering By activityType
  // Calculation by projects is bad and must be rewieved

  // TODO: Better calculation of participant activityType by projects
  JSON.nodes = _.map(JSON.nodes, function (d) {
    const foundNode = _.find(
      props.filters.populate.participantsReference["options"],
      function (o) {
        return o["organizationId"] === d["id"];
      }
    );

    d["values"]["participantType"] = [foundNode["activityTypeId"]];
    return d;
  });

  // Check if is there activityType filter applied
  const activityTypeFilter = _.find(props.filters.selected, function (o) {
    return o["type"] === "activityType";
  });

  if (activityTypeFilter) {
    JSON.nodes = _.filter(JSON.nodes, function (d) {
      return activityTypeFilter["values"].includes(
        d["values"]["participantType"][0]
      );
    });

  }

  // Remap activityTypeId to the reference partners object

  // Remember to add CERCA when REC is present

  // 2. Define canvas

  const canvasRef = React.useRef(null);

  // 3. Variables

  let edgeWeightModel = 1;

  const selectedParticipants = props.filters.searchedParticipantIds;
  // Selection of nodes in search box
  if (selectedParticipants.length > 0) {
    JSON.nodes.forEach(function (n) {
      n.__searched = selectedParticipants.includes(n["id"]) && true;
    });
  }

  let typeaheadModel = props["clickedNode"]
    ? _.find(JSON["nodes"], (obj) => obj.id === props["clickedNode"])
    : undefined;

  let pickedNodes = [];

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // CANVAS
    const canvasEl = d3
      .select(canvasRef.current)
      .attr("id", "networkCanvas")
      .attr("class", "canvas-network")
      .attr("width", props.width + "px")
      .attr("height", props.height + "px");

    const context = canvasEl
      .node()
      .getContext("2d", { willReadFrequently: true });

    // VARIABLES
    let transform = d3.zoomIdentity,
      selectedDragNode = null;
    const zoom = d3.zoom(),
      drag = d3.drag();

    // FUNCTIONS
    /**
     * @param   {Object} A node object
     * Set property '__highlight'  to true to the received node, to those nodes that are inmediate
     * neighbours of the received node, and the links that connect the received node with these
     * immediate neighbours
     */
    const highlightNodeCanvas = function (hNode) {
      if (hNode && _.includes(_.map(JSON.nodes, "id"), hNode.id)) {
        // select links between our node
        // and its neighbours
        var firstNeighboursLinks = _.filter(JSON.links, function (l) {
            return l.source.id === hNode.id || l.target.id === hNode.id;
          }),
          // select neighbour nodes
          firstNeighbours = _.uniq(
            _.map(firstNeighboursLinks, function (o) {
              return o.source.id;
            }).concat(
              _.map(firstNeighboursLinks, function (o) {
                return o.target.id;
              })
            )
          );

        if (firstNeighbours.length === 0) firstNeighbours.push(hNode.id);

        JSON.nodes
          .filter(function (d) {
            return _.includes(firstNeighbours, d.id);
          })
          .forEach(function (n) {
            n.__highlighted = true;
            n.__rolloved = n.id === hNode.id;
          });

        JSON.links
          .filter(function (d) {
            return _.includes(
              _.map(firstNeighboursLinks, function (l) {
                return (
                  l.target.id === d.target.id && l.source.id === d.source.id
                );
              }),
              true
            );
          })
          .forEach(function (l) {
            l.__highlighted = true;
          });
      }
    }; // end highlightNode function

    /**
     * func to restore state of nodes and links:
     * reset own properties used to keep track of what is
     * going on regarding interactivity (mouseover and click)
     * these own properties start with '__'
     */
    const resetNodesAndLinks = function () {
      var resetProperties = function (elem) {
        if (elem !== typeaheadModel) {
          _(_.keys(elem))
            .filter(function (k) {
              return /__/gi.test(k);
            })
            .forEach(function (k) {
              var brushedNodes = _.includes(pickedNodes, elem.id);
              if (brushedNodes) {
                k !== "__brushed" && (elem[k] = false);
              } else {
                k !== "__searched" && (elem[k] = false);
              }
            });
        }
      };
      JSON.nodes.forEach(resetProperties);
      JSON.links.forEach(resetProperties);
    };

    // SCALES
    const scales = generateScales({
      data: JSON,
    });

    // This come from teh sorting selector
    const sortCriteria = {
      field: props.sorting.network.field,
      label: props.sorting.network.label,
      colorMap: props.sorting.scale ? props.sorting.scale : scales.colorMap,
      options: props.sorting.options,
    };

    // INTERACTION FUNCTIONS

    const sendSelectedNodeWidth = function () {
      return typeaheadModel
        ? context.measureText(typeaheadModel.label).width
        : 0;
    };

    /**
     *
     * @param {Object} selectedNode
     * Filling and formating entitat field
     */

    let mouseCanvas = [];

    const mouseCanvasHandler = function (event) {
      mouseCanvas = [
        transform.invertX(d3.pointer(event)[0]),
        transform.invertY(d3.pointer(event)[1]),
      ];

      updateNetwork();

      event.type === "mousemove" &&
        (findClosestNode()
          ? (document.body.style.cursor = "pointer")
          : (document.body.style.cursor = "default"));

      if (event.type === "click") {
        onClickCanvas(findClosestNode());
      }

      runTicked();
    };

    const centerNodeInCanvas = (n) => {
      // If n is undefined, center the network
      if (!n) {
        centerNetworkInCanvas();
        return;
      }

      zoom.translateBy(canvasEl, 0, 0);
      zoom.scaleBy(canvasEl, 1.25);

      var visiblePosition = { x: props.width * 0.33, y: props.height * 0.33 };
      zoom.translateBy(
        canvasEl,
        (visiblePosition.x - transform.applyX(n.x)) / transform.k,
        (visiblePosition.y - transform.applyY(n.y)) / transform.k
      );
    };

    const centerNetworkInCanvas = () => {
      zoom.translateTo(canvasEl, props.width * 0.5, 0);
      zoom.scaleTo(canvasEl, 0.5);
    };

    // const setLabelsOut = () => {

    const onClickCanvas = function (n) {
      // show/hide statistics panel
      // $rootScope.$broadcast(n ? Events.SHOW_AGGS_PANEL : Events.HIDE_AGGS_PANEL);

      if (n) {
        n.__clicked = true;
        // when a selection is done, the statistic panel unfolds, so clicked node can be hidden.
        // Move it to a visible position in the canvas (half width and close to the top)
        // Translation is calculated by:
        // 1) getting the distance between current position of the node (can be transformed) and our target position
        // 2) Apply the zoom factor to the distance to avoid scaling it

        // OPEN AN INFO WINDOW
        props.openNodeInfo(n.id);

        centerNodeInCanvas(n);
        highlightNodeCanvas(n);
        // typeaheadModel = n;
        resetNodesAndLinks();
        ticked({
          transform: transform,
          min: edgeWeightModel,
          textWidth: sendSelectedNodeWidth(),
          context: context,
          width: props.width,
          height: props.height,
          scales: scales,
          sortCriteria: sortCriteria,
          graphJSON: JSON,
        });
        // TODO: MULTIPLE SELECTION
        // pickedNodes = [n.id];
        // props.setPickedNodes(pickedNodes);
      } else {
        // typeaheadModel = undefined;
        // pickedNodes = [];
        // TODO: prepare to send selection
        // props.setPickedNodes(pickedNodes);
        // updateNetwork();
        // // Improve display removing label
        // ticked({
        //   transform: transform,
        //   min: edgeWeightModel,
        //   textWidth: -1,
        //   context: context,
        //   width: props.width,
        //   height: props.height,
        //   scales: scales,
        //   sortCriteria: sortCriteria,
        //   graphJSON: JSON
        // });
        props.closeNodeInfo();
      }

      // onClickCanvas can be called also from the typeahead input selector,
      // if so we need to refresh the canvas in order to update visually
    };

    // DRAG
    // TODO: Strangly the drag event stops suddenly
    const dragstarted = function (event) {
      document.body.style.cursor = "move";
      fix_nodes(selectedDragNode);

      // mouseCanvas = [
      //   transform.invertX(d3.pointer(event)[0]),
      //   transform.invertY(d3.pointer(event)[1]),
      // ];

      if (!event.active) {
        simulation.alpha(0.3).restart();
      }
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;

      runTicked();
    };

    const dragged = function (event) {
      fix_nodes(selectedDragNode);

      // mouseCanvas = [
      //   transform.invertX(d3.pointer(event)[0]),
      //   transform.invertY(d3.pointer(event)[1]),
      // ];

      event.subject.fx += event.dx / transform.k;
      event.subject.fy += event.dy / transform.k;

      runTicked();
    };

    const dragended = function (event) {
      document.body.style.cursor = "default";
      // mouseCanvas = [
      //   transform.invertX(d3.pointer(event)[0]),
      //   transform.invertY(d3.pointer(event)[1]),
      // ];

      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;

      runTicked();
    };

    // ZOOM
    const zoomed = function (event) {
      // Change cursor depending on the event
      if (event) {
        if (event.sourceEvent && event.sourceEvent.type === "wheel") {
          document.body.style.cursor = "zoom-in";
        } else {
          document.body.style.cursor = "grab";
        }
        transform = event.transform;
      }

      runTicked();
    };

    // Preventing other nodes from moving while dragging one node
    const fix_nodes = function (this_node) {
      _.find(JSON.nodes, function (node) {
        if (this_node !== node) {
          node.fx = node.x;
          node.fy = node.y;
        }
      });
    };

    /**
     * @returns the node with the __clicked attibute, otherwise return undefined
     */
    // const clickedNode = function () {
    //   return _.find(JSON.nodes, function (node) {
    //     return node.__clicked;
    //   });
    // };

    // throttled function to update state of nodes and links
    const updateNetwork = _.throttle(function () {
      // if there is a clicked node, do not update the network
      // until a) click in empty canvas or b)typeahead??

      // if (clickedNode())
      //   return;

      resetNodesAndLinks();

      // find the node that can contain the x,y mouse
      var closest = findClosestNode();
      if (closest) highlightNodeCanvas(closest);
    }, 25);

    /**
     * Finds the node closest to the mouse position. Transforms generated by the d3.zoom are applied
     * to take into account possible zooming and panning, so we take the current mouse position and
     * apply the inverse transform, in order to have a position aligned with the node internal position
     *
     * @returns If the mouse position falls between the circle of the node, return the matched node,
     *          otherwise return undefined
     */

    const findClosestNode = function () {

      return _.find(JSON.nodes, function (node) {
        if (!node) {
          return false;
        }

        // what about https://github.com/d3/d3-quadtree#quadtree_find ?
        return (
          CONFIG.APP.EUCLIDEAN_DISTANCE(
            node.x,
            node.y,
            mouseCanvas[0],
            mouseCanvas[1]
          ) < node.radius
        );
      });
    };

    const minimumNodesForGrouping = 3;

    // decide how to cluster nodes: it can be by auto-detected communities
    // (first option in the UI selector regarding entity grouping criteria)
    // or by specific properties contained in the nodes. For the latest,
    // we have to assign node's group value to the selected property
    if (sortCriteria.field !== "group") {
      // if not grouping by auto-detected communities, take the value of
      // the selected property as id of the group. Do it for those
      // that are not isolated (its group == constants.ID_NO_GROUP)
      _.filter(JSON.nodes, function (node) {
        return node.values.group !== CONFIG.APP.ID_NO_GROUP;
      }).forEach(function (node) {
        node.values.group = _.first(node.values[sortCriteria.field]);
      });
    }

    // count groups by its number of nodes. groups values can be either
    // numbers generated from the auto-detected communities or values
    // of the selected property
    const groupCounts = _.countBy(JSON.nodes, function (n) {
      return n.values.group;
    });
    // reassign all those groups with less than
    // 3 nodes to a specific group
    JSON.nodes.forEach(function (n) {
      if (groupCounts[n.values.group] < minimumNodesForGrouping)
        n.values.group = CONFIG.APP.ID_NO_GROUP;
    });

    // PROCESS DATA
    // for each node, precalculate its radius based on its
    // data and store the largest node for each group
    JSON.nodes.forEach(function (node) {
      node.radius = scales.sizeScale(node.values[CONFIG.APP.CONFIG.size.field]);
    });

    // VARIABLES
    const selectedNodeProperty = sortCriteria.field;

    // SIMULATION
    const simulation = getForceSimulation();
    simulation.nodes(JSON.nodes);

    //SIMULATION TYPE
    // This is for sorting criteria [tipus d'entitat, programa, provincia]
    // This is the initial for collaboration
    sortCriteria.field === "group"
      ? setClusteredForceLayout({
          selectedNodeProperty: selectedNodeProperty,
          graphJSON: JSON,
          simulation: simulation,
          width: props.width,
          height: props.height,
        })
      : setIsolatedForceLayout({
          selectedNodeProperty: selectedNodeProperty,
          graphJSON: JSON,
          simulation: simulation,
          width: props.width,
        });

    // simulate without rendering
    simulation.alphaTarget(0);
    simulation.alpha(1).stop();

    // runTicked();

    for (
      var i = 0,
        n = Math.ceil(
          Math.log(simulation.alphaMin()) /
            Math.log(1 - simulation.alphaDecay())
        );
      i < n;
      ++i
    ) {
      simulation.tick();
    }

    // here the simulation has already calculated the layout
    // just call to ticked function to render the entire
    // network with the finals positions
    simulation.on(
      "tick",
      ticked({
        transform: transform,
        min: edgeWeightModel,
        textWidth: sendSelectedNodeWidth(),
        context: context,
        width: props.width,
        height: props.height,
        scales: scales,
        sortCriteria: sortCriteria,
        graphJSON: JSON,
      })
    );

    if (sortCriteria.field === "group") {
      removeClusteringForces({
        simulation: simulation,
      });
    } else {
      simulation.alpha(0.3).restart();
      var intPromise = setInterval(function () {
        if (simulation.alpha() < 0.3) {
          removePropForces({
            simulation: simulation,
          });
          clearInterval(intPromise);
        }
      }, 300);
    }

    // EVENTS ON CANVAS

    canvasEl
      .on("mousemove", function (event) {
        mouseCanvasHandler(event);
      })
      .call(
        drag
          .subject(findClosestNode)
          .on("start", function (event) {
            selectedDragNode = findClosestNode();
            dragstarted(event);
          })
          .on("drag", function (event) {
            dragged(event);
          })
          .on("end", function (event) {
            dragended(event);
          })
      )
      .on("click", function (event) {
        mouseCanvasHandler(event);
      })
      .call(
        zoom
          .scaleExtent([0.25, 8])
          .on("zoom", zoomed)
          // .on("end", props.setZoomActive(true))
      );

    // LANDING EVENTS
    // TODO: Review when to apply centering. Cases:
    // - Changing layouts
    // - After filtering
    // - Center node coming from search
    // ...
    if (selectedParticipants.length > 0 && JSON["nodes"].length > 1) {
      const searchedNode = _.filter(
        JSON["nodes"],
        (node) =>
          node["id"] ===
          props.filters.searchedParticipantIds[
            props.filters.searchedParticipantIds.length - 1
          ]
      );
      centerNodeInCanvas(searchedNode[0]);
    }
    // If initial load or filtered
    // random a Node
    else {
      centerNetworkInCanvas();
    }

    // Manage zoom coming from props toolbar
    if (props.zoom.network !== undefined) {
      if (props.zoom.network.length > 0) {
        // Set object trasform
        transform = {
          k: props.zoom.network[0],
          // x: (props.zoom.network[1] - (props.width / 2) * props.zoom.network[0]),
          x: props.zoom.network[1],
          // y: (props.zoom.network[2] - (props.height / 2) * props.zoom.network[0]),
          y: props.zoom.network[2],
        };
        // Set zoom for the canvas
        zoom.scaleTo(canvasEl, transform.k);
        // zoom.translateTo(canvasEl, transform.x, transform.y);
        // Call to zoomed function
        zoomed();
      }
    }

    // Set selected node if available from props
    if (typeaheadModel) {
      // Need to add a timer to give place and time for everything
      setTimeout(() => {
        onClickCanvas(typeaheadModel);
      }, 1);
    }

    // Run a tick to render the network
    function runTicked() {
      ticked({
        transform: transform,
        min: edgeWeightModel,
        textWidth: sendSelectedNodeWidth(),
        context: context,
        width: props.width,
        height: props.height,
        scales: scales,
        sortCriteria: sortCriteria,
        graphJSON: JSON,
      });
    }

    d3.selectAll("#canvasLoadingText").remove();

    // Run a tick to render the network
    // runTicked();
  });

  return <canvas ref={canvasRef} />;
};
