import * as d3 from "d3";
// common config for the vega vizs
export const VEGA_CONFIG = {
  title: {
    fontFamily: "Open Sans",
  },
};

export const PROPERTIES_TO_KEYS = {
  categoryName : "CATEGORY_NAME",
  topics : "TOPICS",
  SDGs: "SDGS",
  frameworks : "FRAMEWORKS",
  activityType : "ACTIVITY_TYPE",
  nuts3 : "NUTS3",
  years: "YEARS",
};

export const STATIC_DATA_FOLDER = "./static-data";

export const SPARQL_ENDPOINT =
  // "https://sparql.unics.cloud/ris3cat_test/sparql/query"; // https test
  "https://sparql.unics.cloud/ris3cat/sparql/query"; // https production

// INITIAL CONFIG FOR PROJECT
export const CONFIG = {
  APP: {
    LANGUAGE: "ca",
    COPIES: {
      TITLE: "Plataforma RIS3-MCAT",
    },
    TOPICS: {
      ACTIVE: true,
      NAME: "topics",
      TITLE: "Mapa de projectes",
      CONFIG: {
        labels: "label",
      },
    },
    NETWORK: {
      ACTIVE: true,
      NAME: "network",
      TITLE: "Xarxa d'entitats",
    },
    PROJECT_RELATED: true, // true if we want to show data
    LAYOUT: {
      MAIN_TABS_HEIGHT: 50,
      SPACER: 15,
      BUTTON_SIZE: 40,
      PANEL_WIDTH: 380,
    },
    // Set the active tab when app starts
    ACTIVE_TAB: "network",
    CHUNK_SIZE: 100,
    CONFIG: {
      label: "RIS3CAT",
      entity: {
        field: "projectId",
        title: {
          field: "projectTitle",
        },
        abstract: {
          field: "projectAbstract",
        },
        funding: {
          investment: {
            field: "totalInvestment",
          },
          grant: {
            field: "totalGrant",
          },
        },
        acronym: {
          field: "projectAcronym",
        },
        keywords: {
          field: "keywords",
          sep: "|",
        },
      },
      node: {
        id: {
          field: "organizationId",
        },
        name: {
          field: "organizationName",
        },
        // Add values to participants (nodes) from projects
        // TODO review if we need this data on nodes
        // Pass values for sorting criteria
        aggs: [
          {
            field: "participantType",
            label: "Tipus d'entitat",
          },
          {
            field: "nodeFramework",
            label: "Programa",
          },
          {
            field: "participantNuts3Name",
            label: "Província",
          },
          // {
          //   field: "categoryName",
          //   label: "Àmbit d'actuació",
          // },
          // {
          //   field: "nodeKeywords",
          //   label: "Paraules clau",
          // },
        ],
        type: {
          id: {
            field: "activityTypeId",
          },
          name: {
            field: "participantType",
          },
        },
        geo: {
          region: {
            field: "participantNuts3Name",
          },
          country: {
            field: "countryName",
          },
        },
        framework: {
          field: "nodeFramework",
        },
        funding: {
          investment: {
            field: "funding",
          },
          grant: {
            field: "grant",
          },
        },
        taxonomy: {
          s3: {
            priority: {
              id: {
                field: "Node RIS3CAT Àmbit d'Actuació Prioritari",
              },
              name: {
                field: "Node RIS3CAT Àmbit d'Actuació Prioritari",
              },
            },
          },
        },
        role: {
          field: "roleInProject",
        },
      },
      link: {
        aggs: [],
      },
      size: {
        opt: "sum",
        field: "investment",
      },
      temporal: {
        date: {
          starting: {
            field: "startingDate",
          },
          ending: {
            field: "endingDate",
          },
        },
        year: {
          starting: {
            field: "startingYear",
          },
          ending: {
            field: "startingYear",
          },
        },
      },
      framework: {
        name: {
          field: "frameworkName",
        },
      },
      taxonomy: {
        meta: {
          type: {
            field: "categoryTypeName",
          },
          name: {
            field: "categoryName",
          },
          instrument: {
            field: "operacioCoordinadaName",
          },
        },
        instrument: {
          id: {
            field: "instrumentId",
          },
          name: {
            field: "instrumentName",
          },
        },
        s3: {
          priority: {
            id: {
              field: "RIS3CAT Àmbit d'Actuació Prioritari",
            },
            name: {
              field: "RIS3CAT Àmbit d'Actuació Prioritari",
            },
            label: {
              value: "Àmbits d'actuació",
            },
          },
        },
        cordis: {
          id: {
            field: "ecProgrammeId",
          },
          name: {
            field: "ecProgrammeName",
          },
        },
        sdg: {
          id: {
            field: "sdgId",
          },
          name: {
            field: "sdgName",
          },
        },
      },
      filter: [
        {
          label: "Programa",
          child: {
            field_id: "instrumentId",
            field_label: "instrumentName",
          },
          parent: {
            field_id: "frameworkName",
            field_label: "frameworkName",
          },
        },
        {
          field_id: "RIS3CAT Àmbit d'Actuació Prioritari",
          field_label: "RIS3CAT Àmbit d'Actuació Prioritari",
          label: "Àmbit d'actuació",
        },
        {
          field_id: "sdgId",
          field_label: "sdgName",
          label: "ODS",
        },
        {
          field_id: "participantType",
          field_label: "participantType",
          label: "Tipus d'entitat",
        },
        {
          field_id: "participantNuts3Name",
          field_label: "participantNuts3Name",
          label: "Província",
        },
      ],
    },
    NETWORK_COLORS: {
      LINKS_SCALE_RANGE: ["#DDD", "#777"],
      LINK_SELECTED: "rgba(0,0,0,.75)",
      TEXT_ROLLOVED: "rgba(0,0,0,.9)",
    },
    ID_NO_GROUP: -1,
    EUCLIDEAN_DISTANCE: function (x1, y1, x2, y2) {
      return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
    },
  },
  TOPICS: {
    LAYOUT: {
      MARGIN: {
        TOP: 0,
        RIGHT: 0, // lateral panel
        BOTTOM: 0,
        LEFT: 0,
      },
      COLLISION_RADIUS: 1,
      COLLISION_ITERATIONS: 9,
      SPACER: 12,
      FONT_SIZE: 12,
      FONT_FAMILY: "Open Sans",
      COLOR_CLUSTER: "#333333",
      COLOR_VALUES: [
        hexToRgbA("#C44601"),
        hexToRgbA("#5928ED"),
        hexToRgbA("#B51963"),
        hexToRgbA("#7209A6"),
        hexToRgbA("#F39C12"),
        hexToRgbA("#0E6655"),
        hexToRgbA("#39768E"),
        hexToRgbA("#4F6CAF"),
        hexToRgbA("#573F2D"),
        hexToRgbA("#2D483F"),
        hexToRgbA("#4B5D6F"),
        hexToRgbA("#4D573A"),
        hexToRgbA("#6F6268"),
      ],
      // COLOR_VALUES: ["#1F77B4", "#FF7F0B", "#2CA02C", "#D62728", "#9467BD", "#8C564B", "#E377C2", "#7F7F7F", "#BCBD22", "#17BECF", "#1F77B4"],
      PANEL_WIDTH: 360,
      OPACITIES: {
        INACTIVE: 0.1,
        NORMAL: 0.5,
        ACTIVE_DOT: 0.6,
        ACTIVE_LABEL: 0.75,
        ACTIVE: 0.9,
        FULL: 1,
      },
      COLOR_LABEL_BACKGROUND: "#FFFFFF",
    },
    DATA_FOLDERS: {
      COORDS: "./data-ris3cat",
      TOPICS: "./data-ris3cat",
    },
  },
  FILTERS: [
    {
      TYPE: "checkboxes",
      ENTITY: "categoryName",
      TRANSLATION_KEY: "CATEGORY_NAME",
      COLUMNS: 1,
    },
    {
      TYPE: "checkboxes",
      ENTITY: "topics",
      TRANSLATION_KEY: "TOPICS",
      OPTIONS_COLUMNS: 1,
    },
    {
      TYPE: "checkboxes",
      ENTITY: "SDGs",
      TRANSLATION_KEY: "SDGS",
      COLUMNS: 1,
    },
    {
      TYPE: "hierarchy",
      ENTITY: "frameworks",
      TITLE: "Programa",
      TRANSLATION_KEY: "FRAMEWORKS",
      COLUMNS: 1,
    },
    {
      TYPE: "checkboxes",
      ENTITY: "activityType",
      TRANSLATION_KEY: "ACTIVITY_TYPE",
      COLUMNS: 1,
    },
    {
      TYPE: "checkboxes",
      ENTITY: "nuts3",
      TRANSLATION_KEY: "NUTS3",
      COLUMNS: 2,
    },
    {
      TYPE: "filterSlide",
      ENTITY: "years",
      TRANSLATION_KEY: "YEARS",
    },
  ],
  SEARCH: {},
  COLABORATORS: {},
};

// UTILS
export const FORMAT_EURO = d3
  .formatLocale({
    decimal: ",",
    thousands: ".",
    grouping: [3],
    currency: ["", " €"],
  })
  .format("$,.0f");

export const FORMAT_THOUSANDS = d3
  .formatLocale({
    decimal: ",",
    thousands: ".",
    grouping: [3],
  })
  .format("$,.0f");

export const FORMAT_MILLIONS = d3
  .formatLocale({
    decimal: ",",
    thousands: ".",
    grouping: [3],
    currency: ["", "€"],
  })
  .format("$,.2r");

export const FORMAT_MILLIONS_CUSTOM = (value) => {
  if (value >= 1000000000) {
    var n = Math.round(value / 1000000);
    return n.toLocaleString("es") + " M€";
  } else {
    // redefine the d3.format to the new locale
    d3.formatDefaultLocale({
      currency: ["", "€"],
      decimal: ".",
      thousands: ".",
      grouping: [3],
    });
    return d3.format("$.2s")(value).replace("M", " M");
  }
};

// Mapping (labelling) properties from data
export const ACTIVITY_TYPES = [
  { value: "PUB", label: "Administració pública", sort: 1 },
  { value: "REC", label: "Centre de recerca", sort: 2 },
  { value: "CERCA", label: "Centre CERCA", sort: 3 },
  { value: "PRC", label: "Empresa", sort: 4 },
  { value: "HES", label: "Universitat", sort: 5 },
  { value: "OTH", label: "Altres", sort: 6 },
];

export const ACTIVITY_TYPES_LABELS = {
  HES: "Universitat",
  PRC: "Empresa",
  PUB: "Administració pública",
  REC: "Centre de recerca",
  CERCA: "Centre CERCA",
  OTH: "Altres",
};

// Map filters types with label names
export const FILTERS_LABELS = {
  categoryName: "Àmbit d'actuació",
  SDGs: "Objectiu de desenvolupament sostenible (ODS)",
  frameworks: "Programa",
  activityType: "Tipus d'entitat",
  nuts3: "Província",
  years: "Període",
  topics: "Temàtica",
  technologies: "RIS3CAT technologies",
  sectors: "Àmbit sectorial",
  keywords: "Paraules clau",
  participants: "Entitats",
};

export const PROGRAMS_LABELS = {
  HORIZON: "Horitzó Europa",
  H2020: "Horitzó 2020",
  "Instruments RIS3CAT": "Instruments de la RIS3CAT",
};

// Create an array of cluster that should be moved to fix overlaping
// Each cluster affected should add a percentage values relative to the screen
export const CLUSTERS_TO_MOVE = [
  {
    id: 0, // Determinants ambientals i socials de la salut
    x: -10,
    y: 6,
  },
  {
    id: 1, // Transicions cap a la sostenibilitat
    x: 0,
    y: -10,
  },
  {
    id: 2, // Ind\u00fastria 4.0. Sistemes ciberf\u00edsics. Cadenes de subministrament
    x: 0,
    y: 2,
  },
  {
    id: 3, // Neuroci\u00e8ncies. Trastorns neurodegeneratius i mentals
    x: 0,
    y: -6,
  },
  {
    id: 4, // Diagnòstic i monitoratge de la salut
    x: 0,
    y: -4,
  },
  {
    id: 5, // Clima. Observació i modelització terrestre
    x: 14,
    y: -4,
  },
  {
    id: 7, // Gesti\u00f3 i tractament de l'aig\u00fca
    x: 14,
    y: -2,
  },
  {
    id: 8, // Xarxes intel·ligents. Gestió i comunitats energètiques
    x: 0,
    y: 10,
  },
  {
    id: 9, // Càncer 1
    x: -10,
    y: 0,
  },
  {
    id: 12, // Infraestructures per la ciència oberta. Ciència oberta i ciutadana
    x: 0,
    y: -2,
  },
  {
    id: 14, // Agricultura. Botànica
    x: 10,
    y: 0,
  },
  {
    id: 18, // Tecnologies quàntiques. Fotònica. Nanoelectrònica
    x: -10,
    y: 5,
  },
  {
    id: 20, // Química verda. Materials per a la transició energètica. Captació de carboni
    x: -16,
    y: 0,
  },
  {
    id: 23, // Malalties infeccioses i vacunes
    x: 0,
    y: -10,
  },
  {
    id: 24, // Agricultura. Silvicultura. Gestió i desenvolupament territorial
    x: 10,
    y: 2,
  },
  {
    id: 25, // Coordinació dels projectes territorials (PECT)
    x: 0,
    y: -5,
  },
  {
    id: 27, // Salut digital
    x: 0,
    y: 3,
  },
  {
    id: 28, // Seguretat i ciberseguretat. Privadesa. Blockchain
    x: 6,
    y: -2,
  },
  {
    id: 29, // Ciències cognitives
    x: 5,
    y: -5,
  },
  {
    id: 30, // Acció climàtica. Gestió dels recursos ambientals
    x: 4,
    y: -6,
  },
  {
    id: 31, // Acció climàtica. Gestió dels recursos ambientals
    x: -8,
    y: 0,
  },
  {
    id: 32, // Microscòpia i imatge avançada. Biofotònica.
    x: -8,
    y: 2,
  },
  {
    id: 34, // Enginyeria de teixits i biomaterials
    x: -10,
    y: 0,
  },
  {
    id: 35, // Emprenedoria, innovació i ecosistemes empresarials
    x: 10,
    y: -5,
  },
  {
    id: 36, // Eficiència energètica i sostenibilitat a la construcció i la indústria
    x: 14,
    y: 0,
  },
  {
    id: 37, // Patrimoni cultural i natural. Estudis culturals i història. Indústries creatives.
    x: 14,
    y: 0,
  },
  {
    id: 42, // Teràpies avançades i immunoteràpia
    x: 0,
    y: -5,
  },
  {
    id: 41, // Càncer 2
    x: -10,
    y: 0,
  },
  {
    id: 43, // Economia blava. Recursos hídrics. Ciències de la terra
    x: 10,
    y: 6,
  },
  {
    id: 44, // Manufactura avançada. Materials sostenibles. Circularitat
    x: -10,
    y: -2,
  },
  {
    id: 45, // Atenció sanitària. Salut pública i digital
    x: 0,
    y: -8,
  },
  {
    id: 46, // Educació i formació contínua. Integració social
    x: -5,
    y: -10,
  },
  {
    id: 48, // Disseny, simulació i modelatge per als sectors de l'energia i el transport
    x: 0,
    y: 8,
  },
];

// These projects are not classified by program
export const PROJECTS_BLACKLIST = [
  // Without program
  "CORDIS-701561",
  "CORDIS-101061677",
  "CORDIS-101060920",
  "CORDIS-101061037",
  "CORDIS-101060028",
  "CORDIS-101061251",
  "CORDIS-101052200",
  // Not from Catalunya
  "CORDIS-101082021", // https://cordis.europa.eu/project/id/101082021
  "CORDIS-101091668", // https://cordis.europa.eu/project/id/101091668
];

function hexToRgbA(hex) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
    );
  }
  throw new Error("Bad Hex");
}
