import React, { Component, Fragment } from "react";
import _ from "lodash";
import "./index.scss";
import { getTranslation } from "../../common/translations";

class SortingPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      internalOptions: [
        {
          field: "group",
          label: getTranslation({
            lang: props.lang,
            key: "SORTING_PANEL.OPTIONS.OPTION_1",
          }),
          filterField: "",
        },
        {
          field: "participantType",
          label: getTranslation({
            lang: props.lang,
            key: "SORTING_PANEL.OPTIONS.OPTION_2",
          }),
          filterField: "activityType",
        },
        {
          field: "nodeFramework",
          label: getTranslation({
            lang: props.lang,
            key: "SORTING_PANEL.OPTIONS.OPTION_3",
          }),
          filterField: "frameworks",
        },
        {
          field: "participantNuts3Name",
          label: getTranslation({
            lang: props.lang,
            key: "SORTING_PANEL.OPTIONS.OPTION_4",
          }),
          filterField: "nuts3",
        },
      ],
    };
  }

  componentDidMount() {
    // Call any function in provider
    this.setState({
      loading: false,
    });
  }

  handleSelected = (event) => {
    // console.log("EVENT", event);
    // console.log("EVENT.TARGET", event["target"]);
    // console.log("EVENT.TARGET.VALUE", event["target"]["value"]);

    let currenOptions = [...this.state.internalOptions];

    const selectedOption = _.find(
      currenOptions,
      (option) => option["field"] === event["target"]["value"]
    );

    const selectedLegendOptions = _.find(
      this.props.filters["populate"][selectedOption["filterField"]]
    );

    // console.log("SELECTED LEGEND OPTIONS", selectedLegendOptions);

    // Set the array of colors for each option according to the scale
    // selectedOption["colors"] = scale.range();

    // console.log("selectedOption", selectedOption);
    this.props.setNetworkSortingCriteria(selectedOption, selectedLegendOptions);
  };

  render() {
    const { loading, internalOptions } = this.state;
    const { sorting, filters, lang } = this.props;
    const found = _.find(internalOptions, (option) => {
      return sorting.network["field"] === option["field"];
    });

    // Get the selected legend options
    let selectedLegendOptions = _.find(
      filters["populate"][found["filterField"]]
    );

    // Need to add an extra option when the sorting is by "frameworks"
    if (found["filterField"] === "frameworks") {
      selectedLegendOptions = [
        ...selectedLegendOptions,
        {
          label: getTranslation({
            lang: lang,
            key: "SORTING_PANEL.MULTIPLE_PROGRAMS",
          }),
          value: "various",
          color: "#6F418F",
        },
      ];
    }

    return (
      <Fragment>
        <div className="panel right-side-panel">
          <header>
            <h6>
              {getTranslation({
                lang: lang,
                key: "SORTING_PANEL.TITLE",
              })}
            </h6>
            <button
              className="btn btn-primary ms-auto"
              onClick={() => this.props.setActiveTool("")}
            >
              {getTranslation({
                lang: lang,
                key: "SORTING_PANEL.BUTTON_CLOSE",
              })}
            </button>
          </header>
          {loading ? (
            <p>Loading</p>
          ) : (
            <div className="search-container border-top border-bottom">
              {_.map(internalOptions, (option, index) => {
                return (
                  <div key={index} className="col col-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        value={option["field"]}
                        id={`option-${option["field"]}`}
                        onChange={(event) => this.handleSelected(event)}
                        checked={
                          found && found["field"] === option["field"]
                            ? true
                            : false
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`option-${option["field"]}`}
                      >
                        {option["label"]}
                      </label>
                    </div>
                    {found["field"] !== "group" &&
                      found["filterField"] === option["filterField"] && (
                        <ul className="legend-options">
                          {_.map(selectedLegendOptions, (option, index) => {
                            return (
                              <li
                                key={"property-" + index}
                                style={{ color: option["color"] }}
                              >
                                <span>{option["label"]}</span>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default SortingPanel;
