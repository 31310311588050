import React, { Component, Fragment } from "react";

import _ from "lodash";
import "./index.scss";
import {
  FaSearch,
  FaSlidersH,
  FaSearchPlus,
  FaSearchMinus,
  FaEye,
  FaCompressArrowsAlt,
  FaInfo,
  FaChevronCircleLeft,
} from "react-icons/fa";

import { VscFilter } from "react-icons/vsc"; // VscFilter

import LayerVisibility from "../LayerVisibility";

import { getTranslation } from "../../common/translations";

class CanvasToolsBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  render() {
    const { loading } = this.state;
    const {
      activeTab,
      setActiveTool,
      activeTool,
      filters,
      setZoom,
      setTopicsZoom,
      visibilityLayers,
      setVisibilityLayers,
      lang,
    } = this.props;
    const activeFilters = filters["selected"];

    // Exclude or include participants fro reseting pourposes
    let searchApplied = _.filter(
      activeFilters,
      (f) => f["type"] === "participants" || f["type"] === "keywords"
    );

    let filterApplied = _.filter(
      activeFilters,
      (f) => f["type"] !== "participants" && f["type"] !== "keywords"
    );

    // console.log("searchApplied", searchApplied);

    if (searchApplied) {
      searchApplied = searchApplied[0];
    } else {
      searchApplied = null;
    }

    return (
      <Fragment>
        {loading ? (
          <p>Carregant</p>
        ) : (
          <ul className="tools-list">
            <li
              className={
                "position-relative " +
                (activeTool === "filter" ? "active-tool" : "")
              }
              onClick={() => setActiveTool("filter")}
            >
              <label>
                <FaChevronCircleLeft></FaChevronCircleLeft>
                {getTranslation({
                  lang: lang,
                  key: "TOOLS.TOOLS_BAR.MENU.FILTER",
                })}
              </label>

              <button className="btn btn-toolbar btn-toolbar-highlighted btn-toolbar-filter">
                <VscFilter></VscFilter>
              </button>
              {filterApplied && filterApplied.length > 0 && (
                <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle"></span>
              )}
            </li>
            <li
              className={
                "position-relative " +
                (activeTool === "search" ? "active-tool" : "")
              }
              onClick={() => setActiveTool("search")}
            >
              <label>
                <FaChevronCircleLeft></FaChevronCircleLeft>
                {getTranslation({
                  lang: lang,
                  key: "TOOLS.TOOLS_BAR.MENU.SEARCH",
                })}
              </label>
              <button className="btn btn-toolbar btn-toolbar-highlighted">
                <FaSearch></FaSearch>
              </button>
              {searchApplied && searchApplied["values"].length > 0 && (
                <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle"></span>
              )}
            </li>

            {activeTab === "network" && (
              <li
                className={
                  "position-relative " +
                  (activeTool === "sortingNetwork" ? "active-tool" : "")
                }
                onClick={() => setActiveTool("sortingNetwork")}
              >
                <label>
                  <FaChevronCircleLeft></FaChevronCircleLeft>
                  {getTranslation({
                    lang: lang,
                    key: "TOOLS.TOOLS_BAR.MENU.SORT",
                  })}
                </label>
                <button className="btn btn-toolbar btn-toolbar-highlighted">
                  <FaSlidersH></FaSlidersH>
                </button>
              </li>
            )}
            {activeTab === "topics" && (
              <li
                className={
                  "position-relative " +
                  (activeTool === "sortingTopics" ? "active-tool" : "")
                }
              >
                <label className="min-150 hovered label-dark">
                  {getTranslation({
                    lang: lang,
                    key: "TOOLS.TOOLS_BAR.MENU.SHOW",
                  })}
                  <LayerVisibility
                    visibilityLayers={visibilityLayers}
                    setVisibilityLayers={setVisibilityLayers}
                    lang={lang}
                  ></LayerVisibility>
                </label>
                <button className="btn btn-toolbar btn-toolbar-highlighted">
                  <FaEye></FaEye>
                </button>
              </li>
            )}
            {/* TODO: Add this feature */}
            {/* <li>
              <label>Delimita la xarxa fent-hi clics</label>
              <button className="btn btn-toolbar">
                <FaDrawPolygon></FaDrawPolygon>
              </button>
            </li> */}
            <li
              onClick={() =>
                activeTab === "network"
                  ? setZoom("zoomIn")
                  : setTopicsZoom("zoomIn")
              }
            >
              <label className="label-dark">
                {getTranslation({
                  lang: lang,
                  key: "TOOLS.TOOLS_BAR.MENU.ZOOM_IN",
                })}
              </label>
              <button className="btn btn-toolbar">
                <FaSearchPlus></FaSearchPlus>
              </button>
            </li>
            <li
              onClick={() =>
                activeTab === "network"
                  ? setZoom("zoomOut")
                  : setTopicsZoom("zoomOut")
              }
            >
              <label className="label-dark">
                {getTranslation({
                  lang: lang,
                  key: "TOOLS.TOOLS_BAR.MENU.ZOOM_OUT",
                })}
              </label>
              <button className="btn btn-toolbar">
                <FaSearchMinus></FaSearchMinus>
              </button>
            </li>
            <li
              onClick={() =>
                activeTab === "network"
                  ? setZoom("zoomReset")
                  : setTopicsZoom("zoomReset")
              }
            >
              <label className="label-dark">
                {getTranslation({
                  lang: lang,
                  key: "TOOLS.TOOLS_BAR.MENU.ZOOM_RESET",
                })}
              </label>
              <button className="btn btn-toolbar">
                <FaCompressArrowsAlt></FaCompressArrowsAlt>
              </button>
            </li>
            <li>
              <label className="label-dark">
                {getTranslation({
                  lang: lang,
                  key: "TOOLS.TOOLS_BAR.MENU.DOWNLOAD",
                })}
              </label>
              <button
                className="btn btn-toolbar"
                onClick={() =>
                  this.props.dwnldExcel({
                    fileName: "dades-ris3mcat",
                    networkdata: this.props["networkData"],
                    // sheet: "Dades",
                  })
                }
              >
                {/* <FaRegFileExcel></FaRegFileExcel> */}
                <img src="./images/icon-xls.svg" alt="" />
              </button>
            </li>
            <li>
              <label className="hovered label-dark">
                <strong>
                  {getTranslation({
                    lang: lang,
                    key: "TOOLS.TOOLS_BAR.MENU.LEGEND",
                  })}
                </strong>{" "}
                <div className="label-tool-container">
                  {activeTab === "topics" ? (
                    <ul className="legend-topics">
                      <li className="icon-node">
                        {getTranslation({
                          lang: lang,
                          key: "TOOLS.TOOLS_BAR.LEGEND.OPTION_1",
                        })}
                      </li>
                    </ul>
                  ) : (
                    <ul className="legend-network">
                      <li className="icon-link">
                        {getTranslation({
                          lang: lang,
                          key: "TOOLS.TOOLS_BAR.LEGEND.OPTION_2",
                        })}
                      </li>
                      <li className="icon-node">
                        {getTranslation({
                          lang: lang,
                          key: "TOOLS.TOOLS_BAR.LEGEND.OPTION_3",
                        })}
                      </li>
                    </ul>
                  )}
                </div>
              </label>
              <button className="btn btn-toolbar">
                <FaInfo></FaInfo>
              </button>
            </li>
          </ul>
        )}
      </Fragment>
    );
  }
}

export default CanvasToolsBar;
