import React, { Component, Fragment } from "react";
import * as d3 from "d3";
import _ from "lodash";
import "./index.scss";
import { FORMAT_THOUSANDS, FORMAT_EURO, CONFIG } from "../../common/constants";

import { FaTimesCircle } from "react-icons/fa";

import { getTranslation } from "../../common/translations";

class NodePanelInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    const nodeInformation = this.getNodeInformation({
      currentId: this.props["clickedNode"],
      data: this.props.data["networkData"],
      participants: this.props.data["internalPartners"],
      filteredProjects: this.props.filters["filteredProjects"],
    });
    this.setState({
      loading: false,
      nodeInformation: nodeInformation,
    });
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    // console.log("NODE PANEL INFORMATION componentDidUpdate");
    // console.log("THIS.PROPS", this.props);
    // console.log("PREV_PROPS", prevProps);

    if (
      this.props["clickedNode"] !== prevProps["clickedNode"] ||
      !_.isEqual(
        this.props.filters["filteredProjects"],
        prevProps.filters["filteredProjects"]
      )
    ) {
      const nodeInformation = this.getNodeInformation({
        currentId: this.props["clickedNode"],
        data: this.props.data["networkData"],
        participants: this.props.data["internalPartners"],
        filteredProjects: this.props.filters["filteredProjects"],
      });
      this.setState({
        loading: false,
        nodeInformation: nodeInformation,
      });
    } else {
      // console.log("NODE PANEL INFORMATION -> NOT UPDATING");
    }
  }

  getNodeInformation = ({
    currentId = null,
    data = [],
    participants = [],
    filteredProjects = [],
  }) => {
    // console.log("GET_NODE_INFORMATION")
    // console.log("ID", currentId)
    // console.log("DATA", data)
    let currentNode = _.filter(
      data,
      (node) => node["organizationId"] === currentId
    );

    // Calculate particpant sum of investment
    // Group dataset by project id
    const uniqByProject = _.uniqBy(currentNode, "projectId");

    // Filter projects by current filters
    const filteredByProject = _.filter(uniqByProject, (project) => {
      return filteredProjects.length > 0
        ? _.includes(filteredProjects, project["projectId"])
        : true;
    });

    // TODO
    // 1. Get simple properties
    // 1.1 Name

    // if organizationName is undefined, stop function
    if (currentNode[0] === undefined) {
      return;
    }

    const organizationName = currentNode[0]["organizationName"];

    // if organizationName is undefined, stop function
    if (organizationName === undefined) {
      return;
    }

    // 1.2 Provincia
    const participantNuts3Name = currentNode[0]["participantNuts3Name"];
    // 1.3 Actor type
    const participantType = currentNode[0]["participantType"];
    // Aggregate information to show in modal info window
    // 2.1 Number of projects
    const uniqProjects = filteredByProject.length;
    // const totalFunding = _.sumBy(uniqByProject, "investment");

    const participantProjects = _.filter(participants, (participant) => {
      return participant["organizationId"] === currentId;
    });

    const filteredParticipantProjects = _.filter(
      participantProjects,
      (project) => {
        return filteredProjects.length > 0
          ? _.includes(filteredProjects, project["projectId"])
          : true;
      }
    );

    const totalFunding = _.sumBy(filteredParticipantProjects, "investment");
    const nodeInformation = {
      id: currentId,
      name: organizationName,
      nuts3Name: participantNuts3Name,
      totalProjects: uniqProjects,
      totalFunding: totalFunding,
      participantType: participantType,
    };
    return nodeInformation;
  };

  setFilter = ({ organizationId = null }) => {
    const allParticipants = this.props.filters.populate["participants"].options;
    const currentParticipant = _.find(
      allParticipants,
      (participant) => participant["organizationId"] === organizationId
    );

    let participantData = {};

    participantData["filter"] = [currentParticipant];
    participantData["type"] = "participants";

    this.props.setSearch({
      participants: participantData,
    });
    this.props.setSelectedStatisticTab("statistic-projects");
    this.props.setActiveTool("");

    setTimeout(() => {
      d3.selectAll(".statistic-module-nav .nav-link").classed("active", false);
      d3.select(
        ".statistic-module-nav .col .nav .nav-item:last-child .nav-link"
      ).classed("active", true);
      d3.select("#statistic-tabs-tabpane-statistic-projects").classed(
        "fade",
        false
      );
      d3.select("#statistic-tabs-tabpane-statistic-projects").style(
        "display",
        "block"
      );
    }, 100);
  };

  render() {
    const { loading, nodeInformation } = this.state;
    const { tools } = this.props;
    const isToolsActive = tools["active"] !== "" ? true : false;

    // console.log("PROJECT_INFO_MODAL -> RENDER");
    // console.log("STATE", this.state);
    // console.log("PROPS", this.props);

    // If node information is not available, return null
    if (nodeInformation === undefined) {
      return null;
    }

    return (
      <Fragment>
        {loading ? (
          <p>loading info</p>
        ) : (
          <div
            className="informative-panel"
            style={{
              right: isToolsActive
                ? CONFIG.APP.LAYOUT.PANEL_WIDTH +
                  CONFIG.APP.LAYOUT.BUTTON_SIZE +
                  CONFIG.APP.LAYOUT.SPACER * 2 +
                  "px"
                : CONFIG.APP.LAYOUT.BUTTON_SIZE +
                  CONFIG.APP.LAYOUT.SPACER * 2 +
                  "px",
            }}
          >
            <div className="informative-panel-body container">
              <button
                className="btn btn-close-window"
                onClick={this.props.closeNodeInfo}
              >
                <FaTimesCircle></FaTimesCircle>
              </button>
              <div className="row">
                <h3>{nodeInformation["name"]}</h3>
                <h4 className="text-muted">
                  {getTranslation({
                    lang: this.props.language,
                    key:
                      "FILTER_PANEL.FILTERS.ACTIVITY_TYPE.OPTIONS." +
                      nodeInformation["participantType"],
                  })}
                </h4>
              </div>

              <div className="row">
                <div className="col col-6 pe-0">
                  <div className="indicator">
                    <h6 className="indicator-label">
                      {getTranslation({
                        lang: this.props.language,
                        key: "INFO_WINDOWS.ENTITY.NUMBER_OF_PROJECTS",
                      })}
                    </h6>
                    <h5>
                      {FORMAT_THOUSANDS(nodeInformation["totalProjects"])}
                    </h5>
                  </div>
                </div>
                <div className="col col-6 text-end ps-0">
                  <div className="indicator">
                    <h6 className="indicator-label">
                      {getTranslation({
                        lang: this.props.language,
                        key: "INFO_WINDOWS.ENTITY.TOTAL_FUNDING",
                      })}
                    </h6>
                    <h5>{FORMAT_EURO(nodeInformation["totalFunding"])}</h5>
                  </div>
                </div>
              </div>
            </div>

            <footer className="informative-panel-footer">
              <button
                className="btn btn-primary ms-auto"
                onClick={() =>
                  this.setFilter({ organizationId: nodeInformation["id"] })
                }
              >
                {getTranslation({
                  lang: this.props.language,
                  key:
                    nodeInformation["totalProjects"] > 1
                      ? "INFO_WINDOWS.ENTITY.BUTTON_PROJECTS"
                      : "INFO_WINDOWS.ENTITY.BUTTON_PROJECT",
                })}
              </button>
            </footer>
          </div>
        )}
      </Fragment>
    );
  }
}

export default NodePanelInformation;
