import React, { Component, Fragment } from "react";
import "./index.scss";

import _ from "lodash";
import { StatisticsBars } from "../StatisticsBars";
import BarsSender from "../BarsSender";

import { getTranslation } from "../../common/translations";

class StatisticsTabPartners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      optionsNumbers: [
        {
          label: getTranslation({
            lang: this.props.language,
            key: "STATISTICS.EXTERNAL_PARTNERS.RADIO_OPTIONS.COUNT",
          }),
          value: "count",
        },
        {
          label: getTranslation({
            lang: this.props.language,
            key: "STATISTICS.EXTERNAL_PARTNERS.RADIO_OPTIONS.FUNDING",
          }),
          value: "funding",
        },
      ],
      selectedOption: "count",
      interactionLevel: "level1",
      interactionLabel: "",
      clickedCountry: "",
      clickedNUTS2: "",
      dataSource: "exterior",
      downloadData: [],
    };
    this.leftChartContainer = React.createRef();
    this.rightChartContainer = React.createRef();
    this.resizeObserver = null;
  }

  componentDidMount() {
    const chartData = this.prepareDataForCharts({
      dataCountries:
        this.props.statistics.data[this.state.dataSource]["countries"],
      dataParticipants:
        this.props.statistics.data[this.state.dataSource]["participants"],
      level: "level1",
    });

    const downloadData = _.flatMap(
      this.props.statistics.data[this.state.dataSource]["countries"]
    );

    // let receptorData = [];
    if (this.state.interactionLabel !== "") {
      if (this.state.interactionLevel === "level1") {
        // Filter by countryName
        chartData["participants"][this.state.selectedOption] = _.filter(
          chartData["participants"][this.state.selectedOption],
          (obj) => obj["level1"] === this.state.interactionLabel
        );
      }
    }
    // else {
    //   chartData["participants"][selectedOption] = chartData["participants"][selectedOption];
    // }

    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        // Do something on resize, access the element on `entry.target`
        const className = entry["target"]["className"];
        // Save the width and height somewhere
        if (className === "col col-5") {
          this.setState({
            leftChartWidth: entry["contentRect"]["width"],
            leftChartHeight: entry["contentRect"]["height"],
          });
        } else if (className === "col col-7") {
          this.setState({
            rightChartWidth: entry["contentRect"]["width"],
            rightChartHeight: entry["contentRect"]["height"],
          });
        }
      }
      this.setState({
        loading: false,
        chartData: chartData,
        downloadData: downloadData,
      });
    });

    this.resizeObserver.observe(this.leftChartContainer.current);
    this.resizeObserver.observe(this.rightChartContainer.current);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      _.isEqual(
        this.props["filters"]["filteredProjects"],
        prevProps["filters"]["filteredProjects"]
      ) &&
      this.props["language"] === prevProps["language"] &&
      prevState["interactionLabel"] === this.state["interactionLabel"] &&
      prevState["clikedLabel"] === this.state["clikedLabel"] &&
      prevState["clikedCountry"] === this.state["clikedCountry"] &&
      prevState["clikedNUTS2"] === this.state["clikedNUTS2"] &&
      prevState["interactionLevel"] === this.state["interactionLevel"] &&
      prevState["dataSource"] === this.state["dataSource"]
    ) {
      // console.log("INTERACTION ->>>> DO NOTHING");
      return false;
    } else {
      // console.log("INTERACTION ->>>> UPDATE");

      let chartData;
      let downloadData = [];

      // LEVEL 2 -> COUNTRY
      if (this.state.clickedCountry !== "" && this.state.clickedNUTS2 === "") {
        // Prepare participants
        let participantsArray = Object.values(
          this.props.statistics.data[this.state.dataSource]["participants"]
        );
        // FILTER PARTICIPANTS TO GET CURRENT COUNTRY
        participantsArray = _.filter(participantsArray, (values) => {
          return values[0]["countryName"] === this.state.clickedCountry;
        });

        chartData = this.prepareDataForCharts({
          dataCountries: _.groupBy(
            this.props.statistics.data[this.state.dataSource]["countries"][
              this.state.clickedCountry
            ],
            "participantNuts2Name"
          ),
          dataParticipants: participantsArray,
          level: "level2",
        });

        downloadData =
          this.props.statistics.data[this.state.dataSource]["countries"][
            this.state.clickedCountry
          ];
      }
      // LEVEL 3 -> NUTS2
      else if (
        this.state.clickedCountry !== "" &&
        this.state.clickedNUTS2 !== ""
      ) {
        // Prepare participants
        let participantsArray = Object.values(
          this.props.statistics.data[this.state.dataSource]["participants"]
        );
        // FILTER PARTICIPANTS TO GET CURRENT COUNTRY
        participantsArray = _.filter(participantsArray, (values) => {
          return values[0]["participantNuts2Name"] === this.state.clickedNUTS2;
        });

        // Filter by country = clickedCountry
        // Filter by NUTS2 = participantNuts2Name

        let currentDataCountries = _.filter(
          this.props.statistics.data[this.state.dataSource]["countries"][
            this.state.clickedCountry
          ],
          (participant) => {
            return (
              participant["participantNuts2Name"] === this.state.clickedNUTS2
            );
          }
        );

        currentDataCountries = _.groupBy(
          currentDataCountries,
          "participantNuts3Name"
        );

        chartData = this.prepareDataForCharts({
          dataCountries: currentDataCountries,
          dataParticipants: participantsArray,
          level: "level3",
        });

        downloadData = _.flatMap(currentDataCountries);
      }
      // LEVEL 1 -> ALL
      else {
        // console.log("NO CLICKED LABEL -> INTERACTION LEVEL1");
        // This is level1
        // countryName
        chartData = this.prepareDataForCharts({
          dataCountries:
            this.props.statistics.data[this.state.dataSource]["countries"],
          dataParticipants:
            this.props.statistics.data[this.state.dataSource]["participants"],
          level: "level1",
        });
        downloadData = _.flatMap(
          this.props.statistics.data[this.state.dataSource]["countries"]
        );
      }

      // let receptorData = [];
      if (this.state.interactionLabel !== "") {
        chartData["participants"][this.state.selectedOption] = _.uniqBy(
          _.filter(
            chartData["participants"][this.state.selectedOption],
            (obj) =>
              obj[this.state.interactionLevel] === this.state.interactionLabel
          ),
          "label"
        );

        // console.log(
        //   "LEVEL 1 ->>>>>",
        //   chartData["participants"][this.state.selectedOption]
        // );
      }

      this.setState({
        chartData: chartData,
        downloadData: downloadData,
      });
      // return false
    }
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  prepareDataForCharts = ({
    dataCountries = null, // projects grouped by country
    dataParticipants = null, // projects grouped by country
    level = "",
  }) => {
    let countriesProjectsChartData = [];
    let countriesFundingChartData = [];

    // Translate country names

    // For countries count and sum
    Object.entries(dataCountries).forEach(function ([key, values]) {
      // Detect level
      // countryName, participantNuts2Name, participantNuts3Name
      if (level === "level1") {
        countriesProjectsChartData.push({
          label: values[0]["countryName"],
          value: values.length,
        });
        countriesFundingChartData.push({
          label: values[0]["countryName"],
          value: _.sumBy(values, "investment"),
        });
      }
      if (level === "level2") {
        countriesProjectsChartData.push({
          label: values[0]["participantNuts2Name"],
          value: values.length,
        });
        countriesFundingChartData.push({
          label: values[0]["participantNuts2Name"],
          value: _.sumBy(values, "investment"),
        });
      }
      if (level === "level3") {
        countriesProjectsChartData.push({
          label: values[0]["participantNuts3Name"],
          value: values.length,
        });
        countriesFundingChartData.push({
          label: values[0]["participantNuts3Name"],
          value: _.sumBy(values, "investment"),
        });
      }
    });

    // Sort / Order values
    countriesProjectsChartData = _.orderBy(
      countriesProjectsChartData,
      ["value"],
      ["desc"]
    );

    countriesFundingChartData = _.orderBy(
      countriesFundingChartData,
      ["value"],
      ["desc"]
    );

    let participantsProjectsChartData = [];
    let participantsFundingChartData = [];

    // For participants count and sum
    Object.entries(dataParticipants).forEach(function ([key, values]) {
      participantsProjectsChartData.push({
        label: values[0]["organizationName"],
        level1: values[0]["countryName"],
        level2: values[0]["participantNuts2Name"],
        level3: values[0]["participantNuts3Name"],
        value: values.length,
      });
      participantsFundingChartData.push({
        label: values[0]["organizationName"],
        level1: values[0]["countryName"],
        level2: values[0]["participantNuts2Name"],
        level3: values[0]["participantNuts3Name"],
        value: _.sumBy(values, "investment"),
      });
    });

    // Sort / Order values
    participantsProjectsChartData = _.orderBy(
      participantsProjectsChartData,
      ["value"],
      ["desc"]
    );

    participantsFundingChartData = _.orderBy(
      participantsFundingChartData,
      ["value"],
      ["desc"]
    );

    const result = {
      countries: {
        count: countriesProjectsChartData,
        funding: countriesFundingChartData,
      },
      participants: {
        count: participantsProjectsChartData,
        funding: participantsFundingChartData,
      },
    };

    return result;
  };

  handleRadioOption = (event) => {
    this.setState({
      selectedOption: event["target"]["value"],
    });
  };

  handleHoverInteraction = ({ label = null, value = null }) => {
    // const level = this.state.interactionLevel;
    this.setState({
      interactionLabel: label,
    });
  };

  handleOutInteraction = () => {
    this.setState({
      interactionLabel: "",
    });
  };

  handleClickInteraction = ({ label = null, value = null }) => {
    // console.log("CLICKED LABEL -> INTERACTION", label, value);
    let currentLevel = this.state.interactionLevel;
    // let currentCountry = { ... };
    // let currentNUTS2 = { ... };
    if (currentLevel === "level1") {
      currentLevel = "level2";
    } else if (currentLevel === "level2") {
      currentLevel = "level3";
    } else {
      currentLevel = "level2";
    }

    // currentLevel = (currentLevel === "level1") ? "level2" : (currentLevel === "level2") ? "level3" : "level2";
    // currentLevel = "level2";
    this.setState({
      clickedCountry:
        currentLevel === "level2" ? label : this.state.clickedCountry,
      clickedNUTS2: currentLevel === "level3" ? label : this.state.clickedNUTS2,
      interactionLevel: currentLevel,
      interactionLabel: "",
    });
  };

  resetCountry = () => {
    this.setState({
      clickedCountry: "",
      clickedNUTS2: "",
      interactionLevel: "level1",
    });
  };

  resetNUTS2 = () => {
    this.setState({
      clickedCountry: this.state.clickedCountry,
      clickedNUTS2: "",
      interactionLevel: "level2",
    });
  };

  handleCoordinators = (event) => {
    this.setState({
      dataSource:
        event["target"]["checked"] === true ? "coordinators" : "exterior",
    });
  };

  render() {
    const {
      loading,
      leftChartWidth,
      leftChartHeight,
      rightChartWidth,
      rightChartHeight,
      chartData,
      downloadData,
      optionsNumbers,
      selectedOption,
      interactionLabel,
      interactionLevel,
      // senderActivate,
      clickedCountry,
      clickedNUTS2,
      // dataSource, // for coordinators selector
    } = this.state;

    const { language } = this.props;

    // console.log("**** DOWNLOAD DATA", downloadData);

    return (
      <Fragment>
        <div className="container-fluid tab-container tab-container-entitats">
          <div className="row subheader">
            <div className="col col-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="coordinators"
                  // checked={dataSource === "coordinators" ? true : false}
                  onChange={this.handleCoordinators}
                />
                <label className="form-check-label" htmlFor="coordinators">
                  {getTranslation({
                    lang: language,
                    key: "STATISTICS.EXTERNAL_PARTNERS.COORDINATORS_CHECKBOX",
                  })}
                </label>
              </div>
            </div>
            <div className="col col-auto ms-auto me-auto">
              <div
                className="btn-group btn-group-sm statistics-group"
                role="group"
                aria-label=""
              >
                {_.map(optionsNumbers, (option, index) => {
                  return (
                    <span key={"option" + index}>
                      <input
                        className="btn-check"
                        type="radio"
                        name="inlineRadioOptions"
                        id={"inlineRadio" + option["value"]}
                        autoComplete="off"
                        value={option["value"]}
                        checked={
                          selectedOption === option["value"] ? true : false
                        }
                        onChange={this.handleRadioOption}
                      />
                      <label
                        className="btn btn-secondary"
                        htmlFor={"inlineRadio" + option["value"]}
                      >
                        {option["label"]}
                      </label>
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="col col-4 ms-auto">
              <button
                className="btn btn-link p-0 float-end"
                onClick={() =>
                  this.props.dwnldPartnersExcel({
                    partnersdata: downloadData,
                    fileName: "socis-exterior-ris3mcat",
                  })
                }
              >
                <small>
                  {getTranslation({
                    lang: language,
                    key: "STATISTICS.EXTERNAL_PARTNERS.DOWNLOAD_EXCEL",
                  })}
                </small>
              </button>
            </div>
          </div>
          {loading ? (
            <p>Carregant</p>
          ) : (
            <header className="row header">
              <div className="col col-5">
                <h4>
                  {selectedOption === "count"
                    ? getTranslation({
                        lang: language,
                        key: "STATISTICS.EXTERNAL_PARTNERS.TITLE_1_COUNT",
                      })
                    : getTranslation({
                        lang: language,
                        key: "STATISTICS.EXTERNAL_PARTNERS.TITLE_1_FUNDING",
                      })}
                  {clickedCountry !== "" && (
                    <div className="d-inline-block ms-2">
                      <span
                        className="bars-breadcrumb-link"
                        onClick={this.resetCountry}
                      >
                        {clickedCountry}
                      </span>
                      {clickedNUTS2 === "" ? (
                        <strong>
                          {" "}
                          /{" "}
                          {getTranslation({
                            lang: language,
                            key: "STATISTICS.EXTERNAL_PARTNERS.NUTS2_TITLE",
                          })}
                        </strong>
                      ) : (
                        <div className="d-inline-block">
                          <span>&nbsp;/&nbsp;</span>
                          <span
                            className="bars-breadcrumb-link"
                            onClick={this.resetNUTS2}
                          >
                            {clickedNUTS2}
                          </span>
                          <strong>
                            {" "}
                            /{" "}
                            {getTranslation({
                              lang: language,
                              key: "STATISTICS.EXTERNAL_PARTNERS.NUTS3_TITLE",
                            })}
                          </strong>
                        </div>
                      )}
                    </div>
                  )}
                </h4>

                {/* {clickedCountry === "" && clickedNUTS2 === "" && <h4></h4>} */}
              </div>
              <div className="col col-7">
                <h4>
                  {selectedOption === "count"
                    ? getTranslation({
                        lang: language,
                        key: "STATISTICS.EXTERNAL_PARTNERS.TITLE_2_COUNT",
                      })
                    : getTranslation({
                        lang: language,
                        key: "STATISTICS.EXTERNAL_PARTNERS.TITLE_2_FUNDING",
                      })}

                  {interactionLabel !== "" && (
                    <small> ({interactionLabel})</small>
                  )}
                </h4>
              </div>
            </header>
          )}
          <div className="row body w-subheader">
            <div className="col col-5" ref={this.leftChartContainer}>
              {loading ? (
                <p>Loading</p>
              ) : (
                // Need to add a wrapper component to manage updating
                <BarsSender
                  width={leftChartWidth}
                  height={leftChartHeight}
                  chartData={chartData["countries"][selectedOption]}
                  type={selectedOption === "count" ? "number" : "funding"}
                  interaction={"sender"}
                  interactionLabel={interactionLabel}
                  clickedCountry={clickedCountry}
                  handleHoverInteraction={this.handleHoverInteraction}
                  handleOutInteraction={this.handleOutInteraction}
                  handleClickInteraction={this.handleClickInteraction}
                  interactionLevel={interactionLevel}
                  labelsWidth={0.3}
                ></BarsSender>
              )}
            </div>
            <div className="col col-7" ref={this.rightChartContainer}>
              {loading ? (
                <p>Loading</p>
              ) : (
                <StatisticsBars
                  width={rightChartWidth}
                  height={rightChartHeight}
                  chartData={chartData["participants"][selectedOption].slice(
                    0,
                    15
                  )}
                  type={selectedOption === "count" ? "number" : "funding"}
                  interaction={"receptor"}
                ></StatisticsBars>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default StatisticsTabPartners;
