import _ from "lodash";
import * as d3 from "d3";
import * as louvain from "jlouvain"

// TODO
// - add a function to get the filter by keywords with logical operator
// const version = "0.0.2s",
//   LOGICAL_OR = "OR",
//   LOGICAL_AND = "AND";

// PRIVATE FUNCTIONS

/**
 * Get the filter by keywords
 */
export const getKeywordsFilter = function () {
  const filtersMap = d3.map();
  return _.find(filtersMap.entries(), function (filter) {
    return filter.key === "keywords";
  });
};

/**
 * Get the filter by year extent
 */
export const getYearExtentFilter = function () {
  const filtersMap = d3.map();
  return _.find(filtersMap.entries(), function (filter) {
    return filter.value[0] instanceof Date;
  });
};

/**
 * Get all categorical filters, excluding
 * the filter by keywords and the filter
 * by year extent
 */
export const getCategoricalFilters = function () {
  const filtersMap = d3.map();
  return _.filter(filtersMap.entries(), function (filter) {
    return !(filter.value[0] instanceof Date) && !(filter.key === "keywords");
  });
};

/**
 * count all the values of all categorical filters, plus
 * the keywords and year filters in case they exist
 */

export const countFilters = function () {
  return (
    _.reduce(
      getCategoricalFilters(),
      function (result, filter) {
        return result + filter.value.length;
      },
      0
    ) +
    (getKeywordsFilter() ? getKeywordsFilter().value.length : 0) +
    (getYearExtentFilter() ? 1 : 0)
  );
};

export const existsFilters = function () {
console.log("existsFilters -> filtersMap", d3.map());
  const filtersMap = d3.map();

  return _.flatten(filtersMap.values()).length > 0;
};

// export const filterData = function ({ myData = null, myConfig = null }) {
//   const filtersMap = d3.map();
//   var data = myData.slice(),
//     temporalFilter = _.find(filtersMap.entries(), function (filter) {
//       return filter.value[0] instanceof Date;
//     }),
//     categoryFilters = _.filter(filtersMap.entries(), function (filter) {
//       return !(filter.value[0] instanceof Date);
//     });

// //   if (categoryFilters.length !== 0)
// //     categoryFilters.forEach(function (filter) {
// //       if (filter.key === myConfig.entity.keywords.field) {
// //         // filtering by keywords can be done by a single value or
// //         // by multiple values. Also, this filter contains the
// //         // logical operator for multiple values (key1 and key2 and
// //         // key3) or (key1 or key2 or key3).
// //         data = _.filter(data, function (obj) {
// //           if (obj[filter.key] && filter.value.logicalOperator) {
// //             // testing 1: concat keywords to search in
// //             // var extractedKeywords = obj[filter.key].split(myConfig.entity.keywords.sep),
// //             // abstractKeywords = obj['projectAbstract'].split(' ');
// //             // var keywords = extractedKeywords.concat(abstractKeywords), // testing 1: how to add search by keyword spliting text by blankspace

// //             var keywords = obj[filter.key].split(myConfig.entity.keywords.sep),
// //               // check whether we filter by all keywords or by some keywords
// //               filterLogic =
// //                 filter.value.logicalOperator == LOGICAL_OR ? _.some : _.every;

// //             var searchSuccessfull;

// //             return filterLogic(
// //               filter.value, // keywords entered by the user to filter
// //               function (keyWordToSearch) {
// //                 return (
// //                   keywords.filter(function (keyword) {
// //                     var cleanKeyword = keyword.replace(/[\W_]+/g, " "),
// //                       cleanKeyWordToSearch = keyWordToSearch.replace(
// //                         /[\W_]+/g,
// //                         " "
// //                       );

// //                     // testing 2: Searching in abstract and title
// //                     searchSuccessfull =
// //                       obj["projectAbstract"].search(cleanKeyWordToSearch) !==
// //                         -1 ||
// //                       obj["projectTitle"].search(cleanKeyWordToSearch) !== -1
// //                         ? true
// //                         : false;

// //                     // testing 2: Check also in keywords
// //                     searchSuccessfull =
// //                       (!searchSuccessfull &&
// //                         cleanKeyword.includes(cleanKeyWordToSearch)) ||
// //                       searchSuccessfull
// //                         ? true
// //                         : false;
// //                     return searchSuccessfull; //cleanKeyword.includes(cleanKeyWordToSearch);
// //                   }).length > 0
// //                 );
// //               }
// //             );
// //           } else return false;
// //         });
// //       } else {
// //         var currentFilteringValues = filter.value;
// //         data = _.filter(data, function (obj) {
// //           return _.includes(currentFilteringValues, obj[filter.key]);
// //         });
// //       }
// //     });

// //   if (temporalFilter)
// //     data = _.filter(data, function (dataRow) {
// //       var temporalKeys = temporalFilter.key.split(",");
// //       return (
// //         dataRow[temporalKeys[0]] <= temporalFilter.value[1] &&
// //         dataRow[temporalKeys[1]] >= temporalFilter.value[0]
// //       );
// //     });

//   return data;
// };

export const filters = {
  /**
   * Add an entry with its value to the filtersMap
   * @param {*} key
   * @param {*} value
   */
  update: function (key, value) {
    const filtersMap = d3.map();
    // append the new filter key and values
    filtersMap.set(key, Array.isArray(value) ? value : [].concat(value));

    // drop keys with empty values
    var emptyKeys = _.map(
      _.filter(filtersMap.entries(), function (f) {
        return f.value.length === 0;
      }),
      "key"
    );

    emptyKeys.forEach(function (key) {
      filtersMap.remove(key);
    });
  },

  /**
   * Deletes an entry from the filtersMap
   * @param {*} key
   */
  drop: function (key) {
    const filtersMap = d3.map();
    filtersMap.remove(key);
  },

  /**
   * Resets all the filters
   */
  resetAll: function () {
    const filtersMap = d3.map();
    filtersMap.clear();
  },

  /**
   * Gets all the filters
   */
  getAll: function () {
    const filtersMap = d3.map();
    return _([
      _.find(filtersMap.entries(), function (filter) {
        return filter.value[0] instanceof Date;
      }),
      _.filter(filtersMap.entries(), function (filter) {
        return !(filter.value[0] instanceof Date);
      }),
    ])
      .flatten()
      .compact()
      .value();
  },
  getKeywordsFilter: getKeywordsFilter,
  getYearExtentFilter: getYearExtentFilter,
  getCategoricalFilters: getCategoricalFilters,
  countFilters: countFilters,
};

export const graphToJSON = function (G) {

  var graphData = {},
    louvainLinks = _.map(G.edges(), function (edge) {
      return {
        source: edge[0],
        target: edge[1],
        weight: edge[2]
      };
    }),
    community = louvain.jLouvain().nodes(G.nodes()).edges(louvainLinks)();

  graphData.nodes = _.map(G.nodes(true), function (nodeObj) {

    // console.log("NODEOBJK", nodeObj)

    /* if community is empty, it means that no communities have found, i.e., the network is disconnected.
    In these case, community 999 is returned, representing in a connected graph an isolated node. 
    */
    nodeObj[1].group = (community ? community[nodeObj[0]] : 999);
    return {
      id: nodeObj[0],      // nodename
      label: nodeObj[1]['label'], // nodelabel
      values: nodeObj[1]  // node data
    }
  });

  graphData.links = _.map(G.edges(true), function (edge) {
    return {
      'source': _.find(graphData.nodes, function (obj) { return obj.id === edge[0]; }),
      'target': _.find(graphData.nodes, function (obj) { return obj.id === edge[1]; }),
      'weight': 1,
      'values': edge[2]
    };
  })
  return graphData;
}
