import React, { Component, Fragment } from "react";
import _ from "lodash";
import "./index.scss";

class FilterCheckboxes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      networkData: this.props["data"]["networkData"],
    };
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  handleSelected = (event) => {
    this.props.storeCheckboxesFilter({
      type: this.props.entity,
      values: [event["target"]["value"]],
    });
  };

  handleSelectedEntireBlock = () => {
    const allValues = _.map(this.props["options"], (option) =>
      option["value"].toString()
    );
    this.props.storeCheckboxesFilter({
      type: this.props.entity,
      values: allValues,
    });
  };

  // TODO: Find the way to show how filters results apply to each option
  getOptionLength = (option, entity) => {
    // console.log("OPTION", option);
    // console.log("ENTITY", entity);
    // console.log("THIS PROPS", this.props);

    let result;

    let data = _.groupBy(
      this.props["data"]["networkData"],
      (d) => d["projectId"]
    );
    data = Object.values(data);
    data = _.map(data, (d) => d[0]);

    // console.log("DATA", data);

    switch (entity) {
      case "topics":
        const groupedData = _.groupBy(data, "projectTopic");
        // console.log("GROUPED DATA", groupedData);
        result = groupedData[option["value"]] ? groupedData[option["value"]].length : 0;
        // result = 10;
        break;

      default:
        result = 10;
        break;
    }

    return result;
  };

  render() {
    const { loading } = this.state;
    const { options, entity, selectedFilters, columns } = this.props;

    const found = _.find(selectedFilters, (filter) => {
      return filter["type"] === entity;
    });

    return (
      <Fragment>
        {loading ? (
          <p>Carregant</p>
        ) : (
          <div className="filter-container-checkbox container">
            <div className="row my-3">
              <div className="col">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id={`all-${entity}`}
                    onChange={this.handleSelectedEntireBlock}
                    checked={
                      found && found["values"].length === options.length
                        ? true
                        : false
                    }
                  />
                  <label className="form-check-label" htmlFor={`all-${entity}`}>
                    {this.props["textAll"]}
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              {_.map(options, (option, index) => {
                return (
                  <div key={index} className={`col ${columns === 2 ? 'col-6' : 'col-12'} ${option["value"] === "CERCA" ? "ps-5" : ""}`}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={option["value"]}
                        id={`option-${entity}-${index}`}
                        onChange={this.handleSelected}
                        checked={
                          found &&
                          found["values"].includes(option["value"].toString())
                            ? true
                            : false
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`option-${entity}-${index}`}
                      >
                        {option["label"]} 
                        {/* <small>[{this.getOptionLength(option, entity)}]</small> */}
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default FilterCheckboxes;
