import React, { Component, Fragment } from "react";
import _ from "lodash";
import * as d3 from "d3";

import { CONFIG, CLUSTERS_TO_MOVE } from "../../common/constants";
import { DotsCollision } from "./SVG";

import "./index.scss";

class TopicsCanvas extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // Call any function in provider
    document.addEventListener("keydown", (e) => {
      if (e.key === "Control") document.body.style.cursor = "crosshair";
    });
    document.addEventListener("keyup", (e) => {
      if (e.key === "Control") document.body.style.cursor = "unset";
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      // _.isEqual(this.props.data, nextProps.data) &&
      // _.isEqual(this.props.filters.selected, nextProps.filters.selected) &&
      // _.isEqual(
      //   this.props.filters.filteredProjects,
      //   nextProps.filters.filteredProjects
      // ) &&
      // _.isEqual(
      //   this.props.filters.filteredTopics,
      //   nextProps.filters.filteredTopics
      // ) &&
      // _.isEqual(this.props.data.topicsPoints, nextProps.data.topicsPoints) &&
      // _.isEqual(this.props.clickedDot, nextProps.clickedDot) &&
      // this.props["zoom"]["topics"] === nextProps["zoom"]["topics"] &&
      this.props["canvasWidth"] === nextProps["canvasWidth"] &&
      this.props["canvasHeight"] === nextProps["canvasHeight"] &&
      this.props["tabs"]["active"] === nextProps["tabs"]["active"] &&
      this.props["language"] === nextProps["language"]
    ) {
      // console.log("TOPICS_CANVAS -> DO NOTHING");
      return false;
    } else {
      // console.log("TOPICS_CANVAS -> UPDATE");
      if (nextProps["tabs"]["active"] === "topics") {
        if (
          this.props["canvasWidth"] !== nextProps["canvasWidth"] ||
          !_.isEqual(this.props.data, nextProps.data)
        ) {
          nextProps.setTopicPoints([], []);
          return true;
        } else {
          if (this.props["language"] !== nextProps["language"]) {
            // nextProps.setTopicPoints([], []);
            return true;
          } else {
            return false;
          }
        }
        // this.props.setCanvasSize(nextProps['canvasWidth'], nextProps['canvasHeight']);
      } else {
        if (this.props["language"] !== nextProps["language"]) {
          // nextProps.setTopicPoints([], []);
          return true;
        } else {
          // console.log("TOPICS_CANVAS -> DO NOTHING");
          return false;
        }
      }
    }
  }

  render() {
    const { canvasWidth, canvasHeight } = this.props;

    const calculatedHeight = canvasHeight - CONFIG.APP.LAYOUT.MAIN_TABS_HEIGHT;
    const calculatedWidth = canvasWidth;
    const colorValues = CONFIG.TOPICS.LAYOUT.COLOR_VALUES;

    const dotsDataXExtent = d3.extent(this.props.data.topics.data, (d) => d.x);
    const dotsDataYExtent = d3.extent(this.props.data.topics.data, (d) => d.y);

    // Apply scale to projects and labels
    const xScale = d3
        .scaleLinear()
        .range([
          CONFIG.TOPICS.LAYOUT.MARGIN.LEFT,
          canvasWidth - CONFIG.TOPICS.LAYOUT.MARGIN.RIGHT,
        ])
        .domain(dotsDataXExtent)
        .nice(),
      yScale = d3
        .scaleLinear()
        .range([
          CONFIG.TOPICS.LAYOUT.MARGIN.TOP,
          calculatedHeight - CONFIG.TOPICS.LAYOUT.MARGIN.BOTTOM,
        ])
        .domain(dotsDataYExtent)
        .nice(),
      dotSizeScale = CONFIG.APP.PROJECT_RELATED
        ? d3
            .scaleSqrt()
            .range([3, 11])
            .domain(
              d3.extent(this.props.data.topics.data, function (datum) {
                return datum.funding;
              })
            )
        : () => {
            return 2;
          };

    const dotsData = this.props.data.topics.data.map((d) => {
      return {
        ...d,
        x: xScale(d.x),
        y: yScale(d.y),
        r: dotSizeScale(d["funding"]),
        color: d3.interpolateRgbBasis(colorValues)(
          this.props.colorScale(d["cluster"])
        ),
      };
    });

    // Calculate label displacement as a % of the width/height of the canvas
    const labelDisplacementX = (value) => {
      return (
        (value / (dotsDataXExtent[1] - dotsDataXExtent[0])) *
        (canvasWidth -
          CONFIG.TOPICS.LAYOUT.MARGIN.LEFT -
          CONFIG.TOPICS.LAYOUT.MARGIN.RIGHT)
      );
    };
    const labelDisplacementY = (value) => {
      return (
        (value / (dotsDataYExtent[1] - dotsDataYExtent[0])) *
        (calculatedHeight -
          CONFIG.TOPICS.LAYOUT.MARGIN.TOP -
          CONFIG.TOPICS.LAYOUT.MARGIN.BOTTOM)
      );
    };
    const labelsData = this.props.data.topics.topics.map((label) => {
      const found = _.find(CLUSTERS_TO_MOVE, (d) => d.id === label["cluster"]);
      return {
        ...label,
        x: xScale(label.x) + (found ? labelDisplacementX(found.x) : 0),
        y: yScale(label.y) + (found ? labelDisplacementY(found.y) : 0),
        color: d3.interpolateRgbBasis(colorValues)(
          this.props.colorScale(label["cluster"])
        ),
      };
    });

    return (
      <Fragment>
        <DotsCollision
          width={calculatedWidth}
          height={calculatedHeight}
          dotsData={dotsData}
          labelsData={labelsData}
          {...this.props}
        ></DotsCollision>
      </Fragment>
    );
  }
}

export default TopicsCanvas;
