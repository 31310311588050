import React, { Component } from "react";
import { Context } from "./Context";
import Cookies from "universal-cookie";

import _ from "lodash";
import * as d3 from "d3";
// import * as dl from "datalib";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import { zoomed } from "../components/TopicsCanvas/Zoom";

import ReactGA from "react-ga4";

import {
  // FROM SPARQL
  getProjectsInformation,
  getProjectsAmbits,
  getProjectsKeywords,
  getProjectsPartners,
  getProjectsSDGs,
  getMainDataDownload,
  // FROM LOCAL
  getProjectCoords,
  getTopics,
  getTopicsTranslations
} from "../data/DataService";

import {
  dotHandleOver,
  dotHandleOut,
  afterFiltersApply,
  labelHandleOver,
  loadingState,
  // handleFilteredLabels,
} from "./utils/interaction";

import { processPartnersData } from "./utils/partners";
import { calculateInvestment } from "./utils/processingInformation";

import {
  searchParticipantsKeywords,
  searchProjectsKeywords,
} from "./utils/search";

import {
  CONFIG,
  ACTIVITY_TYPES,
  ACTIVITY_TYPES_LABELS,
  PROGRAMS_LABELS,
  PROJECTS_BLACKLIST,
} from "../common/constants";

import { getTranslation } from "../common/translations";

export class Provider extends Component {
  constructor(props) {
    super(props);
    const cookies = new Cookies(null, { path: "/" });

    // Read lang cookie
    // if it exists get the values from the cookie
    // if not, get the values from the config file
    const langCookie = cookies.get("lang");
    const currentLang = langCookie ? langCookie : CONFIG.APP.LANGUAGE;


    this.state = {
      loading: true,
      language: currentLang,
      loadingText: getTranslation({
        lang: currentLang,
        key: "LOADING_MESSAGES.INITIAL_LOAD.STEP_1",
      }),
      interactionLoading: false,
      isDotInfoOpened: false,
      isNodeInfoOpened: false,
      tabs: {
        active: CONFIG.APP.ACTIVE_TAB,
      },
      statisticsTabs: {
        active: "",
        last: "statistic-ambits",
      },
      statistics: {
        data: {
          entitats: {},
        },
      },
      tools: {
        active: "",
      },
      data: {
        topics: {},
        network: {},
        topicsPoints: [],
      },
      filters: {
        populate: {},
        selected: [],
        filteredProjects: [],
        filteredTopics: [],
        searchedParticipantId: "",
        searchedParticipantIds: [],
        zero: false,
      },
      summary: {
        years: [],
        projects: 0,
        funding: 0,
        participants: 0,
        partners: 0,
      },
      sorting: {
        network: {
          field: "group",
          label: "Alta col·laboració",
        },
        topics: {
          labels: true,
          nodes: true,
          densities: true,
        },
      },
      zoomActive: false,
      zoom: {
        network: {
          scale: 1,
          translate: [0, 0],
        },
      },
      zoomTopics: "zoomInitial",
      visibilityLayers: {
        labels: true,
        nodes: true,
        densities: true,
      },
      canvasSize: {
        width: 0,
        height: 0,
      },
      colorScale: d3.scaleOrdinal(d3.schemeTableau10),
      initialStateData: {
        data: {},
        filters: {},
        summary: {},
        statistics: {},
      },
      initialStatisticsData: {},
      showCookiesBanner: cookies.get("acceptCookies") ? false : true,
      cookiesAccepted: false,
    };

    // FILTERING
    this.setFilteredProjects = this.setFilteredProjects.bind(this);
    this.setSearch = this.setSearch.bind(this);
    this.setFilters = this.setFilters.bind(this);
    this.resetAllFilters = this.resetAllFilters.bind(this);
    this.resetAllSearches = this.resetAllSearches.bind(this);
    this.setClickedAmbit = this.setClickedAmbit.bind(this);
    this.loadingInteraction = this.loadingInteraction.bind(this);
    // SORTING
    this.setNetworkSortingCriteria = this.setNetworkSortingCriteria.bind(this);
    // TABS
    this.setSelectedTab = this.setSelectedTab.bind(this);
    this.setSelectedStatisticTab = this.setSelectedStatisticTab.bind(this);
    this.setActiveTool = this.setActiveTool.bind(this);
    this.getFilteredProjects = this.getFilteredProjects.bind(this);
    this.getFilteredTopics = this.getFilteredTopics.bind(this);
    this.getTopicInformation = this.getTopicInformation.bind(this);
    this.getProjectInformation = this.getProjectInformation.bind(this);
    this.getClickedDot = this.getClickedDot.bind(this);
    this.getClickedLabel = this.getClickedLabel.bind(this);
    // TOPICS
    this.setTopicPoints = this.setTopicPoints.bind(this);
    // INTERACTION TOPICS
    this.dotHandleOver = dotHandleOver.bind(this);
    this.dotHandleOut = dotHandleOut.bind(this);
    this.labelHandleOver = labelHandleOver.bind(this);
    // TOPIC OPEN DOT INFO
    this.openDotInfo = this.openDotInfo.bind(this);
    this.closeDotInfo = this.closeDotInfo.bind(this);
    // TOPIC LABEL SELECTION
    this.selectTopicLabel = this.selectTopicLabel.bind(this);
    // NETWORK OPEN NODE INFO
    this.openNodeInfo = this.openNodeInfo.bind(this);
    this.closeNodeInfo = this.closeNodeInfo.bind(this);
    // NETWORK
    this.setPickedNodes = this.setPickedNodes.bind(this);
    // OPEN PROJECT WINDOW
    this.setClickedProject = this.setClickedProject.bind(this);
    // ZOOM
    this.setZoom = this.setZoom.bind(this);
    this.setTopicsZoom = this.setTopicsZoom.bind(this);
    // this.setZoomActive = this.setZoomActive.bind(this);
    this.setZoomInactive = this.setZoomInactive.bind(this);
    this.setCanvasSize = this.setCanvasSize.bind(this);
    this.setVisibilityLayers = this.setVisibilityLayers.bind(this);
    // DOWNLOAD
    this.dwnldExcel = this.dwnldExcel.bind(this);
    this.dwnldPartnersExcel = this.dwnldPartnersExcel.bind(this);
    // LANGUAGE
    this.setLang = this.setLang.bind(this);
    // COOKIES
    this.handleAcceptCookies = this.handleAcceptCookies.bind(this);
  }

  ///////////////////////////////////////////////////////////////////////////
  ////////// FETCHING DATA //////////////////////////////////////////////////

  async fetchProjectsCoords() {
    let projectCoords = await getProjectCoords();

    // Parse data to fit old format
    projectCoords = _.map(projectCoords, (project) => {
      return {
        id: project["pid"],
        cluster: project["clusterId"],
        x: project["px"],
        y: project["py"],
      };
    });

    let currentData = { ...this.state.data };
    let currentSummary = { ...this.state.summary };

    // Check with the blaklist to remove not pertinent projects
    projectCoords = _.filter(projectCoords, (project) => {
      return !_.includes(PROJECTS_BLACKLIST, project.id);
    });

    currentData.topics.data = projectCoords;

    // Summary
    currentSummary["projects"] = projectCoords.length;

    this.setState({
      data: currentData,
      summary: currentSummary,
      loadingText: getTranslation({
        lang: this.state.language,
        key: "LOADING_MESSAGES.INITIAL_LOAD.STEP_2",
      }),
    });

    return true;
  }

  async fetchTopicsTranslations() {
    let topicsTranslations = await getTopicsTranslations();

    // Sort the array of objects by the name of the topic for each language

    if (this.state.language === "ca") {
      topicsTranslations = _.orderBy(topicsTranslations, ["topic_label_ca"], ["asc"]);
    } else {
      topicsTranslations = _.orderBy(topicsTranslations, ["topic_label_en"], ["asc"]);
    }


    this.setState({
      topicsTranslations: topicsTranslations,
    });

    return true;
  }


  async fetchTopics() {
    let topics = await getTopics();

    let currentData = { ...this.state.data };
    let currentFilters = { ...this.state.filters };

    // Parse data to fit old format
    topics = _.map(topics, (topic) => {
      return {
        cluster: topic["clusterId"],
        label: topic["clusterLabel"],
        x: topic["clusterX"],
        y: topic["clusterY"],
      };
    });

    // Populate options for topics (Temàtiques) filter
    let topicOptions = _.uniqBy(
      _.map(topics, (topic) => {

        // find the translation for the topic in the state.topicsTranslations by the clusterId
        const foundTopic = _.find(this.state.topicsTranslations, (t) => {
          return t["topic_num"] === topic["cluster"];
        });

        return {
          // TRANSLATION_KEY: "TOPIC_" + topic["cluster"] + "_LABEL",
          label: foundTopic ? foundTopic["topic_label_" + this.state.language] : "N/A",
          value: topic["cluster"],
          sort: foundTopic ? foundTopic["topic_label_" + this.state.language] : "N/A",
        };
      }),
      "value"
    );

    topicOptions = _.orderBy(topicOptions, ["sort"], ["asc"]);

    currentFilters.populate["topics"] = {
      options: topicOptions,
    };
    currentData.topics.topics = topics;
    this.setState({
      data: currentData,
      filters: currentFilters,
      loadingText: getTranslation({
        lang: this.state.language,
        key: "LOADING_MESSAGES.INITIAL_LOAD.STEP_3",
      }),
    });
  }

  // ENRICHMENT OF PROJECTS DATA BY QUERYING TO SPARQL
  // 1. Funding + Title (unidimensional porperties to show in canvas (instead of detail))
  async fetchProjectsInformation() {
    // const projectChunks = _.chunk(array, CONFIG.APP.CHUNK_SIZE);
    // const promises = projectChunks.map(
    //   async (chunk) => await getProjectsInformation(chunk)
    // );

    const promises = await getProjectsInformation([]);

    let currentFilters = { ...this.state.filters };
    let currentSummary = { ...this.state.summary };
    const that = this;

    return Promise.all(promises).then(function (results) {
      const endpointResults = results;

      // ADD OR MODIFY PROPERTIES FOR MAIN PROJECTS DATA
      let currentData = { ...that.state.data };
      let projectsData = currentData.topics.data;

      // 1. funding
      projectsData = _.map(projectsData, function (item) {
        return _.assignIn(
          item,
          _.find(endpointResults, (project) => project.id === item.id)
        );
      });

      // Order data array to avoid overlaping issues
      projectsData = _.orderBy(projectsData, ["funding"], ["asc"]);

      projectsData = _.map(projectsData, function (item) {
        // Need to rename the programs to fit the corporate design / specs
        // H2020 -> Horitzó 2020
        // Instruments RIS3CAT -> Intruments de la RIS3CAT

        item["frameworkNameComplete"] = PROGRAMS_LABELS[item["frameworkName"]];
        return item;
      });

      // 2. Nested programa entry
      // 2.1 Extract first level options (frameworkName)
      let firstProgramLevel = _.uniqBy(
        _.map(projectsData, (project) => {
          return {
            TRANSLATION_KEY: _.camelCase(project["frameworkNameComplete"]),
            label: project["frameworkNameComplete"],
            value: project["frameworkName"],
            sort: project["frameworkNameComplete"],
            children: [],
            level: 1,
            color: that.state.colorScale(project["frameworkName"]),
          };
        }),
        "value"
      );

      firstProgramLevel = _.orderBy(firstProgramLevel, ["sort"], ["asc"]);
      // Remove any undefined entry
      firstProgramLevel = _.filter(
        firstProgramLevel,
        (option) => option["label"] !== undefined
      );
      // 2.2 Add second level for each of first level
      _.map(firstProgramLevel, (program) => {
        // 1. filter projects for each framework
        const projectsInFramework = _.filter(projectsData, (project) => {
          return project["frameworkName"] === program["value"];
        });

        // 2. extract Programas for each framework
        let secondProgramLevel = _.uniqBy(
          _.map(projectsInFramework, (project) => {
            return {
              label: project["instrumentName"],
              value: project["instrumentId"],
              sort: project["instrumentId"],
              level: 2,
            };
          }),
          "value"
        );
        secondProgramLevel = _.orderBy(secondProgramLevel, ["sort"], ["asc"]);
        program["children"] = secondProgramLevel;
        return program;
      });

      projectsData = _.map(projectsData, (project) => {
        project["programaId"] =
          project["frameworkNameComplete"] + " - " + project["instrumentId"];
        project["programaName"] =
          project["frameworkNameComplete"] + " - " + project["instrumentName"];
        return project;
      });

      // Populate options for nested programas (Programa in filters)
      let programaNameOptions = firstProgramLevel;

      currentFilters.populate["frameworks"] = {
        options: programaNameOptions,
      };

      // TODO: Create a functional component to calculate summary component values

      // Array years of projects
      let years = _.map(projectsData, (project) => project.year);
      years = _.uniq(years);
      years = _.sortBy(years, (year) => year);
      // Remove any undefined entry
      years = _.filter(years, (year) => year !== undefined);

      // Add years to filters
      currentFilters.fullYearsRange = years;
      currentFilters.years = years;

      // Add years to summary
      currentSummary.years = d3.extent(years);

      that.setState({
        filters: currentFilters,
        summary: currentSummary,
        loadingText: getTranslation({
          lang: that.state.language,
          key: "LOADING_MESSAGES.INITIAL_LOAD.STEP_4",
        }),
      });

      return projectsData;
    });
  }

  // Ambits
  async fetchProjectsAmbits() {
    // const projectChunks = _.chunk(array, CONFIG.APP.CHUNK_SIZE);
    // const promises = projectChunks.map(
    //   async (chunk) => await getProjectsAmbits(chunk)
    // );
    const promises = await getProjectsAmbits();
    let currentData = { ...this.state.data };
    let currentFilters = { ...this.state.filters }; // for populate
    const that = this;
    return Promise.all(promises).then(function (results) {
      let endpointResults = results;

      const groupedAmbits = _.groupBy(endpointResults, "id");

      // Assing values to projects
      let projectsData = currentData.topics.data;

      // ambits
      projectsData = _.map(projectsData, function (item) {
        item["categoryName"] = _.map(
          groupedAmbits[item["id"]],
          (obj) => obj["categoryName"]
        );
        return item;
      });

      // Populate options for categoryName (Àmbits d'actuació) filter
      let categoryNameOptions = _.uniqBy(
        _.map(endpointResults, (project) => {
          return {
            label: project["categoryName"],
            value: project["categoryName"],
            sort:
              project["categoryName"] === "Sense classificar"
                ? "zzz" // Move the "Altres" option to the last position
                : project["categoryName"],
          };
        }),
        "value"
      );

      categoryNameOptions = _.orderBy(categoryNameOptions, ["sort"], ["asc"]);

      // Generate TRANSLATION_KEY for each option
      categoryNameOptions = _.map(categoryNameOptions, (option, index) => {
        option["TRANSLATION_KEY"] = _.camelCase(option["value"]);
        option["label"] = getTranslation({
          lang: that.state.language,
          key:
            "FILTER_PANEL.FILTERS.CATEGORY_NAME.OPTIONS." +
            option["TRANSLATION_KEY"],
        });
        return option;
      });

      currentFilters.populate["categoryName"] = {
        options: categoryNameOptions,
      };

      that.setState({
        filters: currentFilters,
        loadingText: getTranslation({
          lang: that.state.language,
          key: "LOADING_MESSAGES.INITIAL_LOAD.STEP_5",
        }),
      });

      return projectsData;
    });
  }

  // 2. Keywords
  async fetchProjectsKeywords() {
    // const projectChunks = _.chunk(array, CONFIG.APP.CHUNK_SIZE);
    // const promises = projectChunks.map(
    //   async (chunk) => await getProjectsKeywords(chunk)
    // );
    const promises = await getProjectsKeywords();
    let currentData = { ...this.state.data };
    let currentFilters = { ...this.state.filters };
    return Promise.all(promises).then(function (results) {
      let endpointResults = results;
      _.map(endpointResults, (result) => {
        result["keywords"] = result["keywords"]
          ? result["keywords"].split(";")
          : [];
        return result;
      });

      // Process keywords
      // 1. create array from each

      let keywordsArray = [];
      _.map(endpointResults, function (entry) {
        entry["keywords"] && keywordsArray.push(entry["keywords"]);
      });

      keywordsArray = keywordsArray.flat();

      let groupedKeywordsArray = _.countBy(keywordsArray);
      // groupedKeywordsArray = Object.values(groupedKeywordsArray);
      // Push keywords values to keywords dropdown
      let keywordsOptions = [];
      Object.entries(groupedKeywordsArray).forEach(function (key, index) {
        keywordsOptions.push({
          // label: key[0] + " [" + key[1] + "]",
          label: key[0],
          value: key[0],
          // sort: key[1], // sort by concurrence
        });
      });

      // keywordsOptions = _.orderBy(keywordsOptions, ["sort"], ["desc"]);
      currentFilters.populate["keywords"] = {
        options: keywordsOptions,
      };

      // 2. Group and count

      let projectsData = currentData.topics.data;

      // keywords
      projectsData = _.map(projectsData, function (item) {
        return _.assignIn(
          item,
          _.find(endpointResults, (project) => project.id === item.id)
        );
      });

      return projectsData;
    });
  }

  // 3. Populate Participants information

  populateParticipantsData(data) {
    let currentFilters = { ...this.state.filters };
    let currentSummary = { ...this.state.summary };

    let endpointResults = data;

    // if isCERCA is true change activityTypeId to CERCA
    endpointResults = _.map(endpointResults, (participant) => {
      if (participant.isCERCA) {
        participant.activityTypeId = "CERCA";
      }
      return participant;
    });
    const participantsExtended = _(endpointResults)
      .groupBy("organizationId")
      .map((participant, id) => ({
        organizationId: Number(id),
        totalInvestment: _.sumBy(participant, "investment"),
        totalGrant: _.sumBy(participant, "grant"),
        totalProjects: Object.keys(_.countBy(participant, "id")).length,
        // isCERCA: participant[0].isCERCA,
      }))
      .value();

    // Populate option for participants filter
    let participantOptions = _.uniqBy(
      _.map(data, (project) => {
        return {
          label: project["organizationName"],
          value: Number(project["organizationId"]),
          sort: project["organizationName"],
          activityTypeId: project.activityTypeId,
          countryName: project["countryName"],
          participantNuts2Name: project["participantNuts2Name"],
          participantNuts3Id: project["participantNuts3Id"],
          participantNuts3Name: project["participantNuts3Name"],
        };
      }),
      "value"
    );

    participantOptions = _.orderBy(participantOptions, ["sort"], ["asc"]);

    participantOptions = _.map(participantOptions, function (item) {
      return _.assignIn(
        item,
        _.find(
          participantsExtended,
          (participant) => Number(participant["organizationId"]) === item.value
        )
      );
    });

    // If isCERCA is true change activityTypeId to CERCA
    participantOptions = _.map(participantOptions, function (item) {
      if (item.isCERCA) {
        item.activityTypeId = "CERCA";
      }
      return item;
    });

    // Populate option for activity type filter
    let activityTypeOptions = _.uniqBy(
      _.map(participantOptions, (participant) => {
        const found = _.find(
          ACTIVITY_TYPES,
          (type) => type.value === participant.activityTypeId
        );

        return {
          label: found.label,
          value: participant.activityTypeId,
          sort: found.sort,
          color: this.state.colorScale(participant.activityTypeId),
        };
      }),
      "value"
    );

    activityTypeOptions = _.orderBy(activityTypeOptions, ["sort"], ["asc"]);

    // Populate provincias options

    let nuts3Options = _.uniqBy(
      _.map(data, (project) => {
        return {
          value: project["participantNuts3Id"],
          label: project["participantNuts3Name"],
          sort: project["participantNuts3Name"],
          color: this.state.colorScale(project["participantNuts3Id"]),
        };
      }),
      "value"
    );

    nuts3Options = _.orderBy(nuts3Options, ["sort"], ["asc"]);

    // Populate filters options from data
    // Update summary component with number of participants (nodes in network)
    currentSummary["participants"] = participantOptions.length;
    currentFilters.populate.participantsReference = {
      options: participantOptions,
    };
    currentFilters.populate.participants = {
      options: participantOptions,
    };
    currentFilters.populate.activityType = {
      options: activityTypeOptions,
    };
    currentFilters.populate.nuts3 = {
      options: nuts3Options,
    };

    return currentFilters;
  }

  processProjectsParticipants = (data) => {
    let endpointResults = data;
    // Add properties to the projects by topics data

    const currentData = { ...this.state.data };
    let projectsData = currentData.topics.data;

    // Adding information to data points
    // Add participants id
    const participants = _.chain(endpointResults)
      // Group the elements of Array based on `id` property
      .groupBy("projectId")
      // `key` is group's name (id), `value` is the array of objects
      .map((value, key) => ({
        projectId: key,
        participants: _.map(value, (part) => part["organizationId"]),
      }))
      .value();

    projectsData = _.map(projectsData, function (item) {
      return _.assignIn(
        item,
        _.find(participants, (project) => project["projectId"] === item.id)
      );
    });

    // Add participants type
    // property ->
    const participantTypes = _.chain(endpointResults)
      // Group the elements of Array based on `id` property
      .groupBy("projectId")
      // `key` is group's name (id), `value` is the array of objects
      .map((value, key) => ({
        projectId: key,
        types: _.uniq(_.map(value, (val) => val.activityTypeId)),
      }))
      .value();
    projectsData = _.map(projectsData, function (item) {
      return _.assignIn(
        item,
        _.find(participantTypes, (project) => project["projectId"] === item.id)
      );
    });

    // Add participants province
    // property -> participantNuts3Id
    const participantNuts3 = _.chain(endpointResults)
      // Group the elements of Array based on `id` property
      .groupBy("projectId")
      // `key` is group's name (id), `value` is the array of objects
      .map((value, key) => ({
        projectId: key,
        nuts3: _.uniq(_.map(value, (val) => val.participantNuts3Id)),
      }))
      .value();

    projectsData = _.map(projectsData, function (item) {
      return _.assignIn(
        item,
        _.find(participantNuts3, (project) => project["projectId"] === item.id)
      );
    });

    return projectsData;
  };

  // 4. Partners
  async fetchProjectsPartners() {
    // load local csv file with partners country names
    const COUNTRIES = await d3.csv("./data-ris3cat/map_countries.csv");
    const NUTS2 = await d3.csv("./data-ris3cat/map_nuts2.csv");
    const NUTS3 = await d3.csv("./data-ris3cat/map_nuts3.csv");

    const promises = [getProjectsPartners()];
    // let currentFilters = { ...this.state.filters };
    let currentSummary = { ...this.state.summary };
    let currentData = { ...this.state.data };
    let currentStatistics = { ...this.state.statistics };
    const that = this;
    return Promise.all(promises).then(function (results) {
      let endpointResults = results.flat();
      // Apply workaround for investment
      // Map country, nuts2 and nuts3 to catalan names
      endpointResults = _.map(endpointResults, (project) => {
        if (project["investment"] === null) {
          project["investment"] =
            project["grant"] *
            (project["totalInvestment"] / project["totalGrant"]);
        }


        const foundCountry = _.find(COUNTRIES, (country) => {
          return country.country_en === project["countryName"];
        })

        // Map country
        project["countryName"] = foundCountry ? foundCountry["country_" + that.state.language] : "N/A";

        // Map regions only for nuts not in latin
        // we mix here the names for the spanish comunidades to català
        // generate a list with the comunidades autónomas in spanish and in catalán
        // and map the names
        // we need to do this because the names in the dataset are in spanish
        // and we need to show them in catalan

        const foundNUTS2 = _.find(
          NUTS2,
          (nuts) => nuts.nuts_original === project["participantNuts2Name"]
        );
        if (foundNUTS2) {
          project["participantNuts2Name"] = foundNUTS2.nuts_latin;
        }

        const foundNUTS3 = _.find(
          NUTS3,
          (nuts) => nuts.nuts_original === project["participantNuts3Name"]
        );
        if (foundNUTS3) {
          project["participantNuts3Name"] = foundNUTS3.nuts_latin;
        }

        return project;
      });

      // STORE DATA ON STATE

      // All partners
      // Entitats
      // Socis de Catalunya
      // Socis de l'exterior
      // Socis de l'exterior -> coordinadors
      currentData["partners"] = endpointResults;

      // ENTITATS & PARTCIPANTS DE CATALUNYA
      currentData["internalPartners"] = _.filter(
        endpointResults,
        (result) => result["inRegion"] === true
      );

      // Process participants data
      const populateFilters = that.populateParticipantsData(
        currentData["internalPartners"]
      );

      // Update summary component with the number of network participants
      currentSummary["participants"] =
        populateFilters["populate"]["participants"]["options"].length;

      // Update participants information
      that.processProjectsParticipants(currentData["internalPartners"]);

      // SOCIS DE L'EXTERIOR
      currentData["externalPartners"] = _.filter(
        endpointResults,
        (result) => result["inRegion"] === false
      );
      currentData["coordinators"] = _.filter(
        currentData["externalPartners"],
        (result) => result["roleInProject"] === "Coordinator"
      );

      // Prepare data for ENTITATS & SOCIS DE CATALUNYA & SOCIS DE L'EXTERIOR
      // ENTITATS
      const partnersData = processPartnersData({
        internalPartnersProjects: currentData["internalPartners"],
        externalPartnersProjects: currentData["externalPartners"],
        coordinatorPartnersProjects: currentData["coordinators"],
      });

      // Sum total investment of all participants to get the total investment for catalan actors / participants
      // Add funding to summary component
      // this funding is the sum of all projects funding
      // we need calculate the sum of funding for catalan participants
      // -> move to participants block
      currentSummary.funding = _.sumBy(
        currentData["internalPartners"],
        "investment"
      );

      // SOCIS DE CATALUNYA
      // Internal
      currentStatistics["data"]["entitats"]["projects"] =
        partnersData["internalPartnersProjectsChartData"];
      currentStatistics["data"]["entitats"]["funding"] =
        partnersData["internalPartnersFundingChartData"];
      // SOCIS DE L'EXTERIOR
      // External
      currentStatistics["data"]["exterior"] = {};
      currentStatistics["data"]["exterior"]["countries"] =
        partnersData["externalPartnersByCountryData"];
      currentStatistics["data"]["exterior"]["participants"] =
        partnersData["externalPartnersByParticipantData"];
      // Coordinators
      currentStatistics["data"]["coordinators"] = {};
      currentStatistics["data"]["coordinators"]["countries"] =
        partnersData["coordinatorPartnersByCountryData"];
      currentStatistics["data"]["coordinators"]["participants"] =
        partnersData["coordinatorPartnersByParticipantData"];

      // SUMMARY
      // TODO
      // Create summary component
      // Set summary for socis de l'exterior
      const groupedExternalPartners = _.groupBy(
        currentData["externalPartners"],
        "organizationId"
      );

      currentSummary["partners"] = Object.keys(groupedExternalPartners).length;

      // Save the initial state of the data
      // This is used to reset the data to the initial state
      // We should avoid to update this data in the future

      let currentInitialStateData = { ...that.state.initialStateData };
      // currentInitialStateData["statistics"] = _.cloneDeep(currentStatistics);
      currentInitialStateData["statistics"] = JSON.parse(
        JSON.stringify(currentStatistics)
      );

      const initialStatisticsData = _.cloneDeep(currentStatistics);

      // Update state
      that.setState({
        data: currentData,
        summary: currentSummary,
        statistics: currentStatistics,
        filters: populateFilters,
        loadingText: getTranslation({
          lang: that.state.language,
          key: "LOADING_MESSAGES.INITIAL_LOAD.STEP_6",
        }),
        initialStatisticsData: initialStatisticsData,
        initialStateData: currentInitialStateData,
      });
    });
  }
  // 7. SDGs
  async fetchProjectSDGs() {
    // const projectChunks = _.chunk(array, CONFIG.APP.CHUNK_SIZE);
    // const promises = projectChunks.map(
    //   async (chunk) => await getProjectsSDGs(chunk)
    // );
    const promises = [getProjectsSDGs()];
    let currentData = { ...this.state.data };
    let currentFilters = { ...this.state.filters };
    const that = this;
    return Promise.all(promises).then(function (results) {
      // Populate option for sectors filter
      let SDGOptions = _.uniqBy(
        _.map(results.flat(), (project) => {
          return {
            TRANSLATION_KEY: project.sdgId
              ? project.sdgId.replace(/\s+/g, "_")
              : "NA",
            label: project.sdgName ? project.sdgName : "N/A",
            value: project.sdgId ? project.sdgId : "N/A",
            sort: project.sdgId
              ? Number(project.sdgId.replace("ODS ", ""))
              : results.flat().length,
          };
        }),
        "value"
      );

      SDGOptions = _.orderBy(SDGOptions, ["sort"], ["asc"]);
      currentFilters.populate.SDGs = {
        options: SDGOptions,
      };

      // Adding information to data points
      const SDGs = _.chain(results.flat())
        // Group the elements of Array based on `id` property
        .groupBy("id")
        // `key` is group's name (id), `value` is the array of objects
        .map((value, key) => ({
          id: key,
          SDGs: _.map(value, (sdg) => (sdg.sdgId ? sdg.sdgId : "N/A")),
        }))
        .value();

      let projectsData = currentData.topics.data;

      // sectors id
      projectsData = _.map(projectsData, function (item) {
        return _.assignIn(
          item,
          _.find(SDGs, (project) => project.id === item.id)
        );
      });

      that.setState({
        filters: currentFilters,
        loadingText: getTranslation({
          lang: that.state.language,
          key: "LOADING_MESSAGES.PROCESSANT_INFORMACIO",
        }),
      });

      return projectsData;
    });
  }

  // 8. CREATE RAW DATA AS PREVIOUS VERSION
  // For generating graph data
  createRawData() {
    let currentData = { ...this.state.data };

    let projectsByParticipant = [];
    let projectsByParticipantBySDG = [];
    // Available participants
    const participantsList =
      this.state.filters.populate["participants"]["options"];

    // It should be a entry for each particpant and SDG in the project
    // 1. entry data from the selected projects in clustering
    const entryData = this.state.data.topics.data;

    // 2. split each entry by participants
    _.map(entryData, (project) => {
      if (project["frameworkName"] === undefined) {
        console.log("DEBUG - project no program", project);
      }

      _.map(project["participants"], (participant) => {
        projectsByParticipant.push({
          projectId: project["projectId"],
          projectTitle: project["title"],
          projectAbstract: project["abstract"],
          projectTopic: project["cluster"].toString(),
          organizationId: participant,
          participantType: project["types"][0],
          SDGs: project["SDGs"],
          investment: project["funding"],
          categoryName: project["categoryName"],
          nodeFramework: project["frameworkName"],
          nodePrograma: project["programaId"],
          nodeInstrumentId: project["instrumentId"],
          nodeKeywords: project["keywords"],
          year: project["year"],
          // PROPERTIES COMING FROM THE LIST OF PARTICIPANTS (separated SPARQL query)
          organizationName: _.find(
            participantsList,
            (obj) => obj["value"] === participant
          )["label"],
          countryName: _.find(
            participantsList,
            (obj) => obj["value"] === participant
          )["countryName"],
          participantNuts2Name: _.find(
            participantsList,
            (obj) => obj["value"] === participant
          )["participantNuts2Name"],
          participantNuts3Id: _.find(
            participantsList,
            (obj) => obj["value"] === participant
          )["participantNuts3Id"],
          participantNuts3Name: _.find(
            participantsList,
            (obj) => obj["value"] === participant
          )["participantNuts3Name"],
          // participantIsCERCA: _.find(
          //   participantsList,
          //   (obj) => obj["value"] === participant
          // )["isCERCA"],
        });
      });
    });

    // Remove any entry that have "nodeFramework" = undefined
    projectsByParticipant = _.filter(projectsByParticipant, (entry) => {
      return entry["nodeFramework"] !== undefined;
    });

    // 3. Repeat each entry by SDG
    _.map(entryData, (project) => {
      // let found = _.find(projectsByParticipant, wParticipant => wParticipant['projectId'] === project['id']);
      let filtered = _.filter(
        projectsByParticipant,
        (wParticipant) => wParticipant["projectId"] === project["id"]
      );

      _.map(filtered, (participant) => {
        _.map(participant["SDGs"], (SDG) => {
          let newObj = { ...participant };
          newObj["SDGId"] = Number(SDG.replace("ODS ", ""));
          projectsByParticipantBySDG.push(newObj);
        });
      });

      // _.map(found['SDGs'], (SDG) => {
      //   let newObj = {...found}
      //   newObj['SDGId'] = Number(SDG.replace("ODS ", ""));
      //   projectsByParticipantBySDG.push(newObj);
      // })
    });

    projectsByParticipant = _.orderBy(projectsByParticipant, ["projectId"]);

    projectsByParticipantBySDG = _.orderBy(projectsByParticipantBySDG, [
      "projectId",
    ]);

    currentData.rawData = projectsByParticipantBySDG;
    currentData.networkData = projectsByParticipantBySDG;

    // _.map(currentData['rawData'], project)

    // 3. split each entry by SDG array
    // _.map(entryData, project => {
    //   _.map(project['SDGs'], SDG => {
    //     currentData['rawData'].push({
    //       projectId: project['id'],
    //       sdgId: SDG
    //     })
    //   })
    // })

    this.setState({
      data: currentData,
    });
  }

  // Data for the floating ambits charts
  createAmbitsData({ data = [] }) {
    // 1. Get a list of all the ambits
    let ambits = [];
    _.map(data, (entry) => {
      _.map(entry["categoryName"], (category) => {
        ambits.push(category);
      });
    });

    ambits = _.uniq(ambits);

    // 2. For each ambit group the projects

    let groupedAmbitProjects = {};

    _.map(ambits, (ambit) => {
      let ambitProjects = _.filter(data, (entry) => {
        return _.includes(entry["categoryName"], ambit);
      });

      groupedAmbitProjects[ambit] = ambitProjects;
    });

    return groupedAmbitProjects;
  }

  // Data from programs chart by year
  // createProgramsByYearData({
  //   partnersData = [], // Partners data
  //   data = [], // Projects data
  // }) {
  //   // TODO: Add the investment by partner
  //   // Right now it is only the investment by project

  //   // console.log("CREATE PROGRAMS BY YEAR DATA");
  //   // console.log("ENTRY DATA", data);
  //   // console.log("PARTNERS DATA", partnersData);

  //   // let groupedData = dl.groupby(["year", "frameworkName"]).execute(data);

  //   // get partners grouped data and sum the investment
  //   // let groupedPartners = dl.groupby(["organizationId"]).execute(partnersData);

  //   let partnersGroupedData = dl
  //     .groupby(["year", "nodeFramework"])
  //     .execute(partnersData);

  //   partnersGroupedData = _.map(partnersGroupedData, (partner) => {
  //     // partner["values"] = _.uniqBy(partner["values"], "projectId");
  //     partner["uniqueProjects"] = _.uniqBy(partner["values"], "projectId");
  //     return partner;
  //   });

  //   partnersGroupedData = _.map(partnersGroupedData, (datum) => {
  //     let newItem = {};
  //     newItem["year"] = datum["year"];
  //     newItem["program"] = datum["nodeFramework"];
  //     newItem["value"] = datum["uniqueProjects"].length;
  //     newItem["totalFunding"] = _.sumBy(datum["uniqueProjects"], "investment");
  //     return newItem;
  //   });

  //   return partnersGroupedData;
  // }

  // Main download data as XLS
  async dwnldExcel({
    fileName = "react-export-table-to-excel",
    networkdata = [],
    // sheet = "Dades",
  }) {
    this.setState({
      loading: true,
      loadingText: getTranslation({
        lang: this.state.language,
        key: "LOADING_MESSAGES.DOWNLOAD",
      }),
    });

    // Google Analytics
    this.state.cookiesAccepted &&
      ReactGA.event({
        category: "User interaction",
        action: "data_download",
        label: "projects", // optional
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });

    // Get the uniq projects ids from filtered results (networkData)
    let projectIds = _.uniq(networkdata.map((d) => d.projectId));

    const projectChunks = _.chunk(projectIds, CONFIG.APP.CHUNK_SIZE);

    const promises = projectChunks.map(
      async (chunk) => await getMainDataDownload(chunk)
    );

    const that = this;

    return Promise.all(promises).then(function (results) {
      const endpointResults = results.flat();

      // Manage results to calculate participant investment when it is null
      // Also map activityType to activityTypeLabel
      let downloadData = _.map(endpointResults, (result) => {
        result.participantInvestment === null &&
          (result.participantInvestment = calculateInvestment({
            participantGrant: result.participantGrant,
            projectTotalInvestment: result.projectTotalInvestment,
            projectTotalGrant: result.projectTotalGrant,
          }));

        result.participantType = ACTIVITY_TYPES_LABELS[result.activityTypeId];

        return result;
      });

      // Prepare data for XLS download function
      // Select only the columns to be downloaded

      // Translate the column names
      const columnHeaders = [
        getTranslation({
          lang: that.state.language,
          key: "DOWNLOAD_HEADERS.PROJECT_ID",
        }),
        getTranslation({
          lang: that.state.language,
          key: "DOWNLOAD_HEADERS.PROJECT_TITLE",
        }),
        getTranslation({
          lang: that.state.language,
          key: "DOWNLOAD_HEADERS.PROJECT_ABSTRACT",
        }),
        getTranslation({
          lang: that.state.language,
          key: "DOWNLOAD_HEADERS.PROJECT_YEAR",
        }),
        getTranslation({
          lang: that.state.language,
          key: "DOWNLOAD_HEADERS.PROJECT_AMBIT",
        }),
        getTranslation({
          lang: that.state.language,
          key: "DOWNLOAD_HEADERS.ENTITY_NAME",
        }),
        getTranslation({
          lang: that.state.language,
          key: "DOWNLOAD_HEADERS.ENTITY_TYPE",
        }),
        getTranslation({
          lang: that.state.language,
          key: "DOWNLOAD_HEADERS.ENTITY_ROLE",
        }),
        getTranslation({
          lang: that.state.language,
          key: "DOWNLOAD_HEADERS.ENTITY_NUTS3",
        }),
        getTranslation({
          lang: that.state.language,
          key: "DOWNLOAD_HEADERS.FUNDING",
        }),
        getTranslation({
          lang: that.state.language,
          key: "DOWNLOAD_HEADERS.GRANT",
        }),
      ];

      // Pass the column names as keys and the values from the data
      // as values to the body array

      const body = downloadData.map((d) => {
        return {
          [columnHeaders[0]]: d.projectId,
          [columnHeaders[1]]: d.projectTitle,
          [columnHeaders[2]]: d.projectAbstract,
          [columnHeaders[3]]: d.year,
          [columnHeaders[4]]: getTranslation({
            lang: that.state.language,
            key:
              "FILTER_PANEL.FILTERS.CATEGORY_NAME.OPTIONS." +
              _.camelCase(d.projectCategoryName),
          }),
          [columnHeaders[5]]: d.organizationName,
          [columnHeaders[6]]: getTranslation({
            lang: that.state.language,
            key:
              "FILTER_PANEL.FILTERS.ACTIVITY_TYPE.OPTIONS." + d.activityTypeId,
          }),
          [columnHeaders[7]]: d.roleInProject,
          [columnHeaders[8]]: d.participantNuts3Name,
          [columnHeaders[9]]: Number(d.participantInvestment),
          [columnHeaders[10]]: Number(d.participantGrant),
        };
      });

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(body);
      const wb = {
        SheetNames: ["Dades"],
        Sheets: { Dades: ws },
      };
      const excelBuffer = XLSX.write(wb, {
        type: "array",
        bookType: "xlsx",
      });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      that.setState({
        loading: false,
      });
    });
  }

  // Download data as XLS for the external partners

  dwnldPartnersExcel({
    fileName = "react-export-table-to-excel",
    partnersdata = [],
    // sheet = "Dades",
  }) {
    this.setState({
      loading: true,
      loadingText: getTranslation({
        lang: this.state.language,
        key: "LOADING_MESSAGES.DOWNLOAD",
      }),
    });
    // Google Analytics
    this.state.cookiesAccepted &&
      ReactGA.event({
        category: "User interaction",
        action: "data_download",
        label: "partners", // optional
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });

    // Create a deep copy of the data
    let isolatedPartnersdata = _.cloneDeep(partnersdata);

    // Remove unnecessary columns
    // Map activityType to activityTypeLabel
    isolatedPartnersdata = _.map(isolatedPartnersdata, (partner) => {
      const found = _.find(
        this.state.data.topics.data,
        (project) => project.id === partner.projectId
      );
      // Map the activity type to a regular name
      partner.activityType = ACTIVITY_TYPES_LABELS[partner.activityTypeId];
      // Add title and abstract fro the project
      partner.projectTitle = found ? found["title"] : "No title";
      partner.projectAbstract = found ? found["abstract"] : "No abstract";
      delete partner["inRegion"];
      delete partner["isCERCA"];
      delete partner["organizationId"];
      // delete partner["activityTypeId"];
      return partner;
    });

    // Prepare headers to be multilingual
    // Translate the column names
    const columnHeaders = [
      getTranslation({
        lang: this.state.language,
        key: "DOWNLOAD_HEADERS.PROJECT_ID",
      }),
      getTranslation({
        lang: this.state.language,
        key: "DOWNLOAD_HEADERS.PROJECT_TITLE",
      }),
      getTranslation({
        lang: this.state.language,
        key: "DOWNLOAD_HEADERS.PROJECT_ABSTRACT",
      }),
      getTranslation({
        lang: this.state.language,
        key: "DOWNLOAD_HEADERS.PROJECT_YEAR",
      }),
      getTranslation({
        lang: this.state.language,
        key: "DOWNLOAD_HEADERS.PROJECT_AMBIT",
      }),
      getTranslation({
        lang: this.state.language,
        key: "DOWNLOAD_HEADERS.ENTITY_NAME",
      }),
      getTranslation({
        lang: this.state.language,
        key: "DOWNLOAD_HEADERS.ENTITY_TYPE",
      }),
      getTranslation({
        lang: this.state.language,
        key: "DOWNLOAD_HEADERS.ENTITY_ROLE",
      }),
      getTranslation({
        lang: this.state.language,
        key: "DOWNLOAD_HEADERS.ENTITY_NUTS3",
      }),
      getTranslation({
        lang: this.state.language,
        key: "DOWNLOAD_HEADERS.FUNDING",
      }),
      getTranslation({
        lang: this.state.language,
        key: "DOWNLOAD_HEADERS.GRANT",
      }),
      // Country
      getTranslation({
        lang: this.state.language,
        key: "DOWNLOAD_HEADERS.ENTITY_COUNTRY",
      }),
      // Region
      getTranslation({
        lang: this.state.language,
        key: "DOWNLOAD_HEADERS.ENTITY_NUTS2",
      }),
      // Total investment
      getTranslation({
        lang: this.state.language,
        key: "DOWNLOAD_HEADERS.TOTAL_FUNDING",
      }),
      // Total grant
      getTranslation({
        lang: this.state.language,
        key: "DOWNLOAD_HEADERS.TOTAL_GRANT",
      }),
    ];

    const body = isolatedPartnersdata.map((d) => {
      return {
        [columnHeaders[0]]: d.projectId,
        [columnHeaders[1]]: d.projectTitle,
        [columnHeaders[2]]: d.projectAbstract,
        [columnHeaders[5]]: d.organizationName,
        [columnHeaders[6]]: getTranslation({
          lang: this.state.language,
          key: "FILTER_PANEL.FILTERS.ACTIVITY_TYPE.OPTIONS." + d.activityTypeId,
        }),
        [columnHeaders[7]]: d.roleInProject,
        [columnHeaders[11]]: d.countryName,
        [columnHeaders[12]]: d.participantNuts2Name,
        [columnHeaders[8]]: d.participantNuts3Name,
        [columnHeaders[9]]: Number(d.investment),
        [columnHeaders[10]]: Number(d.grant),
        [columnHeaders[13]]: Number(d.totalInvestment),
        [columnHeaders[14]]: Number(d.totalGrant),
      };
    });

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(body);
    const wb = {
      Sheets: { Dades: ws },
      SheetNames: ["Dades"],
    };
    const excelBuffer = XLSX.write(wb, {
      type: "array",
      bookType: "xlsx",
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);

    this.setState({
      loading: false,
    });
  }

  ///////////////////////////////////////////////////////////////////////////
  ////////// GETTERS ////////////////////////////////////////////////////////

  getTopicInformation = (topicId) =>
    _.filter(
      this.state.data.topics.topics,
      (topic) => topic.cluster === topicId
    );

  getProjectInformation = (projectId) =>
    _.filter(
      this.state.data.topics.data,
      (project) => project.id === projectId
    );

  ///////////////////////////////////////////////////////////////////////////
  ////////// FILTERS ////////////////////////////////////////////////////////

  setFilteredProjects({ array = [] }) {
    let currentFilters = { ...this.state.filters };
    currentFilters.filteredProjects =
      currentFilters.filteredProjects.concat(array);
    currentFilters.filteredProjects = _.uniq(
      currentFilters.filteredProjects
    ).flat();
    this.setState({
      filters: currentFilters,
    });
  }

  // Related to filter checkboxes component
  setFilters = (filters) => {
    // Close Node Panel Information
    this.closeNodeInfo();

    // Remove any entry with values.length === 0
    filters = _.filter(filters, (filter) => filter.values.length > 0);

    loadingState({
      state: true,
      canvasSize: this.state.canvasSize,
      textNetwork: getTranslation({
        lang: this.state.language,
        key: "LOADING_MESSAGES.NETWORK",
      }),
      textTopics: getTranslation({
        lang: this.state.language,
        key: "LOADING_MESSAGES.MAP",
      }),
    });
    let currentFilters = { ...this.state.filters };
    // Reset search participants before send filters
    currentFilters.selected = filters;
    // Magic timer to wait for the state to be updated
    setTimeout(() => {
      this.displayFilters({
        filters: currentFilters,
        activeFilters: filters,
        search: true,
      });
    }, 300);
  };

  // Create separated functions for each search type

  participantsSearch({ currentFilters = [], filter = [] }) {
    const participantFound = _.find(
      currentFilters.selected,
      (filter) => filter.type === "participants"
    );
    participantFound
      ? (participantFound.values = _.map(filter, (o) => o.value))
      : currentFilters.selected.push({
          type: "participants",
          values: _.map(filter, (o) => o.value),
        });

    return currentFilters;
  }

  keywordsSearch = ({ currentFilters = [], filter = [], mode = "AND" }) => {
    // if is there any new option added to the keywords selector
    const addedOptions = _.filter(filter, (option) => {
      return option["__isNew__"];
    });

    // add it to the main keywords selector
    if (addedOptions.length > 0) {
      currentFilters["populate"]["keywords"]["options"] = _.uniqBy(
        currentFilters["populate"]["keywords"]["options"].concat(addedOptions),
        "value"
      );
    }

    // const keywordsFound = _.find(
    //   currentFilters.selected,
    //   (filter) => filter.type === "keywords"
    // );

    // Remove keywords from the selected filters
    currentFilters.selected = _.filter(
      currentFilters.selected,
      (filter) => filter.type !== "keywords"
    );

    // Replace it with the new one
    currentFilters.selected.push({
      type: "keywords",
      values: _.map(filter, (o) => o.value),
      mode: mode, // AND or OR
    });

    return currentFilters;
  };

  setSearch({ keywords = null, participants = null }) {
    let currentFilters = { ...this.state.filters };
    let participantsSelected = null;
    let keywordsSelected = null;

    // Combined search
    if (participants !== null) {
      participantsSelected = this.participantsSearch({
        currentFilters: currentFilters,
        filter: participants["filter"],
      });
    }

    if (keywords !== null) {
      keywordsSelected = this.keywordsSearch({
        currentFilters: currentFilters,
        filter: keywords["filter"],
        mode: keywords["mode"],
      });
    }

    if (participantsSelected === null) {
      participantsSelected = currentFilters;
    }

    if (keywordsSelected === null) {
      keywordsSelected = currentFilters;
    }

    currentFilters["selected"] = _.uniqBy(
      [...participantsSelected["selected"], ...keywordsSelected["selected"]],
      "type"
    );

    // Filter selected if any of the entries has the values length === 0
    currentFilters["selected"] = _.filter(
      currentFilters["selected"],
      (filter) => filter.values.length > 0
    );

    this.displayFilters({
      filters: currentFilters,
      activeFilters: currentFilters["selected"],
      search: true,
    });
  }

  setClickedAmbit({ ambit = null }) {
    // Launch the interaction loader
    loadingState({
      state: true,
      canvasSize: this.state.canvasSize,
      textNetwork: getTranslation({
        lang: this.state.language,
        key: "LOADING_MESSAGES.NETWORK",
      }),
      textTopics: getTranslation({
        lang: this.state.language,
        key: "LOADING_MESSAGES.MAP",
      }),
    });
    // get the active filters
    let currentFilters = { ...this.state.filters };
    let activeFilters = currentFilters["selected"];

    // select ambit filters
    let ambitActiveFilters = _.find(
      activeFilters,
      (filter) => filter.type === "categoryName"
    );

    // Check if is there ambit type filter
    if (!ambitActiveFilters) {
      activeFilters.push({
        type: "categoryName",
        values: [ambit],
      });
    } else {
      const ambitAlreadySelected = ambitActiveFilters
        ? ambitActiveFilters["values"].includes(ambit)
        : false;

      if (ambitAlreadySelected) {
        ambitActiveFilters["values"] = _.filter(
          ambitActiveFilters["values"],
          (value) => value !== ambit
        );
      } else {
        ambitActiveFilters["values"].push(ambit);
      }
      // remove the entry for ambit
      activeFilters = _.filter(
        activeFilters,
        (filter) => filter.type !== "categoryName"
      );
      // add the new ambit
      ambitActiveFilters !== undefined &&
        activeFilters.push(ambitActiveFilters);
    }

    // if filter ambit entry is present but empty, remove it
    if (ambitActiveFilters && ambitActiveFilters["values"].length === 0) {
      activeFilters = _.filter(
        activeFilters,
        (filter) => filter.type !== "categoryName"
      );
    }

    // Update filters
    currentFilters["selected"] = activeFilters;
    setTimeout(() => {
      this.displayFilters({
        filters: currentFilters,
        activeFilters: activeFilters,
      });
    }, 300);
  }

  // SELECT TOPIC LABEL

  selectTopicLabel = (topicId) => {
    // Launch the interaction loader
    loadingState({
      state: true,
      canvasSize: this.state.canvasSize,
      textNetwork: getTranslation({
        lang: this.state.language,
        key: "LOADING_MESSAGES.NETWORK",
      }),
      textTopics: getTranslation({
        lang: this.state.language,
        key: "LOADING_MESSAGES.MAP",
      }),
    });

    // Stop if topicId is null
    if (topicId !== null) {
      // return;
      topicId = topicId.toString();
    }

    // topicId = toString(topicId);
    // get the active filters
    let currentFilters = { ...this.state.filters };
    let activeFilters = currentFilters["selected"];

    // select topics filters
    let topicsActiveFilters = _.find(
      activeFilters,
      (filter) => filter.type === "topics"
    );

    // Check if is there ambit type filter
    if (!topicsActiveFilters && topicId !== null) {
      activeFilters.push({
        type: "topics",
        values: topicId ? [topicId] : [],
      });
    } else {
      if (topicId === null) {
        if (topicsActiveFilters && topicsActiveFilters["values"].length > 0) {
          loadingState({
            state: true,
            canvasSize: this.state.canvasSize,
            textNetwork: getTranslation({
              lang: this.state.language,
              key: "LOADING_MESSAGES.NETWORK",
            }),
            textTopics: getTranslation({
              lang: this.state.language,
              key: "LOADING_MESSAGES.MAP",
            }),
          });
        }
        if (topicsActiveFilters) {
          topicsActiveFilters["values"] = [];
        }
      } else {
        const topicAlreadySelected = topicsActiveFilters
          ? topicsActiveFilters["values"].includes(topicId)
          : false;

        if (topicAlreadySelected) {
          topicsActiveFilters["values"] = _.filter(
            topicsActiveFilters["values"],
            (value) => value !== topicId
          );
        } else {
          topicsActiveFilters["values"].push(topicId);
        }
      }

      // remove the entry for topics
      activeFilters = _.filter(
        activeFilters,
        (filter) => filter.type !== "topics"
      );

      // add the new selected topics
      topicsActiveFilters !== undefined &&
        activeFilters.push(topicsActiveFilters);
    }
    // if filter topics entry is present but empty, remove it
    if (topicsActiveFilters && topicsActiveFilters["values"].length === 0) {
      activeFilters = _.filter(
        activeFilters,
        (filter) => filter.type !== "topics"
      );
    }

    // Update filters
    currentFilters["selected"] = activeFilters;

    setTimeout(() => {
      this.displayFilters({
        filters: currentFilters,
        activeFilters: activeFilters,
      });
    }, 0.1);
  };

  setClickedProject({ id = null }) {
    if (id !== null) {
      // Google Analytics
      this.state.cookiesAccepted &&
        ReactGA.event({
          category: "User interaction",
          action: "project_modal_opened",
          label: _.find(
            this.state.data.topics.data,
            (project) => project["id"] === id
          )["title"], // optional
          // value: 99, // optional, must be a number
          nonInteraction: true, // optional, true/false
          transport: "xhr", // optional, beacon/xhr/image
        });
    }

    this.setState({
      clickedProject: id,
    });
  }

  resetAllFilters = () => {
    loadingState({
      state: true,
      canvasSize: this.state.canvasSize,
      textNetwork: getTranslation({
        lang: this.state.language,
        key: "LOADING_MESSAGES.NETWORK",
      }),
      textTopics: getTranslation({
        lang: this.state.language,
        key: "LOADING_MESSAGES.MAP",
      }),
    });
    // Google Analytics
    this.state.cookiesAccepted &&
      ReactGA.event({
        category: "User interaction",
        action: "filters_reset",
        // label: "projects", // optional
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });

    setTimeout(() => {
      // 1. Clean filters in state
      let currentFilters = { ...this.state.filters };
      currentFilters["selected"] = [];
      // 2. Call displayFilters() without values
      this.displayFilters({ filters: currentFilters, activeFilters: [] });
    }, 300);
  };

  resetAllSearches = () => {
    loadingState({
      state: true,
      canvasSize: this.state.canvasSize,
      textNetwork: getTranslation({
        lang: this.state.language,
        key: "LOADING_MESSAGES.NETWORK",
      }),
      textTopics: getTranslation({
        lang: this.state.language,
        key: "LOADING_MESSAGES.MAP",
      }),
    });
    let currentFilters = { ...this.state.filters };
    currentFilters["selected"] = _.filter(
      currentFilters["selected"],
      (filter) => filter["type"] !== "participants"
    );
    currentFilters["searchedParticipantIds"] = [];
    setTimeout(() => {
      this.displayFilters({
        filters: currentFilters,
        activeFilters: currentFilters["selected"],
      });
    }, 300);
  };

  displayFilters({ filters = {}, activeFilters = [], search = false }) {
    // 1. Get active IDS
    let currentFilters = filters;
    let currentSummary = { ...this.state.summary };
    let currentStatistics = { ...this.state.statistics };
    // FILTER NETWORK DATA
    let currentData = { ...this.state.data };
    let networkData = currentData.rawData;

    if (activeFilters.length > 0) {
      activeFilters.forEach((filter) => {
        // Google Analytics
        this.state.cookiesAccepted &&
          ReactGA.event({
            category: "User interaction",
            action: "filter_" + filter["type"],
            label: _.map(filter["values"], (value) => {
              // Temátiques and participants are numbers so let's send the proper name for each
              if (filter["type"] === "topics") {
                return _.find(
                  filters.populate.topics.options,
                  (topic) => Number(topic.value) === Number(value)
                )["label"];
              } else if (filter["type"] === "participants") {
                return _.find(
                  filters.populate.participantsReference.options,
                  (participant) => participant.value === value
                )["label"];
              } else {
                return value;
              }
            }), // optional
            // value: 99, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
      });
    }
    // TODO: manage a way of resetting filters
    // avoiding mutability of reference datasets (on filtering it is not possible to recover the initial state)
    // Not so structured but here the reset filter state is managed
    // The idea is reduce the scripting when reseting filters
    // else {
    //   // console.log("---------- NO FILTERS");

    //   // Using the initial state data when reseting filters
    //   let initialFilters = this.state.initialStateData.filters;
    //   // Clean selected filters

    //   initialFilters["selected"] = [];

    //   // VISUAL FILTERING OF TOPICS VIEW
    //   afterFiltersApply({
    //     totalProjects: [],
    //     filteredProjects: [],
    //     activeTopics: [],
    //     // filteredTopics: topicIDS,
    //   });

    //   // Need to replicate the partners module calculation
    //   // No way of keep a non mutable object in the state

    //   let currentStatistics = { ...this.state.statistics };

    //   // For internal partners (socis)
    //   const partnersData = processPartnersData({
    //     internalPartnersProjects:
    //       this.state.initialStateData.data.internalPartners,
    //     externalPartnersProjects:
    //       this.state.initialStateData.data.externalPartners,
    //     coordinatorPartnersProjects:
    //       this.state.initialStateData.data.coordinators,
    //     searchedParticipants: [],
    //     selectedParticipants: [],
    //   });

    //   // Internal
    //   currentStatistics["data"]["entitats"]["projects"] =
    //     partnersData["internalPartnersProjectsChartData"];
    //   currentStatistics["data"]["entitats"]["funding"] =
    //     partnersData["internalPartnersFundingChartData"];
    //   // External
    //   currentStatistics["data"]["exterior"]["countries"] =
    //     partnersData["externalPartnersByCountryData"];
    //   currentStatistics["data"]["exterior"]["participants"] =
    //     partnersData["externalPartnersByParticipantData"];
    //   // Coordinators
    //   currentStatistics["data"]["coordinators"] = {};
    //   currentStatistics["data"]["coordinators"]["countries"] =
    //     partnersData["coordinatorPartnersByCountryData"];
    //   currentStatistics["data"]["coordinators"]["participants"] =
    //     partnersData["coordinatorPartnersByParticipantData"];

    //   // Need to repopulate participants dropdown options with the initial state data
    //   currentFilters["populate"]["participants"] = this.state.filters["populate"]["participantsReference"]

    //   this.setState({
    //     interactionLoading: false,
    //     data: this.state.initialStateData.data,
    //     filters: initialFilters,
    //     summary: this.state.initialStateData.summary,
    //     statistics: currentStatistics,
    //   });
    //   // 6. Remove loading state
    //   setTimeout(() => {
    //     loadingState({ state: false });
    //   }, 30);
    //   return null;
    // }

    // Remove participants if coming from filters
    // if (!search) {
    //   activeFilters = _.filter(
    //     activeFilters,
    //     (filter) => filter["type"] !== "participants"
    //   );
    // }

    let categoryNameIDS = [];
    let SDGsIDS = [];
    let frameworksIDS = [];
    let activityTypeIDS = [];
    let nuts3IDS = [];
    let yearsIDS = [];
    // NOT PANEL FILTERS
    // Search participants
    let participantsIDS = [];
    let keywordsIDS = [];
    let topicIDS = [];
    // let coordinatesToFind = [];

    // Set operator for keywords search
    let operator = "AND";

    // Get values from filters
    // Apply filters to networkData
    _.map(activeFilters, (filter) => {
      if (filter.type === "categoryName") {
        categoryNameIDS = filter.values;
        networkData =
          categoryNameIDS.length > 0
            ? _.filter(networkData, (obj) => {
                const checkCommon = categoryNameIDS.filter((value) =>
                  obj["categoryName"].includes(value)
                );
                return checkCommon.length > 0 ? true : false;
              })
            : networkData;
      }
      if (filter.type === "SDGs") {
        SDGsIDS = filter.values;
        networkData =
          SDGsIDS.length > 0
            ? _.filter(networkData, (obj) => {
                const checkCommon = SDGsIDS.filter((value) =>
                  obj["SDGs"].includes(value)
                );
                return checkCommon.length > 0 ? true : false;
              })
            : networkData;
      }
      if (filter.type === "frameworks") {
        frameworksIDS = filter;
        networkData =
          frameworksIDS.values.length > 0
            ? _.filter(networkData, (obj) => {
                return (
                  frameworksIDS["level1"]["values"].includes(
                    obj["nodeFramework"]
                  ) ||
                  frameworksIDS["level2"]["values"].includes(
                    obj["nodeInstrumentId"]
                  )
                );
              })
            : networkData;
      }
      if (filter.type === "activityType") {
        activityTypeIDS = filter.values;
        networkData =
          activityTypeIDS.length > 0
            ? _.filter(networkData, (obj) => {
                return activityTypeIDS.includes(obj["participantType"]);
              })
            : networkData;
      }
      if (filter.type === "nuts3") {
        nuts3IDS = filter.values;
        networkData =
          nuts3IDS.length > 0
            ? _.filter(networkData, (obj) => {
                return nuts3IDS.includes(obj["participantNuts3Id"]);
              })
            : networkData;
      }
      if (filter.type === "years") {
        yearsIDS = filter.values;
        networkData =
          yearsIDS.length > 0
            ? _.filter(networkData, (obj) => {
                return yearsIDS.includes(obj["year"]);
              })
            : networkData;
      }
      // SEARCH PARTICIPANTS
      // Should be a single value
      if (filter.type === "participants") {
        participantsIDS = filter.values;
      }
      // SEARCH KEYWORDS
      // 1. Apply logical operator
      // 2. Search on titles and abstracts
      if (filter.type === "keywords") {
        // Get the logical operator
        operator = _.find(activeFilters, (o) => {
          return o.type === "keywords";
        }).mode;
        keywordsIDS = filter.values;
        // Search by pre defined keywords
        networkData = searchParticipantsKeywords({
          networkData: networkData,
          keywordsIDS: keywordsIDS,
          operator: operator,
        });
      }
      if (filter.type === "topics") {
        topicIDS = filter.values;
        networkData =
          topicIDS.length > 0
            ? _.filter(networkData, (obj) => {
                return topicIDS.includes(obj["projectTopic"]);
              })
            : networkData;
      }
    });

    // extract ids from filter
    // const filterIds = _.map(filter, o => o.value);
    const projects = this.state.data.topics.data;
    const topics = this.state.data.topics.topics;

    // Filters intersection for topics view
    let filteredProjects = _.intersection(
      _.map(
        _.filter(projects, (project) => {
          if (categoryNameIDS.length === 0) {
            return true;
          } else {
            let match = [];
            if (project.categoryName) {
              match = _.filter(categoryNameIDS, (category) =>
                project.categoryName.includes(category)
              );
            }
            return match.length > 0 ? true : false;
            // return categoryNameIDS.includes(project["categoryName"]);
          }
        }),
        "id"
      ),
      _.map(
        _.filter(projects, (project) => {
          if (SDGsIDS.length === 0) {
            return true;
          } else {
            let match = [];
            if (project.SDGs) {
              match = _.filter(SDGsIDS, (SDG) => project.SDGs.includes(SDG));
            }
            return match.length > 0 ? true : false;
          }
        }),
        "id"
      ),
      _.map(
        _.filter(projects, (project) => {
          if (frameworksIDS.values.length === 0) {
            return true;
          } else {
            return (
              frameworksIDS["level1"]["values"].includes(
                project["frameworkName"]
              ) ||
              frameworksIDS["level2"]["values"].includes(
                project["instrumentId"]
              )
            );
          }
        }),
        "id"
      ),
      _.map(
        _.filter(projects, (project) => {
          if (activityTypeIDS.length === 0) {
            return true;
          } else {
            let match = [];
            if (project.types) {
              match = _.filter(activityTypeIDS, (type) =>
                project.types.includes(type)
              );
            }
            return match.length > 0 ? true : false;
          }
        }),
        "id"
      ),
      _.map(
        _.filter(projects, (project) => {
          if (nuts3IDS.length === 0) {
            return true;
          } else {
            let match = [];
            if (project.nuts3) {
              match = _.filter(nuts3IDS, (nuts3) =>
                project.nuts3.includes(nuts3)
              );
            }
            return match.length > 0 ? true : false;
          }
        }),
        "id"
      ),
      _.map(
        _.filter(projects, (project) => {
          if (participantsIDS.length === 0) {
            return true;
          } else {
            let match = [];
            if (project.participants) {
              match = _.filter(participantsIDS, (participant) =>
                project.participants.includes(participant)
              );
            }
            return match.length > 0 ? true : false;
          }
        }),
        "id"
      ),
      _.map(
        searchProjectsKeywords({
          projects: projects,
          keywordsIDS: keywordsIDS,
          operator: operator,
        }),
        "id"
      ),
      _.map(
        _.filter(projects, (project) => {
          if (yearsIDS.length === 0) {
            return true;
          } else {
            return yearsIDS.includes(project.year);
          }
        }),
        "id"
      ),
      _.map(
        _.filter(projects, (project) => {
          if (topicIDS.length === 0) {
            return true;
          } else {
            return topicIDS.includes(project.cluster.toString());
          }
        }),
        "id"
      )
    ).map((id) => id);

    // Get Filtered topics from filtered projects id
    let filteredTopics = _.filter(projects, (project) =>
      filteredProjects.includes(project.id)
    );
    // And topics
    filteredTopics = _.uniq(
      _.map(filteredTopics, (project) => project.cluster)
    );

    // 2. Modify dots

    // TODO: Review this count
    // it appears to be more participants than nodes in the network
    // Filtered dropdown after filter returns more results
    // then the search fails when selecting not present node
    const filteredParticipants =
      this.calculatePartipantsInFilteredProjects(filteredProjects);

    // Projects
    const filteredProjectsComplete = _.filter(
      this.state.data.topics.data,
      (project) => filteredProjects.includes(project.id)
    );

    // UPDATE AMBITS
    currentData["ambitsData"] = this.createAmbitsData({
      data: filteredProjectsComplete,
    });

    currentSummary["projects"] = filteredProjectsComplete.length;

    // If the result for projects is empty
    // deactive the topics view
    if (filteredProjectsComplete.length === 0) {
      currentFilters.zero = true;
      // d3.select("#svgNodes").classed("inactive-view", true);
    } else {
      currentFilters.zero = false;
      d3.select("#svgNodes").classed("inactive-view", false);
    }

    if (yearsIDS.length > 0) {
      currentSummary["years"] = d3.extent(yearsIDS);
    } else {
      // get the years from the filtered projects
      currentSummary["years"] = d3.extent(
        _.map(filteredProjectsComplete, (project) => project.year)
      );
    }

    currentFilters.filteredProjects =
      filteredProjects.length === projects.length ? [] : filteredProjects;
    currentFilters.filteredTopics =
      filteredTopics.length === topics.length ? [] : filteredTopics;

    // TODO
    // Review if we filter the search component with the available participants

    // How much participants for currentFiltered projects

    const activeParticipants = _.filter(
      currentFilters.populate.participantsReference.options,
      (participant) => {
        return filteredParticipants.includes(participant.value);
      }
    );

    // Extract ids from activeParticipants
    const selectedParticpantsIds = _.map(
      activeParticipants,
      (participant) => participant["organizationId"]
    );

    let selectedParticipants;

    // 1. Search participant
    // PARTICIPANTS SUMMARY
    // TODO
    let participantsInSearch = [];
    // Review this functions
    // this function calculate how much participants are related to the filtered projects
    if (participantsIDS.length === 0) {
      // Selected participants in the filtered projects
      selectedParticipants = _.filter(
        currentFilters.populate.participantsReference.options,
        (participant) => {
          return filteredParticipants.includes(participant.value);
        }
      );

      // Add filters to calculation of participants
      // activityType
      if (activityTypeIDS.length > 0) {
        selectedParticipants = _.filter(selectedParticipants, (participant) => {
          return activityTypeIDS.includes(participant["activityTypeId"]);
        });
      }
      // nuts3IDS
      if (nuts3IDS.length > 0) {
        selectedParticipants = _.filter(selectedParticipants, (participant) => {
          return nuts3IDS.includes(participant["participantNuts3Id"]);
        });
      }

      currentSummary["participants"] = selectedParticipants.length;

      // Show only selected participants (Acrivity type or nuts3) in the network

      // Get an array of ids from selected participants
      const selectedParticipantsIds = _.map(
        selectedParticipants,
        (participant) => parseInt(participant["organizationId"])
      );

      networkData = _.filter(networkData, (obj) => {
        return selectedParticipantsIds.includes(obj["organizationId"]);
      });

      // activityType
      if (activityTypeIDS.length > 0) {
        networkData = _.filter(networkData, (obj) => {
          return activityTypeIDS.includes(obj["participantType"]);
        });
      }
      // nuts3IDS
      if (nuts3IDS.length > 0) {
        networkData = _.filter(networkData, (obj) => {
          return nuts3IDS.includes(obj["participantNuts3Id"]);
        });
      }

      // Filter the participants dropdown based on the filters applied
      currentFilters.populate.participants.options = _.filter(
        currentFilters.populate.participantsReference.options,
        (participant) => {
          return selectedParticipantsIds.includes(participant.value);
        }
      );
      currentFilters.searchedParticipantIds = [];
    } else {
      networkData = _.filter(networkData, (obj) => {
        return selectedParticpantsIds.includes(obj["organizationId"]);
      });

      // Apply filters on networkData for activityType and nuts3
      // activityType
      if (activityTypeIDS.length > 0) {
        networkData = _.filter(networkData, (obj) => {
          return activityTypeIDS.includes(obj["participantType"]);
        });
      }
      // nuts3IDS
      if (nuts3IDS.length > 0) {
        networkData = _.filter(networkData, (obj) => {
          return nuts3IDS.includes(obj["participantNuts3Id"]);
        });
      }

      if (search === true) {
        // Find the participants filter
        const foundSearchParticipants = _.find(
          activeFilters,
          (filter) => filter["type"] === "participants"
        );

        if (foundSearchParticipants) {
          participantsInSearch = foundSearchParticipants["values"];
        } else {
          participantsInSearch = [];
        }

        currentFilters.searchedParticipantIds = participantsInSearch;
      } else {
        participantsInSearch = _.filter(
          currentFilters.selected,
          (filter) => filter["type"] === "participants"
        );
        currentFilters.searchedParticipantIds =
          participantsInSearch[0]["values"];
      }

      currentSummary["participants"] = participantsIDS.length;
    }

    // 2. Search keywords
    // Update keywords dropdown options to avoid search for not present keywords
    if (keywordsIDS.length === 0) {
      currentFilters.populate.keywords.options = [];
      currentFilters.populate.keywords.options = _.uniqBy(
        _.map(filteredProjectsComplete, (project) => project["keywords"])
          .flat()
          .map((keyword) => {
            return {
              value: keyword,
              label: keyword,
            };
          }),
        "value"
      );
    }

    // STATISTICS MODULES
    // Internal participants
    // For external partners
    const filteredPartnersProjectsComplete = _.filter(
      this.state.data.externalPartners,
      (project) => filteredProjects.includes(project["projectId"])
    );

    const groupedPartners = _.groupBy(
      filteredPartnersProjectsComplete,
      "organizationId"
    );

    // For internal partners (socis)
    const partnersData = processPartnersData({
      internalPartnersProjects: _.filter(
        this.state.data.internalPartners,
        (project) => filteredProjects.includes(project["projectId"])
      ),
      externalPartnersProjects: _.filter(
        this.state.data.externalPartners,
        (project) => filteredProjects.includes(project["projectId"])
      ),
      coordinatorPartnersProjects: _.filter(
        this.state.data.coordinators,
        (project) => filteredProjects.includes(project["projectId"])
      ),
      searchedParticipants: currentFilters.searchedParticipantIds,
      selectedParticipants: selectedParticipants,
    });

    // Review how is calculated the funding for participants
    // Need to cross the filtered projects with the filtered participants
    // and then sum the funding
    // 1. Filter by participants
    let filteredRegionPartners =
      participantsIDS.length > 0
        ? // If coming from search
          _.filter(currentData["internalPartners"], (partner) => {
            return participantsIDS.includes(partner["organizationId"]);
          })
        : currentData["internalPartners"];
    // Filter by active projects
    // filteredProjects
    filteredRegionPartners = _.filter(filteredRegionPartners, (partner) => {
      return filteredProjects.includes(partner["projectId"]);
    });

    // Internal
    currentStatistics["data"]["entitats"]["projects"] =
      partnersData["internalPartnersProjectsChartData"];
    currentStatistics["data"]["entitats"]["funding"] =
      partnersData["internalPartnersFundingChartData"];
    // External
    currentStatistics["data"]["exterior"]["countries"] =
      partnersData["externalPartnersByCountryData"];
    currentStatistics["data"]["exterior"]["participants"] =
      partnersData["externalPartnersByParticipantData"];
    // Coordinators
    currentStatistics["data"]["coordinators"] = {};
    currentStatistics["data"]["coordinators"]["countries"] =
      partnersData["coordinatorPartnersByCountryData"];
    currentStatistics["data"]["coordinators"]["participants"] =
      partnersData["coordinatorPartnersByParticipantData"];

    currentSummary["funding"] = _.sumBy(filteredRegionPartners, "investment");

    // SUMMARY
    currentSummary["partners"] = Object.keys(groupedPartners).length;

    // UPDATE PROGRAMS CHART DATA
    // UPDATE PROGRAMS
    // currentData["programsByYearData"] = this.createProgramsByYearData({
    //   data: filteredProjectsComplete,
    //   partnersData: networkData,
    // });

    // NETWORK
    // UPDATE NETWORK DATA AFTER APPLY FILTERS
    currentData.networkData = networkData;

    // VISUAL FILTERING OF TOPICS VIEW
    afterFiltersApply({
      totalProjects: projects,
      filteredProjects: filteredProjects,
      activeTopics: filteredTopics,
      // filteredTopics: topicIDS,
    });

    let currentZoom = this.state.zoom;
    currentZoom["topicsAction"] = "inactive";

    // 5. Update state
    this.setState({
      interactionLoading: false,
      data: currentData,
      filters: currentFilters,
      summary: currentSummary,
      statistics: currentStatistics,
      zoom: currentZoom,
    });
    // 6. Remove loading state
    setTimeout(() => {
      loadingState({ state: false });
    }, 30);
  }

  getFilteredProjects = () => this.state.filters.filteredProjects;
  getFilteredTopics = () => this.state.filters.filteredTopics;

  loadingInteraction = () => this.setState({ interactionLoading: true });

  /// SORTING

  setNetworkSortingCriteria = (obj, legendOptions) => {
    // Google Analytics
    this.state.cookiesAccepted &&
      ReactGA.event({
        category: "User interaction",
        action: "sort_network",
        label: obj["label"], // optional
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });

    let currentSorting = { ...this.state.sorting };
    currentSorting["network"] = obj;
    currentSorting["options"] = legendOptions;

    this.setState({
      sorting: currentSorting,
    });
  };

  // TOPICS
  setTopicPoints = (points, labels) => {
    let currentData = { ...this.state.data };
    currentData["topicsPoints"] = points;
    currentData["topicsLabels"] = labels;
    this.setState({
      data: currentData,
    });
  };

  getClickedDot = () => this.state.clickedDot;
  getClickedLabel = () => this.state.clickedLabel;

  // NETWORK
  getClickedParticipant = () => this.state.clickedParticipant;

  // SUMMARY
  getTotalParticipantsNumber = () =>
    this.state.filters.populate.participantsReference.length;

  // SUMMARY COMPONENT CALCULATIONS
  // Aggregate results for:
  // Projects
  // Participants
  // Funding
  // External partners
  // setSummary = ({ partnerData = null }) => {
  //   let currentSummary = { ...this.state.summary };
  //   if (partnerData !== null) {
  //     // Receive projects, groupBy and length
  //   }
  //   // 1. Aggregate results
  //   // 2. Update state
  //   // currentSummary["funding"] = _.sumBy(filteredProjectsComplete, "funding");
  //   // currentSummary["projects"] = filteredProjects.length;
  //   // this.setState({
  //   //   summary: currentSummary
  //   // })
  // };

  ///////////////////////////////////////////////////////////////////////////
  ////////// UTILS //////////////////////////////////////////////////////////

  // UI

  // Manage main Tabs
  setSelectedTab(tab) {
    // Google Analytics
    this.state.cookiesAccepted &&
      ReactGA.event({
        category: "User navigation",
        action: "main_tab_selection",
        label: tab, // optional
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });

    let currentTabs = { ...this.state.tabs };
    currentTabs.active = tab;
    // let currentZoom = this.state.zoom;
    // currentZoom["topicsAction"] = "zoomReset";

    if (tab === "topics") {
      // Manually update zoom for a proper display of the topics view
      const topicsSvg = d3.select("#svgNodes");
      let transform = topicsSvg["_groups"][0][0]["__zoom"];

      // transform.k = transform.k + 0.01;
      setTimeout(() => {
        zoomed({ transform: transform, canvasSize: this.state.canvasSize });
      }, 100);
    }
    this.setState({
      tabs: currentTabs,
      // zoom: currentZoom,
    });
  }

  // Manage statistic Tabs
  setSelectedStatisticTab(tab) {
    // Google Analytics
    this.state.cookiesAccepted &&
      ReactGA.event({
        category: "User navigation",
        action: "module_tab_selection",
        label: tab, // optional
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });

    let currentTabs = { ...this.state.statisticsTabs };
    currentTabs.active = tab;
    currentTabs.last = tab !== "" ? currentTabs.active : currentTabs.last;
    this.setState({
      statisticsTabs: currentTabs,
    });
  }

  // Manage active tool in toolsbar
  setActiveTool(tool) {
    // Google Analytics
    this.state.cookiesAccepted &&
      ReactGA.event({
        category: "User navigation",
        action: "tool_selection",
        label: tool, // optional
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });

    let currentTools = { ...this.state.tools };

    currentTools.active === tool
      ? (currentTools.active = "")
      : (currentTools.active = tool);
    this.setState({
      tools: currentTools,
    });
    return true;
  }

  // Summary component
  calculatePartipantsInFilteredProjects = (array) => {
    let projectsTotalParticipants = _.filter(
      this.state.data.topics.data,
      (project) => {
        return array.includes(project["projectId"]);
      }
    );

    projectsTotalParticipants = _.map(projectsTotalParticipants, (project) => {
      return project.participants;
    });

    projectsTotalParticipants = _.uniq(projectsTotalParticipants.flat());

    return projectsTotalParticipants;
  };

  // ZOOM
  setZoom = (type) => {
    // Follow different strategies depending on the source of the zoom
    // For the network canvas we pass a modified array of values for zooming

    let currentZoom = { ...this.state.zoom };

    let networkValues = [];
    // Network canvas case
    const networkView = d3.select("#networkCanvas");
    const networkTransform = networkView["_groups"][0][0]["__zoom"];

    // Set the value after zoom in view
    // if (!type) {
    //   networkValues = [
    //     networkTransform.k,
    //     networkTransform.x,
    //     networkTransform.y,
    //   ];
    // } else {
    //   // Differentitate zoom events
    // }
    if (type === "zoomIn") {
      networkValues = [
        networkTransform.k * 1.25,
        networkTransform.x,
        networkTransform.y,
      ];
    }
    if (type === "zoomOut") {
      networkValues = [
        networkTransform.k * 0.75,
        networkTransform.x,
        networkTransform.y,
      ];
    }
    if (type === "zoomReset") {
      networkValues = [
        0.5,
        this.state.canvasSize.width,
        this.state.canvasSize.height,
      ];
    }

    currentZoom["network"] = networkValues;

    this.setState({
      zoom: currentZoom,
      zoomActive: false,
    });
  };

  setTopicsZoom = (type) => {
    // For the topic canvas we modify the transform values directly on DOM elements
    const zoom = d3
      .zoom()
      .scaleExtent([0.25, 8])
      .on("zoom", ({ sourceEvent, transform }) => {
        return zoomed({
          sourceEvent: sourceEvent,
          transform: transform,
        });
      });
    // if (source === "topics") {
    // Topics svg case
    const topicsSvg = d3.select("#svgNodes");
    const topicsTransform = topicsSvg["_groups"][0][0]["__zoom"];
    // Differentitate zoom events
    let transform;
    if (!type) {
      transform = {
        x: topicsTransform.x,
        y: topicsTransform.y,
        k: topicsTransform.k,
      };
    }
    if (type === "zoomIn") {
      transform = {
        x:
          topicsTransform.x -
          (this.state.canvasSize.width / 2) * 1.25 +
          this.state.canvasSize.width / 2,
        // x: topicsTransform.x,
        y:
          topicsTransform.y -
          (this.state.canvasSize.height / 2) * 1.25 +
          this.state.canvasSize.height / 2,
        // y: topicsTransform.y,
        k: topicsTransform.k * 1.25,
      };
    }
    if (type === "zoomOut") {
      transform = {
        // x: topicsTransform.x,
        x:
          topicsTransform.x -
          (this.state.canvasSize.width / 2) * 0.75 +
          this.state.canvasSize.width / 2,
        // y: topicsTransform.y,
        y:
          topicsTransform.y -
          (this.state.canvasSize.height / 2) * 0.75 +
          this.state.canvasSize.height / 2,
        k: topicsTransform.k * 0.75,
      };
    }
    if (type === "zoomReset") {
      transform = {
        x: 0,
        y: 0,
        k: 1,
      };
    }
    topicsSvg.call(zoom.scaleTo, transform.k);
    // TODO
    // Manage the translation [x, y] when manually zooming
    // topicsSvg.call(zoom.translateBy, transform.x, transform.y);
    zoomed({ transform: transform, canvasSize: this.state.canvasSize });
    // let currentZoom = { ...this.state.zoomTopics };
    // currentZoom = type;
    // this.setState({
    //   zoomTopics: currentZoom,
    // });
  };

  setZoomInactive = () => {
    let currentZoom = { ...this.state.zoomTopics };
    currentZoom = "inactive";
    this.setState({
      zoomTopics: currentZoom,
    });
  };

  setCanvasSize = (width, height) => {
    this.setState({
      canvasSize: {
        width: width,
        height: height,
      },
    });
  };

  setVisibilityLayers = (layer, value) => {
    let currentLayers = { ...this.state.visibilityLayers };
    currentLayers[layer] = value;
    this.setState({
      visibilityLayers: currentLayers,
    });
  };

  // TOPIC OPEN DOT INFO
  openDotInfo = (projectId) => {
    // Google Analytics
    this.state.cookiesAccepted &&
      ReactGA.event({
        category: "User navigation",
        action: "project_info_opened",
        label: _.find(
          this.state.data.topics.data,
          (project) => project["id"] === projectId
        )["title"], // optional
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });

    this.setState({
      isDotInfoOpened: true,
      clickedDot: projectId,
    });
  };

  closeDotInfo = () => {
    this.setState({
      isDotInfoOpened: false,
      clickedDot: null,
      clickedLabel: null,
    });
    dotHandleOut({
      filteredDots: this.getFilteredProjects(),
      filteredTopics: this.getFilteredTopics(),
    });
  };

  // NETWORK OPEN NODE INFO
  openNodeInfo = (nodeId) => {
    // Google Analytics
    this.state.cookiesAccepted &&
      ReactGA.event({
        category: "User navigation",
        action: "participant_info_opened",
        label: _.find(
          this.state.filters.populate.participantsReference.options,
          (participant) => participant["organizationId"] === nodeId
        )["label"], // optional
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });

    this.setState({
      isNodeInfoOpened: true,
      clickedNode: nodeId,
    });
  };

  closeNodeInfo = () => {
    this.setState({
      isNodeInfoOpened: false,
      clickedNode: null,
    });
  };

  // NETWORK
  setPickedNodes = (array) => {
    let currentFilters = { ...this.state.filters };
    currentFilters["pickedNodes"] = array;
    this.setState({
      filters: currentFilters,
    });
  };

  // LANGUAGE SELECTOR
  setLang = async (lang) => {
    // Translate filters dropdowns to current language
    let currentFilters = { ...this.state.filters };
    let currentData = { ...this.state.data };

    // Translate ambits d'actuació to current language
    let dropdownCategoryName = currentFilters["populate"]["categoryName"];
    // Translate dropdownCategoryName to current language
    _.map(dropdownCategoryName.options, (option) => {
      option.label = getTranslation({
        lang: lang,
        key:
          "FILTER_PANEL.FILTERS.CATEGORY_NAME.OPTIONS." +
          _.camelCase(option.value),
      });
      return option;
    });
    currentFilters["categoryName"] = dropdownCategoryName;

    // Translate topics to current language
    let dropdownTopics = currentFilters["populate"]["topics"];
    // Translate dropdownTopics to current language
    _.map(dropdownTopics.options, (option) => {
      // Find the topic["cluster"] in the state topicsTranslations
      const found = _.find(
        this.state.topicsTranslations,
        (t) => t["topic_num"] === option.value
      );
      option.label = found["topic_label_" + lang];
      return option;
    });

    currentFilters["topics"] = dropdownTopics;

    // Translate labels on data topics to current language
    currentData["topics"]["topics"] = _.map(
      currentData.topics.topics,
      (topic) => {
        // Find the topic["cluster"] in the state topicsTranslations
        const found = _.find(
          this.state.topicsTranslations,
          (t) => t["topic_num"] === topic["cluster"]
        );
      
        if (found) {
          topic["label"] = found["topic_label_" + lang];
          topic["sort"] = found["topic_label_" + lang];
        } else {
          topic["label"] = "No translation available"
        }
        return topic;
      }
    );

    // Translate SDGs to current language
    let dropdownSDGs = currentFilters["populate"]["SDGs"];
    // Translate dropdownSDGs to current language
    _.map(dropdownSDGs.options, (option) => {
      option.label = getTranslation({
        lang: lang,
        key: "FILTER_PANEL.FILTERS.SDGS.OPTIONS." + option.TRANSLATION_KEY,
      });
      return option;
    });
    currentFilters["SDGs"] = dropdownSDGs;

    // Translate frameworks to current language
    let dropdownFrameworks = currentFilters["populate"]["frameworks"];
    // Translate dropdownFrameworks to current language
    _.map(dropdownFrameworks.options, (option) => {
      option.label = getTranslation({
        lang: lang,
        key:
          "FILTER_PANEL.FILTERS.FRAMEWORKS.OPTIONS." + option.TRANSLATION_KEY,
      });
      return option;
    });
    currentFilters["frameworks"] = dropdownFrameworks;

    // Translate activityType to current language
    let dropdownActivityType = currentFilters["populate"]["activityType"];
    // Translate dropdownActivityType to current language
    _.map(dropdownActivityType.options, (option) => {
      option.label = getTranslation({
        lang: lang,
        key: "FILTER_PANEL.FILTERS.ACTIVITY_TYPE.OPTIONS." + option.value,
      });
      return option;
    });
    currentFilters["activityType"] = dropdownActivityType;

    // Translate country names to current language
    const COUNTRIES = await d3.csv("./data-ris3cat/map_countries.csv");
    let currentStatistics = { ...this.state.statistics };
    // Participants del exterior
    let currentExternalParticipants = currentStatistics["data"]["exterior"];
    // For each entry in countries translate the name to current language
    // use Object keys to iterate over the object
    _.map(
      Object.values(currentExternalParticipants["countries"]),
      (country) => {
        // Translate country name to current language
        _.map(country, (participant) => {
          const foundValues = _.find(
            COUNTRIES,
            (c) =>
              c["country_" + this.state.language] === participant.countryName
          );

          if (foundValues) {
            participant.countryName = foundValues["country_" + lang];
          }
          return participant;
        });
        return country;
      }
    );

    // Change the name of the key to current language
    currentExternalParticipants["countries"] = _.mapKeys(
      currentExternalParticipants["countries"],
      (value, key) => {
        const foundKey = _.find(
          COUNTRIES,
          (c) => c["country_" + this.state.language] === key
        );
        if (foundKey) {
          return foundKey["country_" + lang];
        } else {
          return key;
        }
      }
    );

    currentStatistics["data"]["exterior"] = currentExternalParticipants;

    // Participants coordinators
    let currentCoordinators = currentStatistics["data"]["coordinators"];
    // For each entry in countries translate the name to current language
    // use Object keys to iterate over the object
    _.map(Object.values(currentCoordinators["countries"]), (country) => {
      // Translate country name to current language
      _.map(country, (participant) => {
        const foundValues = _.find(
          COUNTRIES,
          (c) => c["country_" + this.state.language] === participant.countryName
        );

        if (foundValues) {
          participant.countryName = foundValues["country_" + lang];
        }
        return participant;
      });
      return country;
    });

    // Change the name of the key to current language
    currentCoordinators["countries"] = _.mapKeys(
      currentCoordinators["countries"],
      (value, key) => {
        const foundKey = _.find(
          COUNTRIES,
          (c) => c["country_" + this.state.language] === key
        );
        if (foundKey) {
          return foundKey["country_" + lang];
        } else {
          return key;
        }
      }
    );

    currentStatistics["data"]["coordinators"] = currentCoordinators;

    // Close the tools bar
    let currentTools = { ...this.state.tools };
    currentTools["active"] = "";

    // Close the statistics module
    let currentStaticticTabs = { ...this.state.statisticsTabs };
    currentStaticticTabs["active"] = "";

    // Save the lang in a cookie
    const cookies = new Cookies(null, { path: "/" });
    cookies.set("lang", lang, {
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
    });
    this.setState({
      language: lang,
      filters: currentFilters,
      statistics: currentStatistics,
      data: currentData,
      tools: currentTools,
      statisticsTabs: currentStaticticTabs,
    });
  };

  // Cookies management
  handleAcceptCookies = (val) => {
    // const { cookies } = this.props;
    const cookies = new Cookies(null, { path: "/" });

    if (val === true) {
      cookies.set("acceptCookies", val, {
        expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
      });
      // set cookie for language
      cookies.set("lang", this.state.language, {
        expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
      });
      // Google Analytics
      ReactGA.initialize("G-MQRM13NKKR");
    }

    this.setState({
      cookiesAccepted: val,
      showCookiesBanner: false,
    });
  };

  async componentDidMount() {
    // LOAD STATIC FILES COMING FROM BERT
    // load coords
    await this.fetchProjectsCoords();
    // load translations
    await this.fetchTopicsTranslations();
    // load topics
    await this.fetchTopics();

    // load funding for projects
    // 1. Get all projects Id
    // const projectsIds = _.map(
    //   this.state.data.topics.data,
    //   (project) => project.id
    // );

    // projectsIds = _.filter(projectsIds, id => id.includes('CORDIS'))

    await this.fetchProjectsInformation();
    await this.fetchProjectsAmbits();
    await this.fetchProjectsKeywords();
    await this.fetchProjectsPartners();
    await this.fetchProjectSDGs();

    // Call create RAW DATA FOR NETWORK
    this.createRawData();
    let currentData = { ...this.state.data };
    // Create ambits d'actuació chart data
    currentData["ambitsData"] = this.createAmbitsData({
      data: this.state.data.topics.data,
    });

    // Create program by year chart data
    // currentData["programsByYearData"] = this.createProgramsByYearData({
    //   data: this.state.data.topics.data,
    //   partnersData: this.state.data.rawData,
    // });

    // CREATE COLOR SCALE
    const colorScale = d3
      .scaleLinear()
      .domain(
        d3.extent(this.state.data.topics.topics, (topic) => topic.cluster)
      )
      .range([0, 1]);

    // SET INITIAL FILTER FOR CHECKING POURPOSES
    // let currentFilters = { ...this.state.filters };
    // currentFilters.selected.push({
    //   type: "categoryName",
    //   values: ["Sistema industrial"],
    // });

    // const that = this;
    // setTimeout(function () {
    //   that.displayFilters({
    //     filters: currentFilters,
    //     activeFilters: currentFilters.selected,
    //   });
    // }, 10000)

    // SET INITIAL TOOL as visible
    // let currentTools = { ...this.state.tools };
    // currentTools.active = "filter";

    let currentInitialStateData = { ...this.state.initialStateData };
    currentInitialStateData["data"] = currentData;
    currentInitialStateData["filters"] = this.state.filters;
    currentInitialStateData["summary"] = this.state.summary;

    this.setState({
      loading: false,
      colorScale: colorScale,
      data: currentData,
      initialStateData: currentInitialStateData,
    });

    // console.log("***** DATA LOADED *****");
    // console.log("- STATE", this.state);
  }

  render() {
    return (
      <Context.Provider
        value={{
          // FILTERS
          setFilteredProjects: this.setFilteredProjects,
          setSearch: this.setSearch,
          setFilters: this.setFilters,
          resetAllFilters: this.resetAllFilters,
          resetAllSearches: this.resetAllSearches,
          setClickedAmbit: this.setClickedAmbit,
          loadingInteraction: this.loadingInteraction,
          // SORTING
          setNetworkSortingCriteria: this.setNetworkSortingCriteria,
          // TABS
          setSelectedTab: this.setSelectedTab,
          setSelectedStatisticTab: this.setSelectedStatisticTab,
          setActiveTool: this.setActiveTool,
          getFilteredProjects: this.getFilteredProjects,
          getFilteredTopics: this.getFilteredTopics,
          getTopicInformation: this.getTopicInformation,
          getProjectInformation: this.getProjectInformation,
          getClickedDot: this.getClickedDot,
          getClickedLabel: this.getClickedLabel,
          // TOPICS
          setTopicPoints: this.setTopicPoints,
          // INTERACTION TOPICS
          dotHandleOver: dotHandleOver,
          dotHandleOut: dotHandleOut,
          labelHandleOver: labelHandleOver,
          // TOPIC OPEN DOT INFO
          openDotInfo: this.openDotInfo,
          closeDotInfo: this.closeDotInfo,
          // TOPIC LABEL SELECTION
          selectTopicLabel: this.selectTopicLabel,
          // NETWORK OPEN NODE INFO
          openNodeInfo: this.openNodeInfo,
          closeNodeInfo: this.closeNodeInfo,
          // NETWORK
          setPickedNodes: this.setPickedNodes,
          // OPEN PROJECT WINDOW
          setClickedProject: this.setClickedProject,
          // ZOOM
          setZoom: this.setZoom,
          setTopicsZoom: this.setTopicsZoom,
          // setZoomActive: this.setZoomActive,
          setZoomInactive: this.setZoomInactive,
          setVisibilityLayers: this.setVisibilityLayers,
          setCanvasSize: this.setCanvasSize,
          // DOWNLOAD
          dwnldExcel: this.dwnldExcel,
          dwnldPartnersExcel: this.dwnldPartnersExcel,
          // LANGUAGE
          setLang: this.setLang,
          // COOKIES
          handleAcceptCookies: this.handleAcceptCookies,
          // colorScale: this.colorScale,
          ...this.state,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}
