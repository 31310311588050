import React, { Component, Fragment } from "react";
import _ from "lodash";
import "./index.scss";

import { StatisticsBars } from "../StatisticsBars";

class BarsSender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (
      _.isEqual(this.props.chartData, nextProps.chartData) &&
      this.props.clickedCountry === nextProps.clickedCountry &&
      this.props.clickedNUTS2 === nextProps.clickedNUTS2 &&
      this.props.interactionLevel === nextProps.interactionLevel
    ) {
      // console.log("BARS SENDER -> DO NOTHING");
      return false;
    } else {
      // console.log("BARS SENDER -> UPDATE");
      return true;
    }
  }

  render() {
    const {
      width,
      height,
      chartData,
      type,
      interaction,
      handleHoverInteraction,
      handleOutInteraction,
      handleClickInteraction,
      labelsWidth,
      interactionLevel,
    } = this.props;

    return (
      <Fragment>
        <div className="bars-sender-container">
          <StatisticsBars
            width={width}
            height={height}
            chartData={chartData}
            type={type}
            interaction={interaction}
            handleHoverInteraction={handleHoverInteraction}
            handleOutInteraction={handleOutInteraction}
            handleClickInteraction={handleClickInteraction}
            interactionLevel={interactionLevel}
            labelsWidth={labelsWidth}
          ></StatisticsBars>
        </div>
      </Fragment>
    );
  }
}

export default BarsSender;
