import * as d3 from "d3";
import _ from "lodash";
import { CONFIG } from "../../common/constants";

export const dotHandleOver = function ({
  selectedDot = null,
  clickedDot = null,
  filteredDots = [],
  filteredTopics = [],
}) {
  // console.log("DOT HANDLE OVER");
  // console.log("-> SELECTED DOT", selectedDot);
  // console.log("-> CLICKED DOT", clickedDot);
  // console.log("-> FILTERED TOPICS", filteredTopics);

  d3.selectAll(".projectNode").style("opacity", (d) => {
    if (selectedDot["id"] === d["id"] || clickedDot === d["id"]) {
      return CONFIG.TOPICS.LAYOUT.OPACITIES.FULL;
    } else if (filteredDots.length > 0) {
      // Activate the dots in the same cluster
      if (
        d["cluster"] === selectedDot["cluster"] ||
        filteredDots.includes(d["id"])
      ) {
        return CONFIG.TOPICS.LAYOUT.OPACITIES.ACTIVE_DOT;
      } else {
        return CONFIG.TOPICS.LAYOUT.OPACITIES.INACTIVE;
      }
    } else {
      return CONFIG.TOPICS.LAYOUT.OPACITIES.INACTIVE;
    }
  });

  // LABELS
  // Activate the label for hovered project
  d3.selectAll("#label" + selectedDot["cluster"])
    .style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.FULL)
    .style("letter-spacing", "-0.25px")
    .style("font-weight", "bold");
};

export const dotHandleOut = function ({
  clickedDot = null,
  filteredDots = [],
  clickedLabel = null,
  filteredTopics = [],
}) {
  // console.log("DOT HANDLE OUT");
  // console.log("-> CLICKED DOT", clickedDot);
  // console.log("-> FILTERED DOTS", filteredDots);
  // console.log("-> CLICKED LABEL", clickedLabel);
  // console.log("-> FILTERED TOPICS", filteredTopics);

  d3.selectAll(".projectNode").style("opacity", (d) => {
    if (clickedDot !== null) {
      if (clickedDot === d["id"]) {
        return CONFIG.TOPICS.LAYOUT.OPACITIES.FULL;
      } else {
        return CONFIG.TOPICS.LAYOUT.OPACITIES.INACTIVE;
      }
    } else {
      if (filteredDots.length > 0) {
        if (filteredDots.includes(d.id)) {
          return CONFIG.TOPICS.LAYOUT.OPACITIES.ACTIVE_DOT;
        } else {
          return CONFIG.TOPICS.LAYOUT.OPACITIES.INACTIVE;
        }
      } else {
        if (clickedLabel) {
          if (d["cluster"] === Number(clickedLabel)) {
            return CONFIG.TOPICS.LAYOUT.OPACITIES.ACTIVE_DOT;
          } else {
            return CONFIG.TOPICS.LAYOUT.OPACITIES.INACTIVE;
          }
        }
      }
    }
  });
  // LABELS
  handleFilteredLabels({ filteredTopics: filteredTopics });
};

// Visibility of dots and labels after filters apply
export const afterFiltersApply = function ({
  totalProjects = [],
  filteredProjects = [],
  activeTopics = [],
  // filteredTopics = [],
}) {
  // console.log("AFTER FILTERS APPLY");
  // console.log("-> TOTAL PROJECTS", totalProjects);
  // console.log("-> FILTERED PROJECTS", filteredProjects);
  // console.log("-> ACTIVE TOPICS", activeTopics);

  // DOTS
  const newNodes = d3.selectAll(".projectNode");
  newNodes
    .style("opacity", (d) => {
      if (
        filteredProjects.length === 0 ||
        filteredProjects.length === totalProjects.length
      ) {
        return CONFIG.TOPICS.LAYOUT.OPACITIES.ACTIVE_DOT;
      } else {
        if (filteredProjects.includes(d.id)) {
          return CONFIG.TOPICS.LAYOUT.OPACITIES.ACTIVE_DOT;
        } else {
          return CONFIG.TOPICS.LAYOUT.OPACITIES.INACTIVE;
        }
      }
    })
    .style("pointer-events", (d) => {
      if (
        filteredProjects.length === 0 ||
        filteredProjects.length === totalProjects.length
      ) {
        return "fill";
      } else {
        if (filteredProjects.includes(d.id)) {
          return "fill";
        } else {
          return "none";
        }
      }
    });

  // LABELS
  handleFilteredLabels({
    filteredTopics: activeTopics,
  });
};

export const labelHandleOver = function ({
  selectedCluster = null,
  filteredDots = [],
  filteredTopics = [], // same as activeTopics after filtering
}) {
  // console.log("LABEL HANDLE OVER");
  // console.log("-> SELECTED CLUSTER", selectedCluster);
  // console.log("-> FILTERED DOTS", filteredDots);
  // console.log("-> FILTERED TOPICS", filteredTopics);

  selectedCluster = Number(selectedCluster);

  d3.selectAll(".projectNode").style("opacity", (d) => {
    // If dots are filtered or the selected one then show them
    if (d["cluster"] === selectedCluster || filteredDots.includes(d.id)) {
      return CONFIG.TOPICS.LAYOUT.OPACITIES.ACTIVE_DOT;
    } else {
      return CONFIG.TOPICS.LAYOUT.OPACITIES.INACTIVE;
    }
  });

  handleFilteredLabels({
    activeTopic: selectedCluster,
    filteredTopics: filteredTopics,
  });
};

export const handleFilteredLabels = function ({
  filteredTopics = [],
  activeTopic = null,
}) {
  // console.log("---------- HANDLE FILTERED LABELS");
  // console.log("-> FILTERED TOPICS", filteredTopics);
  // console.log("-> ACTIVE TOPIC", activeTopic);

  // Manage labels state is is there a filter applied
  if (filteredTopics.length > 0 && filteredTopics.length < 50) {
    // Apply opacity to all labels
    d3.selectAll(".label")
      .style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.NORMAL)
      .style("font-weight", "normal")
      .style("letter-spacing", "0px");
    // Deactive click on label rects
    // d3.selectAll(".label-back-rect").style("pointer-events", "none");
    // Hide all clusters densities
    d3.selectAll(".cluster-container").style("opacity", 0.3);

    // Apply opacity to active labels
    _.map(filteredTopics, (id) => {
      // Increase opacity to active labels
      d3.selectAll("#label" + id)
        .style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.FULL)
        .style("font-weight", "normal")
        .style("letter-spacing", "0px");
      // Activate click on selected topics label rects
      // d3.selectAll("#rectLabel" + id + ".label-back-rect").style(
      //   "pointer-events",
      //   "fill"
      // );
      // Show selected clusters densities
      d3.selectAll("#id" + id).style("opacity", 1);
    });
  } else {
    // It's the initial state
    // Reset label to white background and topic color text
    d3.selectAll(".label")
      .style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.ACTIVE)
      .style("font-weight", "normal")
      .style("letter-spacing", "0px");
    // Reset click on label rects
    // d3.selectAll(".label-back-rect").style("pointer-events", "fill");
    // Reset opacity to all clusters
    d3.selectAll(".cluster-container").style("opacity", 0.3);
    d3.selectAll("#id" + activeTopic).style("opacity", 1);
  }

  // Manage hovered / active topic
  if (activeTopic !== null) {
    d3.selectAll("#label" + activeTopic).style(
      "opacity",
      CONFIG.TOPICS.LAYOUT.OPACITIES.FULL
    );
  }
};

export const clickOnCanvas = function () {

  
  d3.selectAll(".label")
    .style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.ACTIVE)
    .style("font-weight", "normal")
    .style("letter-spacing", "0px");
};

export const loadingState = function ({
  state = false,
  canvasSize = { width: 0, height: 0 },
  textNetwork = "S'està actualitzant la xarxa",
  textTopics = "S'està actualitzant el mapa de projectes",
}) {
  // Topics canvas
  const canvasTopics = d3.select("#canvas-options-tabpane-topics");
  const canvasNetwork = d3.select("#canvas-options-tabpane-network");
  const bgFill = "rgba(255,255,255,0.75)";

  if (state === true) {
    // Change opacity
    // svgNodes.attr("opacity", 0.2);
    // Add an informative text

    // SVG
    const topicsLoader = canvasTopics
      .append("div")
      .attr("id", "topicsLoadingText")
      .attr("class", "loader-container");
    topicsLoader
      .append("div")
      .attr("class", "loader")
      .style("position", "absolute")
      .style("top", CONFIG.APP.LAYOUT.MAIN_TABS_HEIGHT + "px")
      .style("left", "0")
      .style("z-index", "1000")
      .style("width", "100vw")
      .style("height", "96vh")
      .style("line-height", canvasSize.height + "px")
      .style("text-align", "center")
      .style("background-color", bgFill)
      .html(
        "<img src='./images/loading-small.gif' class='loading-spinner' />" +
          textTopics
      );
    // Canvas
    const canvasLoader = canvasNetwork
      .append("div")
      .attr("id", "canvasLoadingText");
    canvasLoader
      .append("div")
      .style("position", "absolute")
      .style("top", "0")
      .style("left", "0")
      .style("z-index", "1000")
      .style("width", canvasSize.width + "px")
      .style("height", canvasSize.height + "px")
      .style("line-height", canvasSize.height + "px")
      .style("text-align", "center")
      .style("background-color", bgFill)
      .html(
        "<img src='./images/loading-small.gif' class='loading-spinner' />" +
          textNetwork
      );
  } else {
    // Reset opacity
    // svgNodes.attr("opacity", 1);
    // Remove loading text
    d3.select("#canvasLoadingText").remove();
    d3.selectAll("#svgLoadingText").remove();
    d3.selectAll("#topicsLoadingText").remove();
  }
};