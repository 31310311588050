import _ from "lodash";

export const processPartnersData = ({
  internalPartnersProjects = null,
  externalPartnersProjects = null,
  coordinatorPartnersProjects = null,
  selectedParticipants = [],
}) => {
  // console.log(">>>>>>> processPartnersData");
  // console.log(">>>>>>> searchedParticipants", searchedParticipants);
  // Prepare data for entitats
  // Group by organizationId
  // INTERNAL PARTNERS
  // PARTICIPANTS
  // SOCIS DE CATALUNYA

  // Get an array of ids from selected participants
  const selectedParticipantsIds = _.map(selectedParticipants, (participant) =>
    parseInt(participant["organizationId"])
  );

  if (selectedParticipants.length > 0) {
    internalPartnersProjects = _.filter(internalPartnersProjects, (project) =>
      selectedParticipantsIds.includes(project["organizationId"])
    );
  }

  const groupedInternalPartners = _.groupBy(
    internalPartnersProjects,
    "organizationId"
  );

  let internalPartnersProjectsChartData = [];
  let internalPartnersFundingChartData = [];
  // By projects (count);
  Object.entries(groupedInternalPartners).forEach(function ([key, values]) {
    // console.log("values", values)
    internalPartnersProjectsChartData.push({
      label: values[0]["organizationName"],
      organizationId: values[0]["organizationId"],
      value: values.length,
      activityTypeId: values[0]["activityTypeId"],
      participantNuts3Id: values[0]["participantNuts3Id"],
    });
    internalPartnersFundingChartData.push({
      label: values[0]["organizationName"],
      organizationId: values[0]["organizationId"],
      value: _.sumBy(values, "investment"),
      activityTypeId: values[0]["activityTypeId"],
      participantNuts3Id: values[0]["participantNuts3Id"],
    });
  });

  internalPartnersProjectsChartData = _.orderBy(
    internalPartnersProjectsChartData,
    ["value"],
    ["desc"]
  );

  internalPartnersFundingChartData = _.orderBy(
    internalPartnersFundingChartData,
    ["value"],
    ["desc"]
  );

  // SOCIS DE L'EXTERIOR
  // Data grouped by country
  const groupedExternalPartnersByCountry = _.groupBy(
    externalPartnersProjects,
    "countryName"
  );
  const groupedExternalPartnersByParticipant = _.groupBy(
    externalPartnersProjects,
    "organizationId"
  );

  // COORDINATORS
  const groupedCoordinatorPartnersByCountry = _.groupBy(
    coordinatorPartnersProjects,
    "countryName"
  );
  const groupedCoordinatorPartnersByParticipant = _.groupBy(
    coordinatorPartnersProjects,
    "organizationId"
  );

  const result = {
    internalPartnersProjectsChartData: internalPartnersProjectsChartData,
    internalPartnersFundingChartData: internalPartnersFundingChartData,
    externalPartnersByCountryData: groupedExternalPartnersByCountry,
    externalPartnersByParticipantData: groupedExternalPartnersByParticipant,
    coordinatorPartnersByCountryData: groupedCoordinatorPartnersByCountry,
    coordinatorPartnersByParticipantData:
      groupedCoordinatorPartnersByParticipant,
  };

  return result;
};
