import React, { Component, Fragment } from "react";
import * as d3 from "d3";
import _ from "lodash";

import { getProjectParticipantsInformation } from "../../data/DataService";

import { FaTimesCircle } from "react-icons/fa";

import "./index.scss";

import { FORMAT_EURO } from "../../common/constants";

import { getTranslation } from "../../common/translations";

class ProjectInfoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    if (this.props.clickedProject) {
      let participantsInProject = await getProjectParticipantsInformation(
        this.props.clickedProject
      );
      let partnersInProject = this.getPartnersInformation(
        this.props.clickedProject
      );
      this.setState({
        projectParticipantsInfo: participantsInProject,
        partnersInProject: partnersInProject,
        loading: false,
      });
    }
  }

  async componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.clickedProject !== prevProps.clickedProject) {
      let participantsInProject = await getProjectParticipantsInformation(
        this.props.clickedProject
      );
      let partnersInProject = this.getPartnersInformation(
        this.props.clickedProject
      );
      this.setState({
        projectParticipantsInfo: participantsInProject,
        partnersInProject: partnersInProject,
      });
    }
  }

  getPartnersInformation = (projectId) => {
    // console.log("GET_PARTNERS_INFORMATION");
    // console.log("PROJECT_ID", projectId);
    const externalPartnersProjects = this.props.data.externalPartners;
    // console.log("EXTERNAL_PARTNERS_PROJECTS", externalPartnersProjects);
    const currentProjectExternalPartners = _.filter(
      externalPartnersProjects,
      (project) => project["projectId"] === projectId
    );

    return currentProjectExternalPartners;
  };

  // TODO
  // Hightlight the searched keywords in the text
  highlightKeywords = (text, querystr) => {
    const reg = new RegExp(querystr, "gi");
    const finalString = text.replace(reg, '<span class="highlight">$&</span>');
    return finalString;
  };

  render() {
    const { loading, projectParticipantsInfo, partnersInProject } = this.state;
    const { filters, clickedNode, language } = this.props;

    // Get the searched keywords
    const foundFilter = _.find(
      filters["selected"],
      (filter) => filter["type"] === "keywords"
    );

    // Need to process the participant information to calculate the investment for CORDIS projects
    // console.log("PROJECT PARTICIPANTS INFO", projectParticipantsInfo);
    const completeParticipantsInfo = _.map(projectParticipantsInfo, (el) => {
      if (el["investment"] === null) {
        el["investment"] =
          el["grant"] * (el["totalInvestment"] / el["totalGrant"]);
      }
      return el;
    });

    const currentProject = this.props.getProjectInformation(
      this.props.clickedProject
    );

    // const currentTopic = this.props.getTopicInformation(
    //   Number(this.props.clickedLabel)
    // );

    const that = this;

    d3.select(".project-info-modal.lightbox").on("click", function (e) {
      if (e["target"]["id"] === "modalBackdrop") {
        return that.props.setClickedProject({ id: null });
      } else {
        return false;
      }
      // e.stopPropagation()
    });

    return (
      <Fragment>
        {loading ? (
          <p>Carregant</p>
        ) : (
          <div id="modalBackdrop" className="project-info-modal lightbox">
            <div className="project-modal">
              <div className="project-modal-header container-fluid">
                <button
                  className="btn btn-close-white"
                  onClick={() => this.props.setClickedProject({ id: null })}
                >
                  <FaTimesCircle></FaTimesCircle>
                </button>
                <div className="row">
                  <div className="col">
                    <h1 className="pe-5">{currentProject[0].title}</h1>
                    <h3>{currentProject[0]["year"]}</h3>
                  </div>
                </div>
              </div>
              <div className="container-fluid project-modal-body">
                <div className="row">
                  <div className="col col-5 project-modal-abstract">
                    <div className="col">
                      <h6 className="indicator-label">
                        {getTranslation({
                          lang: language,
                          key: "MODAL_WINDOWS.PROJECT.DESCRIPTION",
                        })}
                      </h6>
                      <p>{currentProject[0].abstract}</p>
                    </div>
                  </div>
                  <div className="col project-modal-indicators-container">
                    {/* <div className="container-fluid"> */}
                    <div className="row modal-internal-indicators-container">
                      <div className="col col-3">
                        <div className="indicator">
                          <h6 className="indicator-label">
                            {getTranslation({
                              lang: language,
                              key: "MODAL_WINDOWS.PROJECT.FUNDING",
                            })}
                          </h6>
                          <h5>{FORMAT_EURO(currentProject[0].funding)}</h5>
                        </div>
                        <div className="indicator">
                          <h6 className="indicator-label">
                            {getTranslation({
                              lang: language,
                              key: "MODAL_WINDOWS.PROJECT.GRANT",
                            })}
                          </h6>
                          <h5>{FORMAT_EURO(currentProject[0].grant)}</h5>
                        </div>
                      </div>
                      <div className="col col-9">
                        <h6 className="indicator-label">
                          {getTranslation({
                            lang: language,
                            key: "MODAL_WINDOWS.PROJECT.PROGRAM",
                          })}
                        </h6>
                        <h4>
                          {
                            // Split the formattedValue key by character -
                            // and get the fisrt element of the array
                            // to get the value of the program
                            getTranslation({
                              lang: language,
                              key:
                                "FILTER_PANEL.FILTERS.FRAMEWORKS.OPTIONS." +
                                _.camelCase(
                                  currentProject[0]["programaName"]
                                    .split("-")[0]
                                    .trim()
                                ),
                            }) +
                              " - " +
                              currentProject[0]["programaName"]
                                .split(" - ")[1]
                                .trim()
                          }
                        </h4>
                        <h6 className="indicator-label">
                          {getTranslation({
                            lang: language,
                            key: "MODAL_WINDOWS.PROJECT.AMBITS",
                          })}
                        </h6>
                        {_.map(
                          currentProject[0]["categoryName"],
                          (ambit, i) => {
                            return (
                              <h5 className="indicator-pill" key={"ambit-" + i}>
                                {getTranslation({
                                  lang: language,
                                  key:
                                    "FILTER_PANEL.FILTERS.CATEGORY_NAME.OPTIONS." +
                                    _.camelCase(ambit),
                                })}
                              </h5>
                            );
                          }
                        )}
                        {currentProject[0]["keywords"].length > 0 && (
                          <h6 className="indicator-label">
                            {getTranslation({
                              lang: language,
                              key: "MODAL_WINDOWS.PROJECT.KEYWORDS",
                            })}
                          </h6>
                        )}
                        {currentProject[0]["keywords"].length > 0 && (
                          <ul className="tags">
                            {_.map(
                              currentProject[0]["keywords"],
                              (keyword, i) => {
                                return (
                                  <li key={"keyword-" + i}>
                                    <span
                                      className={`badge text-bg-light border me-1 ${
                                        foundFilter &&
                                        foundFilter["values"].includes(keyword)
                                          ? "bg-dark text-white"
                                          : "text-dark"
                                      }`}
                                    >
                                      {keyword}
                                    </span>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        )}
                      </div>
                    </div>
                    <div className="row modal-internal-scrolling-container">
                      <div className="col col-3">
                        <div className="indicator">
                          <h6 className="indicator-label">
                            {getTranslation({
                              lang: language,
                              key: "MODAL_WINDOWS.PROJECT.ENTITIES",
                            })}
                          </h6>
                          <h5>
                            {currentProject[0]["participants"].length +
                              partnersInProject.length}
                          </h5>
                        </div>
                      </div>
                      <div className="col col-9">
                        <div className="modal-internal-scrolling">
                          <ul>
                            {_.map(completeParticipantsInfo, (el, i) => {
                              return (
                                <li
                                  key={`participant-${i}`}
                                  className={
                                    el["organizationId"] === clickedNode
                                      ? "text-danger"
                                      : ""
                                  }
                                >
                                  <strong>{el["organizationName"]}</strong>
                                  <small className="text-muted">
                                    <strong>
                                      {FORMAT_EURO(el["investment"])}
                                    </strong>
                                  </small>
                                </li>
                              );
                            })}
                            {_.map(partnersInProject, (el, i) => {
                              return (
                                <li key={`partner-${i}`}>
                                  {_.escape(el["organizationName"])}
                                  &nbsp;-&nbsp;
                                  {el["countryName"] === "Spain"
                                    ? el["participantNuts2Name"]
                                    : el["countryName"]}
                                  <small className="text-muted">
                                    {FORMAT_EURO(el["investment"])}
                                  </small>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default ProjectInfoModal;
