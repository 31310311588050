import * as d3 from "d3";

export const zoomed = function ({
  sourceEvent = null,
  transform = null,
}) {

  // console.log("--------------- ZOOMED");
  // console.log("SOURCE_EVENT", sourceEvent);
  // console.log("TRANSFORM", transform);

  // d3.select("#svgNodes").attr("opacity", 1).attr("fill", "transparent");
  if (sourceEvent) {
    if (sourceEvent.type === "wheel") {
      document.body.style.cursor = "zoom-in";
    } else {
      document.body.style.cursor = "grab";
    }
  } else {
    document.body.style.cursor = "default";
  }

  // D3 v4
  // var x = d3.event.pageX - document.getElementById("#svgNodes").getBoundingClientRect().x + 10;
  // var y = d3.event.pageY - document.getElementById("#svgNodes").getBoundingClientRect().y + 10;

  // const htmlLabels = d3.select(".html-labels-container");
  const gRects = d3.selectAll(".label-rects-container");
  const gNodes = d3.selectAll(".nodes-container");
  const gLabels = d3.selectAll(".labels-container");
  const gClusters = d3.selectAll(".clusters-container");

  gNodes
    .style(
      "transform",
      "translate(" +
        transform.x +
        "px," +
        transform.y +
        "px) scale(" +
        transform.k +
        ")"
    )
    // .style("transform-origin", sourceEvent ? "0 0" : canvasMiddle[0] + "px " + canvasMiddle[1] + "px");
    .style("transform-origin", sourceEvent && "0 0");
  gRects
    .style(
      "transform",
      "translate(" +
        transform.x +
        "px," +
        transform.y +
        "px) scale(" +
        transform.k +
        ")"
    )
    .style("transform-origin", sourceEvent && "0 0");

  gLabels
    .style(
      "transform",
      "translate(" +
        transform.x +
        "px," +
        transform.y +
        "px) scale(" +
        transform.k +
        ")"
    )
    .style("transform-origin", sourceEvent && "0 0");

  gClusters.style(
    "transform",
    "translate(" +
      transform.x +
      "px," +
      transform.y +
      "px) scale(" +
      transform.k +
      ")"
  );
  // .style("transform-origin", sourceEvent & "0 0");

  // htmlLabels.attr("transform", transform);
  // gNodes.attr("transform", transform);
  // gRects.attr("transform", transform);
  // gLabels.attr("transform", transform);
  // gClusters.attr("transform", transform);
  // gDelaunayLabels.attr("transform", transform);
  // gDelaunayLines.attr("transform", transform);
  // gDelaunayPaths.attr("transform", transform);

  gNodes.selectAll(".projectNode").attr("r", (d) => {
    // Stop zooming when the radius is too big
    if (transform.k * 0.8 > 0.9) {
      // return 0.8;
      return d["r"] / (transform.k * 0.8);
    } else {
      return d["r"];
    }
  });

  // let width;

  const labelTexts = gRects.selectAll("text");

  labelTexts
    .style("font-size", function () {
      return 13 / transform.k + "px";
    })
    .attr("dx", function (d, i) {
      return 5 / transform.k;
    })
    .attr("dy", function (d, i) {
      return 2 / transform.k;
    });

  const labelRects = gRects.selectAll("rect");
  labelRects
    .attr("width", function (d, i) {
      // console.log("D", d);
      // console.log("this", this);
      // console.log("d3 this", d3.select(this));
      // console.log("d3 this more", d3.select(this)['_groups'][0][0]['dataset']['label']);
      // const width = this.getBBox().width / transform.k;
      const width =
        d3
          .select(
            ".label-text-" +
              d3.select(this)["_groups"][0][0]["dataset"]["cluster"]
          )
          .node()
          .getComputedTextLength() + 10;
      return width;
    })
    .attr("height", 20 / transform.k)
    .attr("rx", 5 / transform.k)
    .attr("ry", 5 / transform.k);


  // return [transform]
};
