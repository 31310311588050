import React, { Component, Fragment } from "react";
import SearchDropdown from "../SearchDropdown";
import KeywordsSearchDropdown from "../KeywordsSearchDropdown";
import _ from "lodash";
import "./index.scss";

import { getTranslation } from "../../common/translations";

class SearchesPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keywords: {
        internalOptions: [],
        selectedOption: "AND",
        type: "keywords",
      },
      participants: {
        internalOptions: [],
        type: "participants",
      },
    };
  }

  componentDidMount() {
    // console.log(">>>> SEARCHES PANEL -> DID MOUNT");

    // Check if there are search coming from Provider
    const participantsFound = _.find(
      this.props.filters["selected"],
      (o) => o.type === "participants"
    );

    const keywordsFound = _.find(
      this.props.filters["selected"],
      (o) => o.type === "keywords"
    );

    let participants = {};
    let keywords = {};

    // update the state with the values coming from Provider
    if (participantsFound) {
      participants = {
        internalOptions: participantsFound.values,
        type: "participants",
      };
    } else {
      participants = {
        internalOptions: [],
        type: "participants",
      };
    }

    if (keywordsFound) {
      keywords = {
        internalOptions: keywordsFound.values,
        selectedOption: keywordsFound.mode,
        type: "keywords",
      };
    } else {
      keywords = {
        internalOptions: [],
        selectedOption: "AND",
        type: "keywords",
      };
    }

    this.setState({
      keywords: keywords,
      participants: participants,
    });
  }

  // Do not update the component from props
  shouldComponentUpdate(nextProps, nextState) {
    if (!_.isEqual(nextState, this.state)) {
      // console.log(">>>> SEARCHES PANEL -- UPDATE");
      // console.log("THIS STATE", this.state);
      // console.log("NEXT STATE", nextState);
      return true;
    } else {
      return false;
    }
  }

  storeValues = ({
    internalOptions = [],
    selectedOption = this.state.keywords.selectedOption,
    type = "keywords",
  }) => {
    // console.log(">>>>>>>> STEP 1 >>>>>>>>>");
    // console.log("STORING VALUES");
    // console.log("THIS STATE", this.state);
    // console.log("INTERNAL OPTIONS", internalOptions);
    // console.log("SELECTED OPTION", selectedOption);

    if (type === "keywords") {
      const data = {};

      data.internalOptions = internalOptions;
      data.selectedOption = selectedOption;
      data.type = type;

      this.setState({
        keywords: data,
      });
    } else {
      // type === "participants"

      const data = {};

      data.internalOptions = internalOptions;
      data.type = type;

      this.setState({
        participants: data,
      });
    }
  };

  sendValueToProvider = () => {
    // console.log(">>>>>>>>>>> SENDING VALUES <<<<<<<<<<<<<<<");
    // console.log("KEYWORDS", this.state["keywords"].internalOptions);
    // console.log("MODE", this.state["keywords"].selectedOption);
    // console.log("PARTICIPANTS", this.state["participants"].internalOptions);

    let participantsOptions = [];
    let keywordsOptions = [];

    // Check if participants internalOptions array are numbers
    const participantsAreNumbers = this.state[
      "participants"
    ].internalOptions.every((item) => typeof item === "number");
    // Format participants internalOptions array
    if (participantsAreNumbers) {
      participantsOptions = this.state["participants"].internalOptions.map(
        (item) => {
          return this.props.filters.populate["participants"].options.find(
            (option) => option.value === item
          );
        }
      );
    } else {
      participantsOptions = this.state["participants"].internalOptions;
    }

    // Check if keywords internalOptions array are strings
    const keywordsAreStrings = this.state["keywords"].internalOptions.every(
      (item) => typeof item === "string"
    );

    // Format keywords internalOptions array
    if (keywordsAreStrings) {
      keywordsOptions = this.state["keywords"].internalOptions.map((item) => {
        return {
          value: item,
          label: item,
        };
      });
    } else {
      keywordsOptions = this.state["keywords"].internalOptions;
    }

    let keywordsData = {};
    let participantsData = {};

    keywordsData["filter"] = keywordsOptions;
    keywordsData["mode"] = this.state["keywords"].selectedOption;
    keywordsData["type"] = "keywords";

    participantsData["filter"] = participantsOptions;
    participantsData["type"] = "participants";

    this.props.setSearch({
      keywords: keywordsData,
      participants: participantsData,
    });
    // Close the filter and search panels
    this.props.setActiveTool("");
  };

  render() {
    const { keywords, participants } = this.state;
    const { lang } = this.props;

    // console.log(">>>> SEARCHES PANEL -- RENDER");
    // console.log("KEYWORDS", keywords);
    // console.log("PARTICIPANTS", participants);

    return (
      <Fragment>
        <div className="panel searches-panel">
          <header>
            <h6>
              {getTranslation({
                lang: lang,
                key: "SEARCH_PANEL.TITLE",
              })}
            </h6>
            <button
              className="btn btn-secondary ms-auto"
              onClick={() => this.props.setActiveTool("")}
            >
              {getTranslation({
                lang: lang,
                key: "SEARCH_PANEL.BUTTON_CANCEL",
              })}
            </button>
            <button
              className="btn btn-primary ms-3"
              onClick={this.sendValueToProvider}
              disabled={
                keywords.internalOptions.length === 0 &&
                participants.internalOptions.length === 0
                  ? true
                  : false
              }
            >
              {getTranslation({
                lang: lang,
                key: "SEARCH_PANEL.BUTTON_APPLY",
              })}
            </button>
          </header>
          <div className="search-container search-container-keywords border-top">
            <h4 className="">
              {
                getTranslation({
                  lang: lang,
                  key: "SEARCH_PANEL.TITLE_KEYWORDS",
                })
              }
            </h4>
            <KeywordsSearchDropdown
              entity={"keywords"}
              placeholder={
                getTranslation({
                  lang: lang,
                  key: "SEARCH_PANEL.PLACEHOLDER_KEYWORDS",
                })
              }
              dynamicClassName={"filterdropdown-search-container"}
              dynamicPrefix={"filterdropdown-search"}
              sendValueToProvider={this.sendValueToProvider}
              storeValues={this.storeValues}
              multi={false}
              selectedFromComponent={this.state["keywords"].internalOptions}
              {...this.props}
            ></KeywordsSearchDropdown>
          </div>
          <div className="search-container search-container-entitats border-bottom pt-0">
            <h4 className="">
              {
                getTranslation({
                  lang: lang,
                  key: "SEARCH_PANEL.TITLE_PARTICIPANTS",
                })
              }
            </h4>
            <SearchDropdown
              entity={"participants"}
              placeholder={
                getTranslation({
                  lang: lang,
                  key: "SEARCH_PANEL.PLACEHOLDER_PARTICIPANTS",
                })
              }
              options={this.props.filters.populate["participants"].options}
              dynamicClassName={"filterdropdown-search-container"}
              dynamicPrefix={"filterdropdown-search"}
              multi={false}
              storeValues={this.storeValues}
              selectedFromComponent={this.state["participants"].internalOptions}
              {...this.props}
            ></SearchDropdown>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SearchesPanel;
