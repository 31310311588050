import React, { Component, Fragment } from "react";
import "./index.scss";

import { Context } from "../../context/Context";
import { Provider } from "../../context/Provider";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import Header from "../../components/Header";
import TopicsCanvas from "../../components/TopicsCanvas";
import NetworkCanvas from "../../components/NetworkCanvas";
import CanvasTools from "../../components/CanvasTools";
import TopicPanelInformation from "../../components/TopicPanelInformation";
import NodePanelInformation from "../../components/NodePanelInformation";
import StatisticsTabs from "../../components/StatisticsTabs";
import ProjectInfoModal from "../../components/ProjectInfoModal";
import AmbitsChart from "../../components/AmbitsChart";
// import ProgramsByYearChart from "../../components/ProgramsByYearChart";

import Loader from "../../components/Loader";

import { CONFIG } from "../../common/constants";
import { getTranslation } from "../../common/translations";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      tabs: [
        {
          name: CONFIG.APP.NETWORK.TITLE,
        },
        {
          name: CONFIG.APP.TOPICS.TITLE,
        },
      ],
    };
    this.canvasContainer = React.createRef();
    this.resizeObserver = null;
  }

  componentDidMount() {
    this.resizeObserver = new ResizeObserver((entries) => {
      // console.log("ENTRIES", entries)
      this.setState({
        canvasContainerWidth: entries[0]["contentRect"]["width"],
        canvasContainerHeight: entries[0]["contentRect"]["height"],
      });
    });

    this.resizeObserver.observe(this.canvasContainer.current);
  }

  handleAcceptCookies = () => {
    // console.log("ACCEPT COOKIES");
    // this.setState({ cookiesAccepted: true });
  };

  render() {
    const { canvasContainerWidth, canvasContainerHeight } = this.state;
    return (
      <div className="home home-layout">
        <Provider>
          <Context.Consumer>
            {(context) => (
              <Fragment>
                {/* {console.log("HOME -> CONTEXT", context)} */}
                {context.loading && (
                  <Loader text={context["loadingText"]} size="full"></Loader>
                )}
                <Header
                  lang={context.language}
                  setLang={context.setLang}
                ></Header>
                {/* MAIN VIEWS */}
                <div
                  id="canvasContainer"
                  ref={this.canvasContainer}
                  className="canvas-container"
                >
                  <Tabs
                    id="canvas-options"
                    activeKey={context.tabs.active}
                    defaultActiveKey={context.tabs.active}
                    onSelect={(k) => context.setSelectedTab(k)}
                    className="main-tabs"
                  >
                    <Tab
                      eventKey={CONFIG.APP.NETWORK.NAME}
                      title={getTranslation({
                        lang: context.language,
                        key: "HEADER.TABS.NETWORK_TITLE",
                      })}
                      tabClassName={CONFIG.APP.NETWORK.NAME + "-container"}
                    >
                      {!context.loading && canvasContainerWidth && (
                        <NetworkCanvas
                          canvasWidth={canvasContainerWidth}
                          canvasHeight={canvasContainerHeight}
                          {...context}
                        ></NetworkCanvas>
                      )}

                      {context.clickedNode && (
                        <NodePanelInformation
                          {...context}
                        ></NodePanelInformation>
                      )}
                    </Tab>
                    <Tab
                      eventKey={CONFIG.APP.TOPICS.NAME}
                      title={getTranslation({
                        lang: context.language,
                        key: "HEADER.TABS.MAP_TITLE",
                      })}
                      tabClassName={CONFIG.APP.TOPICS.NAME + "-container"}
                    >
                      {!context.loading && canvasContainerWidth && (
                        <TopicsCanvas
                          canvasWidth={canvasContainerWidth}
                          canvasHeight={canvasContainerHeight}
                          {...context}
                        ></TopicsCanvas>
                      )}
                      {context.interactionLoading && <Loader></Loader>}
                      {context.clickedDot && (
                        <TopicPanelInformation
                          {...context}
                        ></TopicPanelInformation>
                      )}
                    </Tab>
                  </Tabs>
                  {/* TOOLS */}
                  {!context.loading && <CanvasTools {...context}></CanvasTools>}

                  <div className="floating-container">
                    <AmbitsChart {...context}></AmbitsChart>
                    {/* <ProgramsByYearChart {...context}></ProgramsByYearChart> */}
                  </div>
                </div>
                <StatisticsTabs {...context}></StatisticsTabs>
                {context.clickedProject && (
                  <ProjectInfoModal {...context}></ProjectInfoModal>
                )}
                {/* COOKIES */}
                {context.showCookiesBanner && (
                  <div className="cookies-container">
                    <div className="cookieConsentWrapper">
                      <div className="cookieConsent">
                        <div className="cookieConsent__Left">
                          <div className="cookieConsent__Content">
                            <p className="cookieConsent__Title">
                              Aquest web utilitza galetes (<em>cookies</em>)
                            </p>{" "}
                            <p className="cookieConsent__Description">
                              Utilitzem galetes per oferir-te una millor
                              experiència, analitzar el trànsit i personalitzar
                              el contingut mostrat. Pots acceptar totes les
                              galetes o rebutjar-les.
                            </p>{" "}
                          </div>
                        </div>{" "}
                        <div className="cookieConsent__Right">
                          <button
                            type="button"
                            className="cookieConsent__Button secondary"
                            onClick={() => context.handleAcceptCookies(false)}
                          >
                            Rebutja-les totes
                          </button>{" "}
                          <button
                            type="submit"
                            className="cookieConsent__Button"
                            onClick={() => context.handleAcceptCookies(true)}
                          >
                            Accepta-les totes
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="backdrop-cookies"></div>
                  </div>
                )}
              </Fragment>
            )}
          </Context.Consumer>
        </Provider>
      </div>
    );
  }
}

export default Home;
