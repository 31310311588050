export const queries = {
  projectCoords: () => `PREFIX : <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69#>
  SELECT ?pid ?px ?py ?clusterId
  WHERE {
    ?proj a :Project .
    ?proj :simpleProjectId ?pid .
    ?proj :positionX ?px .
    ?proj :positionY ?py .
    ?proj :cluster ?cluster .
    ?cluster :identifier ?clusterId .
  }
  # LIMIT 1000
`,
  topicsCoords: () => `PREFIX : <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69#>
  SELECT ?clusterId ?clusterLabel ?clusterX ?clusterY
  WHERE {
     ?cluster a :Cluster .
     ?cluster :identifier ?clusterId .
     ?cluster :label ?clusterLabel .
     ?cluster :positionX ?clusterX .
     ?cluster :positionY ?clusterY .
  }
`,
  projectsInformation:
    () => `PREFIX : <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69#>    
  PREFIX ris3cat: <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69/ris3cat#>  
  SELECT distinct ?id ?title ?abstract ?year ?funding ?grant ?instrumentId ?instrumentName ?frameworkName
  
  WHERE {
    ?project a :Project.
    ?project :simpleProjectId ?id.

    ?project :title ?title .
    ?project :abstract ?abstract . 
    ?project :startingYear ?year .
    ?project :framework ?framework .
    ?framework :extendedName ?frameworkName .

    ?project :instrument ?instrument . 
    ?instrument :identifier ?instrumentId . 
    ?instrument :shortName ?instrumentName . 
    
    optional {
      ?project :totalInvestment ?funding .
    }
    optional {
      ?project :totalGrant ?grant .
    }
  }
  # LIMIT 1000
  `,
  projectsAmbits:
    () => `PREFIX : <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69#>    
  PREFIX ris3cat: <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69/ris3cat#>  
  SELECT distinct ?id ?categoryName
  
  WHERE {
    ?project a :Project.
    ?project :simpleProjectId ?id.
    ?project :classification ?category . 
    ?category :classificationName ?categoryTypeName . 
    FILTER(?categoryTypeName = "RIS3CAT Àmbit d'Actuació Prioritari") 
    ?category :extendedName ?categoryName .                
  }
  # LIMIT 1000
  `,
  projectsKeywords:
    () => `PREFIX : <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69#>    
  PREFIX ris3cat: <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69/ris3cat#>  
  SELECT ?id (GROUP_CONCAT(DISTINCT ?keyword ; separator=";") AS ?keywords)
  
  WHERE {
    ?project a :Project.
    ?project :simpleProjectId ?id.
    optional { 
      ?project :keyword ?keywordProp .   
      ?keywordProp :textualValue ?keyword .     
    } 
  }
  GROUP BY ?id
  # LIMIT 1000
  `,
  projectsPartners:
    () => `PREFIX : <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69#>    
  PREFIX ris3cat: <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69/ris3cat#>  
  SELECT DISTINCT 
    ?projectId  
    ?organizationId 
    ?organizationName 
    ?roleInProject 
    ?activityTypeId 
    ?countryName 
    ?inRegion 
    ?participantNuts2Name 
    ?participantNuts3Id 
    ?participantNuts3Name 
    ?investment 
    ?grant 
    ?totalInvestment 
    ?totalGrant 
    ?isCERCA
  
  WHERE {
    ?project a :Project.
    ?project :simpleProjectId ?projectId.
    ?project :reifiedParticipant ?participantInRegion .         
    ?participantInRegion :nuts2 ?participantInRegionNuts2 .          
    ?participantInRegionNuts2 :identifier ?participantInRegionNuts2Id .              
    FILTER (?participantInRegionNuts2Id = "ES51")               
    ?project :reifiedParticipant ?participant .  
    ?participant :organization ?organization.
    ?organization :unicsId ?organizationId .
    ?organization :extendedName ?organizationName .
    ?organization :isCERCACenter ?isCERCA .                
    ?participant :ecActivityType ?activityType .         
    ?activityType :shortName ?activityTypeId .          
    ?participant :country ?country .    
    ?country :extendedName ?countryName .       

    optional {  
      ?participant :roleInProject ?role .
      ?role :extendedName ?roleInProject .
    }
    OPTIONAL {    
      ?participant :nuts2 ?participantNuts2 .    
      ?participantNuts2 :identifier ?participantNuts2Id .        
      ?participantNuts2 :extendedName ?participantNuts2Name .        
    }    
    BIND((BOUND(?participantNuts2Id) && (?participantNuts2Id = "ES51")) AS ?inRegion)                     
    optional {  
      ?participant :nuts3 ?participantNuts3 .   
      ?participantNuts3 :identifier ?participantNuts3Id .  
      ?participantNuts3 :extendedName ?participantNuts3Name .     
    }          
    optional { 
      ?participant :investment ?investment . 
    }         
    optional { 
      ?participant :grant ?grant . 
    }        
    optional { 
      ?project :totalInvestment ?totalInvestment . 
    }             
    optional { 
      ?project :totalGrant ?totalGrant . 
    }       
  }
  # LIMIT 1000
  `,
  projectsSDGs:
    () => `PREFIX : <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69#>    
  PREFIX ris3cat: <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69/ris3cat#>  
  SELECT ?id ?sdgId ?sdgName
  
  WHERE {
    ?project a :Project.
    ?project :simpleProjectId ?id.
    optional {   
      ?project :sdgReified ?sdgReified .   
      ?sdgReified :sdg ?sdg .   
      ?sdg :shortName ?sdgId . 
      ?sdg :extendedName ?sdgName . 
    }
  }
  # LIMIT 1000
  `,
  projectParticipantsInformation: (
    projectId
  ) => `PREFIX : <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69#>    
  PREFIX ris3cat: <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69/ris3cat#>
  SELECT distinct
  ?organizationId
  ?organizationName
  ?grant
  ?countryName
  ?totalInvestment
  ?totalGrant
  ?investment
  WHERE {
    ?proj a :Project .
    ?proj :simpleProjectId ?simpleProjectId .
    FILTER(?simpleProjectId = "${projectId}") .
    # ?proj :abstract ?abstract .
    ?proj :totalInvestment ?totalInvestment .
    ?proj :totalGrant ?totalGrant .
    ?proj :reifiedParticipant ?ecParticipant .
    ?ecParticipant :nuts2 ?participantNuts2 .
    ?participantNuts2 :identifier ?participantNuts2Id .              
    FILTER (?participantNuts2Id = "ES51")    
    ?ecParticipant :organization ?organization . 
    ?organization :unicsId ?organizationId .
    ?organization :extendedName ?organizationName .
    ?ecParticipant :grant ?grant .
    optional { ?ecParticipant :investment ?investment } .
    # optional { ?ecParticipant :cityName ?cityName } .
    ?ecParticipant :country ?country .
    ?country :extendedName ?countryName .
    FILTER(?countryName = "Spain")
  }
  # LIMIT 1000
  `,
  mainDataDownload: (
    array
  ) => `PREFIX : <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69#>    
  PREFIX ris3cat: <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69/ris3cat#>  
  SELECT distinct ?projectId ?projectTitle ?projectAbstract ?year ?projectTotalInvestment ?projectTotalGrant ?projectCategoryName ?organizationName ?activityTypeId ?participantInvestment ?participantGrant ?participantNuts3Name ?roleInProject
  WHERE {
    ?project a :Project.
    ?project :simpleProjectId ?projectId.
    FILTER(${array
      .map(function (id, index) {
        return (
          '?projectId = "' + id + (index !== array.length - 1 ? '" || ' : '"')
        );
      })
      .join("")})
    # PROJECT INFORMATION
    ?project :title ?projectTitle .
    ?project :abstract ?projectAbstract . 
    ?project :startingYear ?year .
    # AMBITS D'ACTUACIÓ PRIORITARIS
    ?project :classification ?category . 
    ?category :classificationName ?categoryTypeName . 
    FILTER(?categoryTypeName = "RIS3CAT Àmbit d'Actuació Prioritari") 
    ?category :extendedName ?projectCategoryName .
    # PARTICIPANTS
    ?project :reifiedParticipant ?participantNode .
    ?participantNode :nuts2 ?participantNuts2 .
    ?participantNuts2 :identifier ?participantNuts2Id .
    ?participantNuts2 :extendedName ?participantNuts2Name .                
    FILTER (?participantNuts2Id = "ES51")   
    ?participantNode :organization ?organization .         
    ?organization :unicsId ?organizationId .
    ?organization :extendedName ?organizationName .
    ?participantNode :ecActivityType ?activityType .         
    ?activityType :shortName ?activityTypeId . 
    OPTIONAL { ?participantNode :investment ?participantInvestment } .  
    OPTIONAL { ?participantNode :grant ?participantGrant } . 
    OPTIONAL {  
      ?participantNode :roleInProject ?role .
      ?role :extendedName ?roleInProject .
    }
    OPTIONAL {
      ?project :totalInvestment ?projectTotalInvestment .
    }
    OPTIONAL {
      ?project :totalGrant ?projectTotalGrant .
    }
    OPTIONAL {  
      ?participantNode :nuts3 ?participantNuts3 .   
      ?participantNuts3 :identifier ?participantNuts3Id .
      ?participantNuts3 :extendedName ?participantNuts3Name .     
    }   
  }
  `,
};
