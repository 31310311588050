import React, { Component, Fragment } from "react";
// import WindowedSelect from "react-windowed-select";
import { WindowedMenuList } from 'react-windowed-select';
// import { components, createFilter } from "react-windowed-select";
import CreatableSelect from "react-select/creatable";
import "./index.scss";
// import { FaSearch } from "react-icons/fa";
import _ from "lodash";

import { getTranslation } from "../../common/translations";

class KeywordsSearchDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      selectedOption: "AND",
      radioOptions: [
        {
          label: getTranslation({
            lang: props.lang,
            key: "SEARCH_PANEL.KEYWORDS_SEARCH.RADIO_OPTION_1",
          }),
          value: "AND",
        },
        {
          label: getTranslation({
            lang: props.lang,
            key: "SEARCH_PANEL.KEYWORDS_SEARCH.RADIO_OPTION_2",
          }),
          value: "OR",
        },
      ],
      internalOptions: [],
    };
  }

  componentDidMount() {
    // console.log("KWDS DROPDOWN -> INITIAL MOUNT");
    const foundFilter = _.find(
      this.props.filters["selected"],
      (filter) => filter["type"] === "keywords"
    );

    let currentMode = this.state.selectedOption;
    // console.log("FOUND FILTER", foundFilter);
    // console.log("THIS PROPS", this.props);

    let currentInternalOptions = [];
    if (foundFilter) {
      // console.log("-----> FOUND FILTER", foundFilter);
      // console.log("-----> OPTIONS", this.props.filters.populate["keywords"]["options"]);
      currentInternalOptions = _.filter(
        this.props.filters.populate["keywords"]["options"],
        (option) => foundFilter["values"].includes(option["value"])
      );
      currentMode = foundFilter["mode"];
    }

    // console.log("-----> CURRENT INTERNAL OPTIONS", currentInternalOptions);

    this.setState({
      loading: false,
      internalOptions: currentInternalOptions,
      dropdownOptions: this.props.filters["populate"]["keywords"]["options"],
      selectedOption: currentMode,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // if (_.isEqual(prevProps.filters, this.props.filters)) {
    if (_.isEqual(prevState, this.state)) {
      // // if (prevState.loading === this.state.loading) {
      // console.log("KWDS DROPDOWN -> DO NOTHING");
      // return false;
      return true;
    } else {
      return false;
    }
  }

  handleSelected = (options, { action, removedValue }) => {
    // console.log("handleSelected");
    // console.log("options", options);
    // console.log("action", action);
    // console.log("removedValue", removedValue);

    // if removedValue then
    // 1. go directly to Provider
    // 2. Clean options []
    // 3. disable search button
    if (removedValue !== undefined || options.length === 0) {
      const data = {};

      data.filter = options;
      data.type = this.props.entity;
      data.mode = this.state.selectedOption;

      this.props.setSearch({
        keywords: data,
      });
    }

    this.props.storeValues({
      internalOptions: options,
      selectedOption: this.state.selectedOption,
      type: "keywords",
    });

    this.setState({
      internalOptions: options,
    });
  };

  handleRadioSelected = (event) => {
    this.props.storeValues({
      internalOptions: this.state.internalOptions,
      selectedOption: event["target"]["value"],
      type: "keywords",
    });

    this.setState({
      selectedOption: event["target"]["value"],
    });
  };

  generateOptionsList = (options) => {};

  render() {
    const { placeholder } = this.props;
    const {
      loading,
      dropdownOptions,
      internalOptions,
      radioOptions,
      selectedOption,
    } = this.state;

    // console.log("-> STATE LOADING", loading);
    // console.log("-> STATE INTERNAL OPTIONS", internalOptions);
    // console.log("-> STATE DROPDOWN OPTIONS", dropdownOptions);
    // console.log("-> PROPS OPTIONS", options);

    return (
      <Fragment>
        {loading ? (
          <p>Carregant</p>
        ) : (
          <div className="search-component-container mb-4">
            {/* <WindowedSelect
              components={customComponents}
              isClearable={true}
              filterOption={customFilter}
              options={dropdownOptions}
            /> */}
            <CreatableSelect
              options={dropdownOptions}
              isSearchable={true}
              onChange={this.handleSelected}
              defaultValue={internalOptions}
              allowCreateWhileLoading={true}
              formatCreateLabel={(x) => {
                return "Afegir " + x;
              }}
              isMulti
              openMenuOnClick={false}
              isClearable={true}
              placeholder={<div className="text-truncate">{placeholder}</div>}
              className={
                this.props.dynamicClassName ? this.props.dynamicClassName : ""
              }
              classNamePrefix={
                this.props.dynamicPrefix ? this.props.dynamicPrefix : ""
              }
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#F2F2F2",
                  primary: "#DDD",
                },
              })}
              components={{ MenuList: WindowedMenuList }}
            />
            {/* <button
              className="btn btn-primary"
              onClick={this.sendValueToParent}
              disabled={internalOptions.length === 0 ? true : false}
            >
              <FaSearch />
            </button> */}
          </div>
        )}

        {_.map(radioOptions, (option, index) => {
          return (
            <div key={index} className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value={option["value"]}
                id={`option-${option["value"]}`}
                onChange={this.handleRadioSelected}
                checked={option["value"] === selectedOption ? true : false}
              />
              <label
                className="form-check-label"
                htmlFor={`option-${option["value"]}`}
              >
                {option["label"]}
              </label>
            </div>
          );
        })}
      </Fragment>
    );
  }
}

export default KeywordsSearchDropdown;
