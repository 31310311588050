// Manage the translations of the app
// By page / component / so on...
// Import lodash to use the get() function
import _ from "lodash";

import { ca } from "./translations/ca";
import { en } from "./translations/en";

export const getTranslation = ({ lang = null, key = null }) => {
  let TRANSLATIONS = {};
  if (lang === "ca") {
    TRANSLATIONS = ca;
  }
  if (lang === "en") {
    TRANSLATIONS = en;
  }
  return _.get(TRANSLATIONS, key);
};
