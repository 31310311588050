import React, { Component, Fragment } from "react";
import "./index.scss";
import _ from "lodash";

import { StatisticsBars } from "../StatisticsBars";

// import { ACTIVITY_TYPES_LABELS } from "../../common/constants";

import { getTranslation } from "../../common/translations";

class StatisticsTabEntitats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.leftChartContainer = React.createRef();
    this.rightChartContainer = React.createRef();
    this.resizeObserver = null;
  }

  componentDidMount() {
    this.resizeObserver = new ResizeObserver((entries) => {
      this.setState({
        loading: false,
        leftChartWidth: entries[0]["contentRect"]["width"],
        leftChartHeight: entries[0]["contentRect"]["height"],
      });
    });

    this.resizeObserver.observe(this.leftChartContainer.current);
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  render() {
    const { loading, leftChartWidth, leftChartHeight } = this.state;
    const { filters, statistics, section, data, language } = this.props;

    let dataProjectsCount = [];
    let dataProjectsFunding = [];
    let catalandataProjectsCount = [];
    let catalandataProjectsFunding = [];
    let downloadData = [];

    const nuts3Found = _.find(filters["selected"], { type: "nuts3" });
    const activityTypeFound = _.find(filters["selected"], {
      type: "activityType",
    });

    const filteredProjects = this.props.filters["filteredProjects"];

    if (filters["searchedParticipantIds"].length > 0) {
      if (section === "entitats") {
        dataProjectsCount = _.filter(
          statistics.data.entitats.projects,
          (partner) =>
            filters["searchedParticipantIds"].includes(
              partner["organizationId"]
            )
        );
        dataProjectsFunding = _.filter(
          statistics.data.entitats.funding,
          (partner) =>
            filters["searchedParticipantIds"].includes(
              partner["organizationId"]
            )
        );
      } else {
        // SOCIS DE CATALUNYA
        dataProjectsCount = _.filter(
          statistics.data.entitats.projects,
          (partner) =>
            !filters["searchedParticipantIds"].includes(
              partner["organizationId"]
            )
        );
        dataProjectsFunding = _.filter(
          statistics.data.entitats.funding,
          (partner) =>
            !filters["searchedParticipantIds"].includes(
              partner["organizationId"]
            )
        );

        // If nuts3 filter is selected
        // then remove all participants that are not from nuts3
        if (nuts3Found) {
          dataProjectsCount = _.filter(dataProjectsCount, (partner) =>
            nuts3Found["values"].includes(partner["participantNuts3Id"])
          );
          dataProjectsFunding = _.filter(dataProjectsFunding, (partner) =>
            nuts3Found["values"].includes(partner["participantNuts3Id"])
          );
        }

        if (activityTypeFound) {
          dataProjectsCount = _.filter(dataProjectsCount, (partner) =>
            activityTypeFound["values"].includes(partner["activityTypeId"])
          );
          dataProjectsFunding = _.filter(dataProjectsFunding, (partner) =>
            activityTypeFound["values"].includes(partner["activityTypeId"])
          );
        }

        catalandataProjectsCount = dataProjectsCount;
        catalandataProjectsFunding = dataProjectsFunding;
      }
    } else {
      // If there is not a participant selected
      // and there is not nuts3 or activityType filter selected
      // then show all data
      dataProjectsCount = statistics.data.entitats.projects;
      dataProjectsFunding = statistics.data.entitats.funding;

      // if filters selected contains type="nuts3"

      // if filters selected contains type="nuts3"
      // remove all participants that are not from nuts3

      let nuts3filteredPartnersCount = [];
      let nuts3filteredPartnersSum = [];

      if (nuts3Found) {
        // Filter internalPartners data with filteredProjects
        let filteredPartners = _.filter(
          this.props.data.internalPartners,
          (p) => {
            return filteredProjects.includes(p["projectId"]);
          }
        );

        // Remove any entry with participantNuts3Id
        filteredPartners = _.filter(filteredPartners, (partner) => {
          return !nuts3Found["values"].includes(partner["participantNuts3Id"]);
        });

        // Group by organizationId and count projects
        nuts3filteredPartnersCount = _(filteredPartners)
          .groupBy("organizationId")
          .map((organisation, id) => ({
            organizationId: id,
            label: organisation[0]["organizationName"],
            value: organisation.length,
          }))
          .value();

        // Sort by value
        nuts3filteredPartnersCount = _.orderBy(
          nuts3filteredPartnersCount,
          ["value"],
          ["desc"]
        );

        // Group by organizationId and sum investment
        nuts3filteredPartnersSum = _(filteredPartners)
          .groupBy("organizationId")
          .map((organisation, id) => ({
            organizationId: id,
            label: organisation[0]["organizationName"],
            value: _.sumBy(organisation, "investment"),
          }))
          .value();

        // Sort by value
        nuts3filteredPartnersSum = _.orderBy(
          nuts3filteredPartnersSum,
          ["value"],
          ["desc"]
        );
      }

      // if filters selected contains type="activityType"
      // remove all participants that are not from activityType

      let typeFilteredPartnersCount = [];
      let typeFilteredPartnersSum = [];

      if (activityTypeFound) {
        // Filter internalPartners data with filteredProjects
        let filteredPartners = _.filter(
          this.props.data.internalPartners,
          (p) => {
            return filteredProjects.includes(p["projectId"]);
          }
        );

        // Remove any entry with activityTypeId
        filteredPartners = _.filter(filteredPartners, (partner) => {
          return !activityTypeFound["values"].includes(
            partner["activityTypeId"]
          );
        });

        // Group by organizationId and count projects
        typeFilteredPartnersCount = _(filteredPartners)
          .groupBy("organizationId")
          .map((organisation, id) => ({
            organizationId: id,
            label: organisation[0]["organizationName"],
            value: organisation.length,
          }))
          .value();

        // Sort by value
        typeFilteredPartnersCount = _.orderBy(
          typeFilteredPartnersCount,
          ["value"],
          ["desc"]
        );

        // Group by organizationId and sum investment
        typeFilteredPartnersSum = _(filteredPartners)
          .groupBy("organizationId")
          .map((organisation, id) => ({
            organizationId: id,
            label: organisation[0]["organizationName"],
            value: _.sumBy(organisation, "investment"),
          }))
          .value();

        // Sort by value
        typeFilteredPartnersSum = _.orderBy(
          typeFilteredPartnersSum,
          ["value"],
          ["desc"]
        );
      }

      // New array with the intersection of both arrays

      if (nuts3Found && activityTypeFound) {
        catalandataProjectsCount = _.intersectionBy(
          nuts3filteredPartnersCount,
          typeFilteredPartnersCount,
          "organizationId"
        );
        catalandataProjectsFunding = _.intersectionBy(
          nuts3filteredPartnersSum,
          typeFilteredPartnersSum,
          "organizationId"
        );
      } else if (nuts3Found) {
        catalandataProjectsCount = nuts3filteredPartnersCount;
        catalandataProjectsFunding = nuts3filteredPartnersSum;
      } else if (activityTypeFound) {
        catalandataProjectsCount = typeFilteredPartnersCount;
        catalandataProjectsFunding = typeFilteredPartnersSum;
      } else {
        catalandataProjectsCount = dataProjectsCount;
        catalandataProjectsFunding = dataProjectsFunding;
      }
    }

    if (section !== "entitats") {
      // Prepare download data
      // get ids from selected entitats
      const participantsIDS = _.map(catalandataProjectsCount, (p) =>
        Number(p["organizationId"])
      );

      // Deep clone of data["internalPartners"]
      let isolatedData = JSON.parse(JSON.stringify(data["internalPartners"]));

      downloadData = _.filter(isolatedData, (partner) =>
        participantsIDS.includes(partner["organizationId"])
      );
    }

    return (
      <Fragment>
        <div className="container-fluid tab-container tab-container-entitats">
          {section === "partners-catalans" && (
            <div className="row subheader">
              <div className="col col-auto ms-auto">
                <button
                  className="btn btn-link p-0"
                  onClick={() =>
                    this.props.dwnldPartnersExcel({
                      partnersdata: downloadData,
                      fileName: "socis-catalunya-ris3mcat",
                    })
                  }
                >
                  <small>
                    {getTranslation({
                      lang: language,
                      key: "STATISTICS.ENTITIES.DOWNLOAD_EXCEL",
                    })}
                  </small>
                </button>
              </div>
            </div>
          )}

          <header
            className={`row header border-top ${
              section === "partners-catalans" ? "border-top" : ""
            }`}
          >
            <div className="col">
              <h4>
                {section === "partners-catalans"
                  ? getTranslation({
                      lang: language,
                      key: "STATISTICS.ENTITIES.TITLE_SOCIS_1",
                    })
                  : getTranslation({
                      lang: language,
                      key: "STATISTICS.ENTITIES.TITLE_1",
                    })}
              </h4>
            </div>
            <div className="col">
              <h4>
                {section === "partners-catalans"
                  ? getTranslation({
                      lang: language,
                      key: "STATISTICS.ENTITIES.TITLE_SOCIS_2",
                    })
                  : getTranslation({
                      lang: language,
                      key: "STATISTICS.ENTITIES.TITLE_2",
                    })}
              </h4>
            </div>
          </header>
          <div
            className={`row body ${
              section === "partners-catalans" ? "w-subheader" : ""
            }`}
          >
            <div className="col" ref={this.leftChartContainer}>
              {loading ? (
                <p>Loading</p>
              ) : (
                <StatisticsBars
                  width={leftChartWidth}
                  height={leftChartHeight}
                  chartData={
                    section === "partners-catalans"
                      ? catalandataProjectsCount
                      : dataProjectsCount
                  }
                  type={"number"}
                ></StatisticsBars>
              )}
            </div>
            <div className="col" ref={this.rightChartContainer}>
              {loading ? (
                <p>Loading</p>
              ) : (
                <StatisticsBars
                  width={leftChartWidth}
                  height={leftChartHeight}
                  chartData={
                    section === "partners-catalans"
                      ? catalandataProjectsFunding
                      : dataProjectsFunding
                  }
                  type={"funding"}
                ></StatisticsBars>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default StatisticsTabEntitats;
