// Manage the translations of the app
// By page / component / so on...

export const en = {
  HEADER: {
    TITLE: "RIS3-MCAT Platform",
    MENU: {
      QUERIES: "SPARQL Queries",
      HELP: "Help in PDF",
    },
    INFORMATION_WINDOW: {
      INTRO_1: "Project of the",
      INTRO_2: "strategy for smart specialisation in Catalonia",
      UPDATE: "Update date: Horizon Europe, January 2024; RIS3CAT instruments, October 2021.",
      DATA_SOURCES: {
        TITLE: "Data sources",
        SIFECAT_DESCRIPTION: "European Funds Information System of Catalonia",
      },
      LIBRARIES: {
        TITLE: "Libraries",
      },
      LICENCE: {
        TITLE: "Licence",
        LINK_SUMMARY: "Licence summary",
        LINK_COMPLETE: "Full licence",
      },
    },
    TABS: {
      NETWORK_TITLE: "Organisations network",
      MAP_TITLE: "Projects map",
    },
  },
  FLOATING_PANEL: {
    AMBITS_CHART: {
      TITLE: "Areas of action",
    },
  },
  TOOLS: {
    SUMMARY_MENU: {
      SHOW_FILTERS: "View applied filters",
      REMOVE_FILTERS: "Remove filters",
    },
    TOOLS_BAR: {
      MENU: {
        FILTER: "Filter",
        SEARCH: "Search",
        SORT: "Criteria to group organisations in the network",
        SHOW: "View",
        ZOOM_IN: "Zoom in",
        ZOOM_OUT: "Zoom out",
        ZOOM_RESET: "Reset view",
        DOWNLOAD: "Download as XLSX",
        LEGEND: "Legend",
      },
      LEGEND: {
        OPTION_1:
          "Project (size is proportional to investment volume; colour reflects classification in a specific theme)",
        OPTION_2: "Collaboration between organisations",
        OPTION_3:
        "Organisation (organisations are classified by colour according to the selected grouping criteria)",
      },
    },
  },
  SEARCH_PANEL: {
    TITLE: "Searches",
    BUTTON_APPLY: "Apply",
    BUTTON_CANCEL: "Cancel",
    TITLE_KEYWORDS: "Search by keywords",
    PLACEHOLDER_KEYWORDS: "Select a keyword",
    TITLE_PARTICIPANTS: "Entity name",
    PLACEHOLDER_PARTICIPANTS: "Select an organisation",
    KEYWORDS_SEARCH: {
      RADIO_OPTION_1: "Projects with all the keywords",
      RADIO_OPTION_2: "Projects with some of the keywords",
    },
  },
  FILTER_PANEL: {
    TITLE: "Filters",
    BUTTON_APPLY: "Apply",
    BUTTON_CANCEL: "Cancel",
    FILTERS: {
      CATEGORY_NAME: {
        TITLE: "Areas of action",
        ALL: "All",
        OPTIONS: {
          sistemaAlimentari: "Food system",
          sistemaCultural: "Cultural system",
          sistemaDeMobilitatILogistica: "Mobility and logistics system",
          sistemaDenergiaIRecursos: "Energy and resource system",
          sistemaEducatiuIDeGeneracioDeConeixement:
            "Education and knowledge generation system",
          sistemaIndustrial: "Industrial system",
          sistemaSociosanitari: "Social and health care system",
          senseClassificar: "Unclassified",
        },
      },
      TOPICS: {
        TITLE: "Topic",
        ALL: "All",
      },
      SDGS: {
        TITLE: "Sustainable development goal (SDG)",
        ALL: "All",
        OPTIONS: {
          ODS_1: "SDG 1. No poverty",
          ODS_2: "SDG 2. Zero hunger",
          ODS_3: "SDG 3. Good health and well-being",
          ODS_4: "SDG 4. Quality education",
          ODS_5: "SDG 5. Gender equality",
          ODS_6: "SDG 6. Clean water and sanitation",
          ODS_7: "SDG 7. Affordable and clean energy",
          ODS_8: "SDG 8. Decent work and economic growth",
          ODS_9: "SDG 9. Industry, innovation and infrastructure",
          ODS_10: "SDG 10. Reduced inequalities",
          ODS_11: "SDG 11. Sustainable cities and communities",
          ODS_12: "SDG 12. Responsible consumption and production",
          ODS_13: "SDG 13. Climate action",
          ODS_14: "SDG 14. Life below water",
          ODS_15: "SDG 15. Life on land",
          ODS_16: "SDG 16. Peace, justice and strong institutions",
          ODS_17: "SDG 17. Partnerships for the goals",
          NA: "N/A",
        },
      },
      FRAMEWORKS: {
        TITLE: "Programme",
        OPTIONS: {
          horitzo2020: "H2020",
          horitzoEuropa: "Horizon Europe",
          instrumentsDeLaRis3Cat: "RIS3CAT instruments",
        },
      },
      ACTIVITY_TYPE: {
        TITLE: "Type of organisation",
        ALL: "All",
        OPTIONS: {
          HES: "University",
          PRC: "Company",
          PUB: "Government",
          REC: "Research centre",
          CERCA: "CERCA centre",
          OTH: "Others",
        },
      },
      NUTS3: {
        TITLE: "Province",
        ALL: "All",
      },
      YEARS: {
        TITLE: "Time period",
      },
    },
  },
  SORTING_PANEL: {
    TITLE: "Criteria to group organisations in the network",
    BUTTON_CLOSE: "Close",
    OPTIONS: {
      OPTION_1: "High collaboration",
      OPTION_2: "Type of organisation",
      OPTION_3: "Programme",
      OPTION_4: "Province",
    },
    MULTIPLE_PROGRAMS: "More than one programme",
  },
  VISIBILITY_PANEL: {
    OPTIONS: {
      LABELS: "Labels",
      PROJECTS: "Projects",
      DENSITIES: "Densities",
    },
  },
  SUMMARY: {
    PROJECT: "project",
    PROJECTS: "projects",
    FUNDING: "of investment",
    ENTITY: "organisation",
    ENTITIES: "organisations",
    EXTERNAL_PARTNERS: "external partners",
  },
  STATISTICS: {
    TABS: {
      AMBITS: "Areas of action",
      ENTITIES: "Organisations",
      CATALONIA_PARTNERS: "Catalonia partners",
      EXTERNAL_PARTNERS: "External partners",
      PROJECTS: "Projects",
    },
    AMBITS: {
      TITLE_1_COUNT:
        "Ranking of areas of action according to the number of projects",
      TITLE_1_FUNDING:
        "Ranking of areas of action according to the amount of funding",
      TITLE_2: "Project keyword cloud",
      TITLE_2_SUBTITLE:
        "Filter the word cloud by moving the cursor over the bar chart",
      RADIO_OPTIONS: {
        COUNT: "Number of projects",
        FUNDING: "Funding amount",
      },
    },
    ENTITIES: {
      TITLE_1: "Ranking of organisations by number of projects",
      TITLE_2: "Ranking of organisations by funding amount",
      TITLE_SOCIS_1: "Ranking of partners of Catalonia by number of projects",
      TITLE_SOCIS_2: "Ranking of partners of Catalonia by funding amount",
      DOWNLOAD_EXCEL: "Download data in XLSX",
    },
    EXTERNAL_PARTNERS: {
      TITLE_1_COUNT:
        "Ranking of countries of origin of partners by number of projects",
      TITLE_2_COUNT: "Ranking of external partners by number of projects",
      TITLE_1_FUNDING:
        "Ranking of countries of origin of partners by funding amount",
      TITLE_2_FUNDING: "Ranking of external partners by funding amount",
      RADIO_OPTIONS: {
        COUNT: "Number of projects",
        FUNDING: "Funding amount",
      },
      DOWNLOAD_EXCEL: "Download data in XLSX",
      COORDINATORS_CHECKBOX: "Consider only coordinating partners",
      NUTS2_TITLE: "NUTS2 ranking",
      NUTS3_TITLE: "NUTS3 ranking",
    },
    PROJECTS: {
      TABLE: {
        HEADER: {
          PROJECT_TITLE: "Project title",
          PROGRAM: "Programme",
          AMBIT: "Scope of action",
          ENTITY: "Entity",
          YEAR: "Start date",
          FUNDING: "Investment",
          GRANT: "Grant",
        },
        ACTIONS: {
          labelDisplayedRows: "of",
          labelRowsPerPage: "Rows per page:",
          columnMenuLabel: "Menu",
          columnMenuShowColumns: "Show columns",
          columnMenuManageColumns: "Manage columns",
          columnMenuFilter: "Filter",
          columnMenuHideColumn: "Hide column",
          columnMenuUnsort: "Unsort",
          columnMenuSortAsc: "Sort by ASC",
          columnMenuSortDesc: "Sort by DESC",
          filterPanelAddFilter: "Add a filter",
          filterPanelRemoveAll: "Clear all",
          filterPanelDeleteIconLabel: "Delete",
          filterPanelLogicOperator: "Logical operator",
          filterPanelOperator: "Operator",
          filterPanelOperatorAnd: "And",
          filterPanelOperatorOr: "Or",
          filterPanelColumns: "Columns",
          filterPanelInputLabel: "Value",
          filterPanelInputPlaceholder: "Filter value",
          footerTotalRows: "Total rows:",
          rowPerPageTranslation: "Rows per page",
        },
      },
    },
  },
  INFO_WINDOWS: {
    PROJECT: {
      TITLE: "Title",
      PROGRAM: "Programme",
      YEAR: "Year",
      TOTAL_FUNDING: "Investment",
      PARTICIPANTS: "Organisations of Catalonia",
      BUTTON_PROJECT: "Project",
    },
    ENTITY: {
      NUMBER_OF_PROJECTS: "Number of projects",
      TOTAL_FUNDING: "Investment",
      BUTTON_PROJECT: "Project",
      BUTTON_PROJECTS: "Projects",
    },
  },
  MODAL_WINDOWS: {
    PROJECT: {
      DESCRIPTION: "Description",
      FUNDING: "Investment",
      GRANT: "Grant",
      PROGRAM: "Funding programme",
      AMBITS: "Areas of action",
      KEYWORDS: "Keywords",
      ENTITIES: "Organisations",
    },
    TOOLS_BAR: {
      MENU: {
        FILTER: "Filter",
        SEARCH: "Search",
        SORT: "Choose the criteria to group organisations in the network",
        SHOW: "View",
        ZOOM_IN: "Zoom in",
        ZOOM_OUT: "Zoom out",
        ZOOM_RESET: "Reset view",
        DOWNLOAD: "Download as XLSX",
        LEGEND: "Legend",
      },
      LEGEND: {
        OPTION_1:
          "Project (size is proportional to investment volume; colour reflects classification in a specific theme)",
        OPTION_2: "Collaboration between organisations",
        OPTION_3:
          "Organisation (organisations are classified by colour according to the selected grouping criteria)",
      },
    },
  },
  LOADING_MESSAGES: {
    LOADING_DATA: "Loading data...",
    INITIAL_LOAD: {
      STEP_1: "1/6 - Loading projects",
      STEP_2: "2/6 - Loading topics",
      STEP_3: "3/6 - Loading areas of action",
      STEP_4: "4/6 - Loading external partners",
      STEP_5: "5/6 - Loading organisations",
      STEP_6: "6/6 - Loading keywords",
    },
    PROCESSANT_INFORMACIO: "Processing information",
    DOWNLOAD: "Processing data for download",
    CHART_AMBITS: "Loading areas of action information",
    CHART_PROGRAMS: "Loading funding programme information",
    NETWORK: "Loading network information",
    MAP: "Loading map information",
  },
  DOWNLOAD_HEADERS: {
    PROJECT_ID: "Project identifier",
    PROJECT_TITLE: "Project title",
    PROJECT_ABSTRACT: "Project abstract",
    PROJECT_YEAR: "Start date",
    PROJECT_AMBIT: "Scope of action",
    ENTITY_NAME: "Organisation name",
    ENTITY_TYPE: "Organisation type",
    ENTITY_ROLE: "Role",
    ENTITY_COUNTRY: "Country",
    ENTITY_NUTS2: "Region",
    ENTITY_NUTS3: "Province",
    FUNDING: "Investment",
    GRANT: "Grant",
    TOTAL_FUNDING: "Total investment",
    TOTAL_GRANT: "Total grant",
  },
};