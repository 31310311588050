import React, { Component, Fragment } from "react";
import "./index.scss";
import FiltersPanel from "../../components/FiltersPanel";
import SearchesPanel from "../../components/SearchesPanel";
import SortingPanel from "../../components/SortingPanel";

class CanvasToolsPanel extends Component {

  render() {
    const {
      data,
      filters,
      setFilter,
      setSearch,
      setFilters,
      resetAllFilters,
      activeTool,
      sorting,
      setNetworkSortingCriteria,
      setActiveTool,
      lang
    } = this.props;
    return (
      <Fragment>
        <div className="tools-panels">
          {activeTool === "search" && (
            <SearchesPanel
              data={data}
              filters={filters}
              setFilter={setFilter}
              setSearch={setSearch}
              setActiveTool={setActiveTool}
              lang={lang}
            ></SearchesPanel>
          )}
          {activeTool === "filter" && (
            <FiltersPanel
              data={data}
              filters={filters}
              setFilter={setFilter}
              setFilters={setFilters}
              resetAllFilters={resetAllFilters}
              setActiveTool={setActiveTool}
              lang={lang}
            ></FiltersPanel>
          )}
          {activeTool === "sortingNetwork" && (
            <SortingPanel
              data={data}
              filters={filters}
              sorting={sorting}
              setNetworkSortingCriteria={setNetworkSortingCriteria}
              setActiveTool={setActiveTool}
              lang={lang}
            ></SortingPanel>
          )}
        </div>
      </Fragment>
    );
  }
}

export default CanvasToolsPanel;
