import React, { Component, Fragment } from 'react';
import Select from 'react-select'
import './index.scss';

class FilterDropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    async componentDidMount() {
        // Call any function in provider
    }

    handleSelected = (options, { action, removedValue }) => {

        // console.log("handleSelected");
        // console.log("options", options);
        // console.log("action", action);
        // console.log("removedValue", removedValue);

        this.props.setFilter({
            filter: options,
            type: this.props.entity
        })
    }

    render() {
        const { options, placeholder } = this.props;
        return <Fragment>
            <Select
                options={options}
                isSearchable={true}
                onChange={this.handleSelected}
                isMulti
                placeholder={<div className='text-truncate'>{placeholder}</div>}
                className={this.props.dynamicClassName ? this.props.dynamicClassName : ''}
                classNamePrefix={this.props.dynamicPrefix ? this.props.dynamicPrefix : ''}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: 'hotpink',
                        primary: 'black',
                    },
                })} />
        </Fragment>
    }
}

export default FilterDropdown;